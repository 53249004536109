import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Nav, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

import { useUserContext } from "../../context/UserContext";

import { db } from "../../services/firebase";

import RecordEmbed from "../Integration/RecordEmbed";

import TopBar from "../../layout/TopBar";
import Layout from "../../layout/index";

import { PageTab } from "./PageTab";
import { VocastTab } from "./VocastTab";
import { WidgetTab } from "./WidgetTab";

import { ReactComponent as CircleWaveformIcon } from "../../Assets/pictos/circle-waveform-lines-regular.svg";
import { ReactComponent as CircleWaveformIconSolid } from "../../Assets/pictos/circle-waveform-lines-solid.svg";
import { ReactComponent as FileLinesIcon } from "../../Assets/pictos/file-lines-regular.svg";
import { ReactComponent as FileLinesIconSolid } from "../../Assets/pictos/file-lines-solid.svg";
import { ReactComponent as MicrophoneIconRegular } from "../../Assets/pictos/microphone-regular.svg";
import { ReactComponent as MicrophoneIconSolid } from "../../Assets/pictos/microphone-solid.svg";

import { FaChevronLeft, FaCopy, FaPencil } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

export const RecorderEdit = () => {
    const { t } = useTranslation();
    const { user: currentUser, userData } = useUserContext();
    const { recorderId } = useParams();
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("widget");

    const [recorder, setRecorder] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editedName, setEditedName] = useState("");

    const [initialConfig, setInitialConfig] = useState(null);

    const [embedHeight, setEmbedHeight] = useState(64);

    const [widgetOptions, setWidgetOptions] = useState({
        showProfile: true,
        background: "vocast",
        showBorder: true,
        inputContributorPicture: true,
        inputAudioFromFile: false,
        allowRecordingPause: false,
        titleMode: "disabled",
        descMode: "disabled",
        titleBlockMode: "disabled",
        descBlockMode: "disabled",
        title: "",
        desc: "",
        titleBlock: "",
        descBlock: "",
        clickToRecord: "",
        afterUpload: "",
    });
    const [pageOptions, setPageOptions] = useState({
        pageMode: false,
        showLogo: false,
        backgroundColor: "#000000",
        backgroundImage: false,
    });
    const [vocastOptions, setVocastOptions] = useState({
        collections: [],
        status: "draft",
        visibility: "public",
        details: {},
        externalId: "",
        callback: "",
    });
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [unsavedTabs, setUnsavedTabs] = useState({
        widget: false,
        page: false,
        vocast: false,
    });

    const colorsDefault = {
        bgColor: "#B2B2B2",
        textColor: "#FFFFFF",
        cardColor: "#4D4D4D",
        cardContentColor: "#FFFFFF",
    };

    const [colors, setColors] = useState(colorsDefault);
    const [copied, setCopied] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (!currentUser || !recorderId) return;

        const fetchRecorder = async () => {
            try {
                const recorderRef = doc(db, "recorder", recorderId);
                const recorderSnap = await getDoc(recorderRef);

                if (!recorderSnap.exists()) {
                    setError(t("common:errors.not_found"));
                    setLoading(false);
                    return;
                }

                const recorderData = recorderSnap.data();
                setInitialConfig(recorderData);

                if (recorderData.owner.id !== currentUser.uid) {
                    setError(t("common:errors.unauthorized"));
                    setLoading(false);
                    return;
                }

                setRecorder({
                    userId: currentUser.uid,
                    id: recorderSnap.id,
                    ...recorderData,
                });

                // Set widget options from recorder data if they exist
                if (recorderData.widgetOptions) {
                    setWidgetOptions((prev) => ({ ...prev, ...recorderData.widgetOptions }));
                }

                // Set page options from recorder data if they exist
                if (recorderData.pageOptions) {
                    setPageOptions((prev) => ({ ...prev, ...recorderData.pageOptions }));
                }

                // Set vocast options from recorder data if they exist
                if (recorderData.vocastOptions) {
                    setVocastOptions((prev) => ({ ...prev, ...recorderData.vocastOptions }));
                }

                if (recorderData.embedHeight) {
                    setEmbedHeight(recorderData.embedHeight);
                }

                setLoading(false);
            } catch (err) {
                console.error("Error fetching recorder:", err);
                setError(t("common:errors.load_failed"));
                setLoading(false);
            }
        };

        fetchRecorder();
    }, [currentUser, recorderId, t]);

    useEffect(() => {
        if (!initialConfig || loading || error) return;

        const resizeObserver = new ResizeObserver((entries) => {
            let height = entries[0].contentRect.height + "px";
            setEmbedHeight(height);
            setHasUnsavedChanges(height !== initialConfig.embedHeight);
        });
        resizeObserver.observe(document.querySelector("#embedIframe > div"));
        return () => {
            resizeObserver.disconnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialConfig, loading, error, activeTab]);

    const handleEditName = async () => {
        try {
            const recorderRef = doc(db, "recorder", recorderId);
            await updateDoc(recorderRef, {
                name: editedName,
            });
            setRecorder((prev) => ({ ...prev, name: editedName }));
            setShowEditModal(false);
        } catch (err) {
            console.error("Error updating recorder name:", err);
            setError(t("common:errors.update_failed"));
        }
    };

    const handleWidgetOptionsChange = async (newOptions) => {
        try {
            if (newOptions.background === "no") newOptions.colors = colors;
            else delete newOptions.colors;
            setWidgetOptions(newOptions);
            setHasUnsavedChanges(true);
            setUnsavedTabs((prev) => ({ ...prev, widget: true }));
        } catch (err) {
            console.error("Error updating widget options:", err);
            setError(t("common:errors.update_failed"));
        }
    };

    const handlePageOptionsChange = async (newOptions) => {
        try {
            setPageOptions(newOptions);
            setHasUnsavedChanges(true);
            setUnsavedTabs((prev) => ({ ...prev, page: true }));
        } catch (err) {
            console.error("Error updating page options:", err);
            setError(t("common:errors.update_failed"));
        }
    };

    const handleVocastOptionsChange = async (newOptions) => {
        try {
            setVocastOptions(newOptions);
            setHasUnsavedChanges(true);
            setUnsavedTabs((prev) => ({ ...prev, vocast: true }));
        } catch (err) {
            console.error("Error updating vocast options:", err);
            setError(t("common:errors.update_failed"));
        }
    };

    const handleSaveChanges = async () => {
        try {
            setIsSaving(true);
            const recorderRef = doc(db, "recorder", recorderId);

            // Handle image upload if there's a temp file
            if (pageOptions.tempFile) {
                const storage = getStorage();
                const fileExtension = pageOptions.tempFile.name.split(".").pop();
                const fileName = `${uuidv4()}.${fileExtension}`;
                const storageRef = ref(storage, `recorder/${currentUser.uid}/${recorderId}/${fileName}`);

                // Upload the file
                await uploadBytes(storageRef, pageOptions.tempFile);

                // Get the download URL
                const downloadURL = await getDownloadURL(storageRef);

                // Update pageOptions with the new image URL
                pageOptions.backgroundImageUrl = downloadURL;
                delete pageOptions.tempFile;
            }

            await updateDoc(recorderRef, {
                widgetOptions,
                pageOptions,
                vocastOptions,
                embedHeight,
            });
            setHasUnsavedChanges(false);
            setUnsavedTabs({
                widget: false,
                page: false,
                vocast: false,
            });
            setInitialConfig((prev) => ({ ...prev, embedHeight }));
        } catch (err) {
            console.error("Error saving changes:", err);
            setError(t("common:errors.update_failed"));
        } finally {
            setIsSaving(false);
        }
    };

    const handleColorChange = (colorKey, value) => {
        const newColors = { ...colors, [colorKey]: value };
        setColors(newColors);
        const newOptions = {
            ...widgetOptions,
            colors: newColors,
            hasStyleChanges: true,
        };
        console.log("Configuration changed:", newOptions);
        setWidgetOptions(newOptions);
        setHasUnsavedChanges(true);
        setUnsavedTabs((prev) => ({ ...prev, widget: true }));
    };

    if (loading || error) {
        return (
            <Layout>
                <Container className="mt-3 p-0 pb-5">
                    <div className="d-flex justify-content-center align-items-center">
                        {loading && <h1>{t("common:status.loading")}</h1>}
                        {error && <h1>{error}</h1>}
                    </div>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout>
            <div fluid style={{ paddingBottom: 50 }}>
                <div style={{ marginLeft: 12, marginRight: 12 }}>
                    <TopBar />
                </div>
                <Container className="mt-3 p-0 pb-5" style={{ marginLeft: "18px", marginRight: "18px", maxWidth: "calc(100% - 36px)" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div className="d-flex align-items-center gap-3">
                            <FaChevronLeft size={24} style={{ cursor: "pointer", color: "#fff" }} onClick={() => navigate("/recorder")} />
                            <h1 style={{ margin: 0 }}>{recorder?.name || t("Recorder:title")}</h1>
                            <FaPencil
                                size={20}
                                style={{ cursor: "pointer", color: "#fff" }}
                                onClick={() => {
                                    setEditedName(recorder?.name || "");
                                    setShowEditModal(true);
                                }}
                            />
                        </div>
                        {hasUnsavedChanges && (
                            <Button
                                variant="primary"
                                onClick={handleSaveChanges}
                                disabled={isSaving}
                                style={{
                                    backgroundColor: "#80fed8",
                                    borderColor: "#80fed8",
                                    color: "#000",
                                    padding: "8px 16px",
                                    borderRadius: "4px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                {isSaving ? (
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {t("Recorder:status.saving")}
                                    </>
                                ) : (
                                    t("Recorder:actions.saveChanges")
                                )}
                            </Button>
                        )}
                    </div>

                    <Row>
                        <Col md={3} xxl={2}>
                            <Nav variant="pills" className="d-flex flex-row flex-md-column justify-content-between mb-4 mb-md-0">
                                <Nav.Item className="flex-fill">
                                    <Nav.Link
                                        active={activeTab === "widget"}
                                        onClick={() => setActiveTab("widget")}
                                        style={{
                                            color: "#fff",
                                            backgroundColor: activeTab === "widget" ? "#6c757d" : "transparent",
                                            fontWeight: activeTab === "widget" ? "600" : "400",
                                            padding: "8px 12px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div className="d-flex align-items-center gap-2 justify-content-center justify-content-md-start">
                                            <div className="d-flex align-items-center gap-2">
                                                {activeTab === "widget" ? <MicrophoneIconSolid width={20} height={20} /> : <MicrophoneIconRegular width={20} height={20} />}
                                                <span className="d-none d-md-inline">{t("Recorder:widgetConfiguration.titleTab")}</span>
                                            </div>
                                            <span className="d-md-none">{t("Recorder:widgetConfiguration.titleTab")}</span>
                                            {unsavedTabs.widget && <span style={{ color: "#ff4444", fontSize: "16px", marginLeft: "4px" }}>*</span>}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="flex-fill">
                                    <Nav.Link
                                        active={activeTab === "page"}
                                        onClick={() => setActiveTab("page")}
                                        style={{
                                            color: "#fff",
                                            backgroundColor: activeTab === "page" ? "#6c757d" : "transparent",
                                            fontWeight: activeTab === "page" ? "600" : "400",
                                            padding: "8px 12px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div className="d-flex align-items-center gap-2 justify-content-center justify-content-md-start">
                                            <div className="d-flex align-items-center gap-2">
                                                {activeTab === "page" ? <FileLinesIconSolid width={20} height={20} /> : <FileLinesIcon width={20} height={20} />}
                                                <span className="d-none d-md-inline">{t("Recorder:pageConfiguration.titleTab")}</span>
                                            </div>
                                            <span className="d-md-none">{t("Recorder:pageConfiguration.titleTab")}</span>
                                            {unsavedTabs.page && <span style={{ color: "#ff4444", fontSize: "16px", marginLeft: "4px" }}>*</span>}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="flex-fill">
                                    <Nav.Link
                                        active={activeTab === "vocast"}
                                        onClick={() => setActiveTab("vocast")}
                                        style={{
                                            color: "#fff",
                                            backgroundColor: activeTab === "vocast" ? "#6c757d" : "transparent",
                                            fontWeight: activeTab === "vocast" ? "600" : "400",
                                            padding: "8px 12px",
                                            fontSize: "14px",
                                        }}
                                    >
                                        <div className="d-flex align-items-center gap-2 justify-content-center justify-content-md-start">
                                            <div className="d-flex align-items-center gap-2">
                                                {activeTab === "vocast" ? <CircleWaveformIconSolid width={20} height={20} /> : <CircleWaveformIcon width={20} height={20} />}
                                                <span className="d-none d-md-inline">{t("Recorder:vocastConfiguration.titleTab")}</span>
                                            </div>
                                            <span className="d-md-none">{t("Recorder:vocastConfiguration.titleTab")}</span>
                                            {unsavedTabs.vocast && <span style={{ color: "#ff4444", fontSize: "16px", marginLeft: "4px" }}>*</span>}
                                        </div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                        <Col md={9} xxl={6}>
                            {activeTab === "widget" && <WidgetTab options={widgetOptions} onOptionsChange={handleWidgetOptionsChange} colors={colors} onColorChange={handleColorChange} colorsDefault={colorsDefault} />}
                            {activeTab === "page" && <PageTab options={{ ...pageOptions, recorderId }} onOptionsChange={handlePageOptionsChange} />}
                            {activeTab === "vocast" && <VocastTab options={vocastOptions} onOptionsChange={handleVocastOptionsChange} />}
                        </Col>
                        <Col md={12} xxl={4}>
                            <div style={{ maxWidth: 400, margin: "20px auto 0 auto" }}>
                                {/* Preview Section */}
                                <div>
                                    <h5 style={{ marginBottom: 16, color: "#fff" }}>{t("Recorder:preview.title")}</h5>
                                    {/* Title Preview */}
                                    {widgetOptions.titleMode !== "disabled" && widgetOptions.title && (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                marginBottom: "20px",
                                                color: widgetOptions.background === "no" ? colors.textColor : "#fff",
                                                fontSize: "18px",
                                                fontWeight: "500",
                                            }}
                                        >
                                            {widgetOptions.title}
                                        </div>
                                    )}
                                    {activeTab === "page" ? (
                                        <div
                                            id="embedIframe"
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 20,
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                backgroundColor: pageOptions.backgroundColor,
                                                backgroundImage:
                                                    pageOptions.backgroundImage && (pageOptions.backgroundImageUrl || pageOptions.tempFile)
                                                        ? `url(${pageOptions.backgroundImageUrl || URL.createObjectURL(pageOptions.tempFile)})`
                                                        : "none",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                borderRadius: 10,
                                                padding: 10,
                                            }}
                                        >
                                            {pageOptions.showLogo && <img src={userData?.profilePicture} alt="Logo" style={{ width: 100, height: 100 }} />}
                                            <RecordEmbed
                                                params={window.btoa(
                                                    JSON.stringify({
                                                        ...widgetOptions,
                                                        ...pageOptions,
                                                        ...vocastOptions,
                                                        userId: recorder.userId,
                                                    }),
                                                )}
                                                demo
                                                inApp
                                            />
                                        </div>
                                    ) : (
                                        <div id="embedIframe" style={{ display: "flex", backgroundColor: "#fff", borderRadius: 10, padding: 10 }}>
                                            <RecordEmbed
                                                params={window.btoa(
                                                    JSON.stringify({
                                                        ...widgetOptions,
                                                        ...pageOptions,
                                                        ...vocastOptions,
                                                        userId: recorder.userId,
                                                    }),
                                                )}
                                                demo
                                                inApp
                                            />
                                        </div>
                                    )}
                                </div>

                                {/* Code Section */}
                                <div style={{ marginTop: 20 }}>
                                    <h5 style={{ marginBottom: 16, color: "#fff" }}>{t("Recorder:preview.code")}</h5>
                                    <div style={{ border: "1px solid #ccc", padding: 10, borderRadius: 10, backgroundColor: "#f8f9fa", overflow: "auto", position: "relative" }}>
                                        <pre
                                            style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere", marginBottom: 0, fontSize: 12, cursor: "pointer" }}
                                            id="codeEmbed"
                                            onClick={() => {
                                                if (document.selection) {
                                                    let range = document.body.createTextRange();
                                                    range.moveToElementText(document.getElementById("codeEmbed"));
                                                    range.select();
                                                } else if (window.getSelection) {
                                                    let range = document.createRange();
                                                    range.selectNode(document.getElementById("codeEmbed"));
                                                    window.getSelection().removeAllRanges();
                                                    window.getSelection().addRange(range);
                                                }
                                                navigator.clipboard.writeText(document.getElementById("codeEmbed").innerText);
                                                setCopied(true);
                                                setIsHovered(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                    setIsHovered(false);
                                                }, 2000);
                                            }}
                                        >
                                            {`<script src="${window.location.origin}/recorder.js?id=${recorderId}"></script>`}
                                        </pre>
                                        <button
                                            className="btn btn-primary copy-code-btn"
                                            style={{
                                                position: "absolute",
                                                bottom: 5,
                                                right: 5,
                                                maxWidth: isHovered ? 200 : 40,
                                                minWidth: 40,
                                                padding: 10,
                                                overflow: "hidden",
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                transition: "all 0.3s ease",
                                                gap: 12,
                                                height: 40,
                                            }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(document.getElementById("codeEmbed").innerText);
                                                setCopied(true);
                                                setTimeout(() => {
                                                    setCopied(false);
                                                }, 2000);
                                            }}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                        >
                                            <FaCopy style={{ color: "#000" }} size={16} />
                                            {isHovered && (
                                                <span style={{ whiteSpace: "nowrap", opacity: isHovered ? 1 : 0, transition: "opacity 0.3s ease", fontWeight: 600 }}>
                                                    {copied ? t("Recorder:preview.copied") : t("Recorder:preview.copyCode")}
                                                </span>
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
                <Modal.Header>
                    <Modal.Title>{t("Recorder:actions.edit")}</Modal.Title>
                    <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40 }} onClick={() => setShowEditModal(false)}>
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="recorderName">{t("Recorder:actions.name")}</label>
                        <input type="text" className="form-control" id="recorderName" value={editedName} onChange={(e) => setEditedName(e.target.value)} placeholder={t("Recorder:actions.namePlaceholder")} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        {t("Recorder:actions.cancel")}
                    </Button>
                    <Button variant="primary" onClick={handleEditName}>
                        {t("Recorder:actions.save")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};
