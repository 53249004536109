import { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { isAndroid, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import { algoliasearch } from "algoliasearch";
import { collection, getDoc, limit, onSnapshot, query, where } from "firebase/firestore";

import { GlobalContext } from "../context/GlobalContext";
import { useUserContext } from "../context/UserContext";

import { db } from "../services/firebase";
import { event_page_profile, event_vocast_listen } from "../services/stats";

import TopBar from "../layout/TopBar";
import Layout from "../layout/index";

import AudioCard from "../components/AudioCard";
import { RoundButton } from "../components/Buttons";
import { ContactPopin } from "../components/ContactPopin";
import Desc from "../components/Desc";
import { FuturPopin } from "../components/FuturPopin";
import { QRCodePopin } from "../components/QRCodePopin";
import { ReportPopin } from "../components/ReportPopin";
import { IsLarger, IsSmaller } from "../components/Responsive";

import defaultBg from "../Assets/img/default-bg.jpg";
import { ReactComponent as ExportIcon } from "../Assets/pictos/export.svg";
import { ReactComponent as LinkIconWhite } from "../Assets/pictos/link-white.svg";
import { ReactComponent as ReportIcon } from "../Assets/pictos/report.svg";
import { ReactComponent as WaveformIcon } from "../Assets/pictos/waveform-lines-light.svg";

import { FaEnvelope, FaMicrophoneAlt } from "react-icons/fa";
import { FaPlay } from "react-icons/fa6";
import { FaPause } from "react-icons/fa6";
import { FaImage } from "react-icons/fa6";
import { FaUser } from "react-icons/fa6";
import { HiMiniQrCode } from "react-icons/hi2";
import { IoIosMore } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const searchClient = algoliasearch("QEZOD7FQY7", "c41c105d487e573ff5541b209eb60e27");
const defaultUrlBg = "https://storage.googleapis.com/vocast-public/assets/background/default.jpg";

export const Profile = () => {
    const { i18n, t } = useTranslation();
    const { user: currentUser, toggleFollow, isFollowing } = useUserContext();
    const { audioSource, audioStatus, language, setLanguage, languages, handlePlay } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const [hits, setHits] = useState([]);
    const [hitsInfo, setHitsInfo] = useState({});

    const [pinnedVocast, setPinnedVocast] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profilePicture, setProfilePicture] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const [showImage, setShowImage] = useState(false);
    const [modalChoice, setModalChoice] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [modalQRCode, setModalQRCode] = useState(false);
    const [modalContact, setModalContact] = useState(false);
    const [modalFutur, setModalFutur] = useState(null);
    const [textFollow, setTextFollow] = useState("common:actions.followed");

    const storeUrl = isAndroid ? "https://play.google.com/store/apps/details?id=com.vocast" : "https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563";

    let params = useParams();

    const followUnfollowUser = async (e, userId) => {
        e.preventDefault();
        e.stopPropagation();
        if (!userId) return;
        if (!currentUser) return navigate("/signup");
        if (currentUser.uid === userId) return;
        await toggleFollow(userId);
    };

    const openModalFutur = (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        setModalFutur(type);
    };

    const handleSearch = async (page = 0) => {
        const filters = [`ownerId:${user?.id}`, `NOT visibility:private`, `status:published`];
        if (params?.collection) filters.push(`collections:${params?.collection}`);
        const { hits, ...rest } = await searchClient.searchSingleIndex({
            indexName: "audio",
            searchParams: {
                hitsPerPage: 10,
                filters: filters.join(" AND "),
                page,
            },
        });
        if (page === 0) setHits(hits);
        else setHits((prev) => [...prev, ...hits]);
        setHitsInfo(rest);
    };

    useEffect(() => {
        if (!audioSource?.audio?.id || !user || !user.id) return;
        let audio = audioSource.audio;
        let contributorId = audio?.contributor?.status === "enabled" ? audio?.contributor?.id : null;
        let vocasterId = user?.vocasters?.[audio?.vocaster] ? audio?.vocaster : null;

        event_vocast_listen({
            audioId: audio.id,
            ownerId: user.id,
            contributorId: contributorId || vocasterId,
            audioLanguage: language || audio.locale,
            listener: currentUser?.uid || "anonymous",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioSource]);

    useEffect(() => {
        setError(null);
        let unsubscribe;

        async function setupUserListener() {
            unsubscribe = onSnapshot(
                query(collection(db, "users"), where("nickname", "==", params.user), limit(1)),
                (querySnapshot) => {
                    if (querySnapshot.empty) {
                        console.log("No user found");
                        setError(t("common:status.notFoundProfile"));
                        return;
                    }

                    let userData = querySnapshot.docs[0].data();
                    userData.id = querySnapshot.docs[0].id;

                    // Event page profile
                    event_page_profile({ profileId: userData.id });

                    if (userData.pinnedVocast && userData.pinnedVocast !== "") {
                        getDoc(userData.pinnedVocast).then((doc) => {
                            if (!doc.exists()) return;
                            let tmpPinnedVocast = doc.data();
                            tmpPinnedVocast.id = doc.id;
                            setPinnedVocast(tmpPinnedVocast);
                        });
                    }

                    document.title = `${userData.name} (@${userData.nickname}) sur Vocast`;
                    let metaDescription = document.querySelector('meta[name="description"]');
                    metaDescription.setAttribute("content", userData.description);
                    setProfilePicture(userData && userData.profilePicture && userData.profilePicture !== "" && !userData.profilePicture.match(/default-avatar\.png/) ? userData.profilePicture : defaultBg);

                    userData.clearCollection = Object.entries(userData?.collections || {})
                        .sort((a, b) => (a[1].name || a[0]).localeCompare(b[1].name || b[0]))
                        .filter(([key, coll]) => coll?.vocasts?.length > 0)
                        .map(([key, coll]) => ({ key, name: coll.name || key, ...coll }));
                    setUser(userData);
                    setLanguage(audioSource?.audio?.locale || languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");
                    setLoading(false);
                },
                (error) => {
                    console.error("Error getting user:", error);
                    setError(t("common:status.notFoundProfile"));
                },
            );
        }

        setupUserListener();
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.user]);

    useEffect(() => {
        if (!user || !user.id) return;
        if (params?.collection && !user.collections[params?.collection]) return navigate(`/${user.nickname}`);
        handleSearch();
        if (!params?.collection) return;
        const collDom = document.querySelector(`[data-key="${params?.collection}"]`);
        if (!collDom || !collDom.scrollIntoView) return;
        collDom.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params?.collection, user]);

    const handleShare = () => {
        // If mobile share api is available, use it
        if (navigator.share) {
            navigator
                .share({
                    url: window.location.href,
                })
                .then(() => {
                    console.log("Thanks for sharing!");
                })
                .catch(console.error);
        }
    };

    const handleOpenAppClick = async (url) => {
        if (url && typeof url === "string") window.location.href = url;

        setTimeout(function () {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.vocast";
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563";
            } else {
                setShowModal(true);
            }
        }, 100);
    };

    const ShowImage = () => (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: window.innerHeight,
                overflow: "hidden",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <img
                alt="Vocast"
                src={profilePicture}
                style={{
                    maxHeight: "95%",
                    maxWidth: "95%",
                    objectFit: "contain",
                }}
                onClick={() => setShowImage(false)}
            />
            <IoIosCloseCircleOutline
                size={40}
                style={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    color: "#fff",
                    cursor: "pointer",
                }}
                onClick={() => setShowImage(false)}
            />
        </div>
    );

    const ModalChoice = (props) => {
        const handleReport = () => {
            setModalReport(true);
            setModalChoice(false);
        };

        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
                <Modal.Body>
                    <button
                        type="button"
                        className="btn-close"
                        style={{
                            background: "unset",
                            padding: 0,
                            width: 40,
                            height: 40,
                            position: "absolute",
                            right: 8,
                            top: 8,
                        }}
                        onClick={props.onHide}
                    >
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                    <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleReport}>
                        <ReportIcon style={{ width: 25, height: 25 }} />
                        <span>{t("Profile:reportAccount")}</span>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    if (loading || !user || error)
        return (
            <Layout>
                <Container style={{}} className="mt-3 p-0 pb-5">
                    {error && (
                        <div xs={12} className="d-flex justify-content-center align-items-center">
                            <h1 style={{ color: "#333" }}>{error}</h1>
                        </div>
                    )}
                    {loading && (
                        <div className="d-flex justify-content-center align-items-center">
                            <h1>{t("common:status.loading")}</h1>
                        </div>
                    )}
                    {!error && !loading && user && (
                        <div style={{ justifyContent: "center" }}>
                            <div style={{ backgroundColor: "red" }}>{error && <h1>{error}</h1>}</div>
                        </div>
                    )}
                </Container>
            </Layout>
        );

    return (
        <Layout>
            {/* @note Il faut qu'on revoie cette partie avec Max, je ne comprends pas trop son système */}
            <div fluid style={{ paddingBottom: 50 }}>
                {showImage && <ShowImage />}
                <FuturPopin show={modalFutur} onHide={() => setModalFutur(null)} />
                <ModalChoice show={modalChoice} onHide={() => setModalChoice(false)} />
                <ReportPopin show={modalReport} onHide={() => setModalReport(false)} {...{ type: "users", context: user?.id, user }} />
                <QRCodePopin show={modalQRCode} onHide={() => setModalQRCode(false)} nickname={user?.nickname} isOwnerPicture={user?.profilePicture} />
                <ContactPopin show={modalContact} onHide={() => setModalContact(false)} {...{ context: user?.id, user }} />

                <div
                    style={{
                        paddingBottom: 16,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                        borderBottomLeftRadius: 44,
                        borderBottomRightRadius: 44,

                        backgroundImage: `linear-gradient(${user?.profilePicture && !user?.profilePicture.match(/default-avatar\.png/) ? "transparent" : "rgb(51 51 51 / 81%)"}, transparent), url(${user?.profilePicture || defaultUrlBg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    className="d-flex justify-content-center align-items-center flex-column position-relative"
                >
                    <Row className="w-100 h-100" style={{ flex: 1 }}>
                        <TopBar handleOpenAppClick={handleOpenAppClick} />
                        <Col xs={12}>
                            <Card
                                style={{
                                    width: "100%",
                                    borderRadius: "2em",
                                    backgroundColor: "rgba(51, 51, 51, 0.8)",
                                    color: "#fff",
                                }}
                            >
                                <Card.Body>
                                    <div
                                        className="d-flex align-items-center"
                                        style={{
                                            cursor: "pointer",
                                            gap: "1rem",
                                            zIndex: 1,
                                            flex: 1,
                                        }} /*, marginLeft: 12, marginRight: 12 */
                                        onClick={(e) => navigate(`/${user && user.nickname}`)}
                                    >
                                        <div className="d-flex align-items-center" style={{ gap: 10, flex: 1 }}>
                                            {user && user.profilePicture && (
                                                <div
                                                    style={{
                                                        width: "auto",
                                                    }}
                                                >
                                                    <img
                                                        alt={user.name}
                                                        src={user.profilePicture}
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "42%",
                                                            aspectRatio: 1,
                                                            objectFit: "cover",
                                                            backgroundPosition: "center",
                                                            maxWidth: 52,
                                                            height: "100%",
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div>
                                                <h5
                                                    className="m-0"
                                                    style={{
                                                        fontSize: "1.15rem",
                                                    }}
                                                >
                                                    {user && user.name}
                                                </h5>
                                                <div>
                                                    <small>@{user && user.nickname}</small>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {!currentUser?.uid || currentUser?.uid !== user?.id ? (
                                            <div>
                                                <Button variant="outline-light" onClick={(e) => followUnfollowUser(e, user.id)}>
                                                    {isFollowing(user.id) ? t("common:actions.followed") : t("common:actions.follow")}
                                                </Button>
                                            </div>
                                        ) : (
                                            <div>
                                                <Button variant="outline-light" onClick={(e) => openModalFutur(e, "settings")}>
                                                    {t("common:actions.editProfile")}
                                                </Button>
                                            </div>
                                        )} */}
                                        {currentUser?.uid && currentUser?.uid !== user?.id && (
                                            <div>
                                                <Button
                                                    variant="outline-light"
                                                    onClick={(e) => followUnfollowUser(e, user.id)}
                                                    onMouseEnter={() => setTextFollow("common:actions.unfollow")}
                                                    onMouseLeave={() => setTextFollow("common:actions.followed")}
                                                >
                                                    {isFollowing(user.id) ? t(textFollow) : t("common:actions.follow")}
                                                </Button>
                                            </div>
                                        )}
                                        {currentUser?.uid && currentUser?.uid === user?.id && (
                                            <div>
                                                <Button variant="outline-light" onClick={(e) => openModalFutur(e, "settings")}>
                                                    <IsSmaller width={650}>{t("common:actions.editProfile_short")}</IsSmaller>
                                                    <IsLarger width={650}>{t("common:actions.editProfile")}</IsLarger>
                                                </Button>
                                            </div>
                                        )}
                                        {!currentUser?.uid && (
                                            <div>
                                                <Button variant="outline-light" onClick={(e) => followUnfollowUser(e, user.id)}>
                                                    {t("common:actions.follow")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                    {user.description && (
                                        <>
                                            <hr />
                                            <Desc desc={user?.description || ""} />
                                        </>
                                    )}
                                    {user?.link && (
                                        <Row>
                                            <Col xs={12} className="text-truncate" style={{ paddingTop: 5 }}>
                                                <a
                                                    href={user?.link}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-white text-decoration-none"
                                                    style={{
                                                        fontSize: "0.9em",
                                                    }}
                                                >
                                                    <LinkIconWhite
                                                        style={{
                                                            width: 18,
                                                            color: "white",
                                                        }}
                                                        color="white"
                                                    />{" "}
                                                    {
                                                        user?.link
                                                            .replace(/https?:\/\//, "")
                                                            .replace(/\/?$/, "")
                                                            .split("?")[0]
                                                    }
                                                </a>
                                            </Col>
                                        </Row>
                                    )}
                                    <Row className="mt-3">
                                        <Col className="d-flex justify-content-start gap-2">
                                            {/* Add play button if user.pinnedVocast exists */}
                                            {pinnedVocast && (
                                                <RoundButton
                                                    variant="light"
                                                    size="sm"
                                                    onClick={(e) =>
                                                        handlePlay(pinnedVocast, {
                                                            forceLocale: true,
                                                        })
                                                    }
                                                    style={{
                                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                        color: "white",
                                                    }}
                                                >
                                                    {pinnedVocast && audioStatus === "play" && audioSource && audioSource.audio.id === pinnedVocast.id ? (
                                                        <FaPause
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            size={18}
                                                        />
                                                    ) : (
                                                        <FaPlay
                                                            style={{
                                                                color: "white",
                                                            }}
                                                            size={18}
                                                        />
                                                    )}
                                                </RoundButton>
                                            )}
                                            <RoundButton
                                                variant="light"
                                                size="sm"
                                                onClick={() => setModalContact(true)}
                                                style={{
                                                    background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                    color: "white",
                                                }}
                                            >
                                                <FaEnvelope
                                                    size={18}
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </RoundButton>
                                            {user?.profilePicture && !user?.profilePicture.match(/default-avatar\.png/) && (
                                                <RoundButton
                                                    variant="light"
                                                    size="sm"
                                                    onClick={() => setShowImage(true)}
                                                    style={{
                                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                        color: "white",
                                                    }}
                                                >
                                                    <FaImage
                                                        size={18}
                                                        style={{
                                                            color: "white",
                                                        }}
                                                    />
                                                </RoundButton>
                                            )}
                                        </Col>
                                        <Col className="d-flex justify-content-end gap-2">
                                            <RoundButton
                                                variant="light"
                                                size="sm"
                                                style={{
                                                    background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                    color: "white",
                                                }}
                                                onClick={() => setModalQRCode(true)}
                                            >
                                                <HiMiniQrCode size={20} />
                                            </RoundButton>
                                            <RoundButton
                                                variant="light"
                                                size="sm"
                                                style={{
                                                    background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                    color: "white",
                                                }}
                                                onClick={() => setModalChoice(true)}
                                            >
                                                <IoIosMore size={25} />
                                            </RoundButton>

                                            {(navigator.share || document.location.hostname === "localhost") && (
                                                <RoundButton
                                                    variant="light"
                                                    size="sm"
                                                    style={{
                                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                        color: "white",
                                                    }}
                                                    onClick={() => handleShare()}
                                                >
                                                    <ExportIcon
                                                        style={{
                                                            width: 20,
                                                        }}
                                                    />
                                                </RoundButton>
                                            )}
                                            {/*} <RoundButton
                                                    variant="light"
                                                    size="sm"
                                                    style={{
                                                        background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                        color: "white",
                                                    }}
                                                    onClick={() => setModalChoice(true)}
                                                >
                                                    <IoIosMore size={20} />
                                                </RoundButton>*/}
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>

                            <Row className="mt-3" style={{ marginLeft: 5 }}>
                                <Col className="d-flex justify-content-start gap-2">
                                    <div
                                        className="bluredIconButton d-flex gap-2 m-0 align-items-center"
                                        style={{
                                            padding: 10,
                                            background: "radial-gradient(rgba(20,20,20,0.6), rgba(20,20,20,0.3))",
                                        }}
                                    >
                                        <WaveformIcon height={18} fill="white" />
                                        <span
                                            style={{
                                                marginTop: 1,
                                                color: "white",
                                                fontSize: 14,
                                            }}
                                        >
                                            <strong style={{ marginRight: 5 }}>{user?.vocasts || 0}</strong>
                                            {t("Profile:vocasts", {
                                                count: user?.vocasts || 0,
                                            })}
                                        </span>
                                    </div>
                                    <div
                                        className="bluredIconButton d-flex gap-2 m-0 align-items-center"
                                        style={{
                                            padding: 10,
                                            background: "radial-gradient(rgba(20,20,20,0.6), rgba(20,20,20,0.3))",
                                        }}
                                    >
                                        <FaUser size={18} style={{ color: "white" }} />
                                        <span
                                            style={{
                                                marginTop: 1,
                                                color: "white",
                                                fontSize: 14,
                                            }}
                                        >
                                            <strong style={{ marginRight: 5 }}>{Object.keys(user.followers || {})?.length || 0}</strong>
                                            {t("Profile:followers", {
                                                count: Object.keys(user.followers || {})?.length || 0,
                                            })}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {user?.clearCollection?.length > 0 && (
                    <div
                        className="m-3 gx-2 hidden-scroll"
                        style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            overflowX: "auto",
                            overflowY: "hidden",
                        }}
                    >
                        {user.clearCollection.map((coll, index) => (
                            <Link
                                key={coll.key}
                                data-key={coll.key}
                                className="profileTab"
                                style={{
                                    flex: `0 0 calc((100% - 1rem - ${user.clearCollection.length > 3 ? 16 : 0}px) / 3.5)`, // ${user.clearCollection.length > 3 ? 3.5 : user.clearCollection.length})
                                    backgroundColor: "#000",
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)), url(${coll?.picture || defaultUrlBg})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    border: params?.collection === coll.key ? "white 1px solid" : "",
                                    marginRight: index === user.clearCollection.length - 1 ? 0 : 16,
                                    maxWidth: 180,
                                    textAlign: "center",
                                    cursor: "pointer",
                                }}
                                to={`/${user?.nickname}${params?.collection === coll.key ? "" : "/c/" + coll.key}`}
                            >
                                <h4
                                    style={{
                                        lineHeight: "1.5em",
                                        color: "rgb(255, 255, 255)",
                                        fontSize: "0.8rem",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: 2,
                                        lineClamp: 2,
                                        WebkitBoxOrient: "vertical",
                                    }}
                                    title={coll.name}
                                >
                                    {coll.name}
                                </h4>
                            </Link>
                        ))}
                    </div>
                )}

                <Container style={{}} className="mt-3 p-0 pb-5">
                    <div className="d-flex flex-column align-items-center" style={{ gap: "1.2rem" }}>
                        {hits.map((hit, index) => (
                            <AudioCard key={`${hit.objectID}-card`} place={[index, hits.length]} hit={hit} user={user} handlePlay={handlePlay} setLanguage={setLanguage} language={language} languages={languages} />
                        ))}
                        {hitsInfo.page < hitsInfo.nbPages - 1 && (
                            <Button variant="secondary" className="w-95" type="button" disabled={loading} onClick={() => handleSearch((hitsInfo.page || 0) + 1)}>
                                {t("Profile:moreVocasts")}
                            </Button>
                        )}
                    </div>
                </Container>
                {/* <Promo showModal={showModal} setShowModal={setShowModal} handleOpenAppClick={handleOpenAppClick} /> */}
            </div>
        </Layout>
    );
};
