import { useEffect, useRef, useState } from "react";

import { getAudioDuration } from "../utils/audio";

const useAudioRecorder = (TIMESLICE = 100, maxDuration = 3 * 60 * 1000) => {
    const audioRef = useRef(null);
    const cronoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const audioChunkRef = useRef([]);

    const [audio, setAudio] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(null);
    const [status, setStatus] = useState("idle"); // idle, rec-play, rec-pause, rev-play, rev-pause, not-allowed, up-progress, up-uploaded, up-finished

    useEffect(() => {
        navigator.permissions.query({ name: "microphone" }).then((status) => {
            switch (status.state) {
                case "denied":
                    setStatus("not-allowed");
                    break;
                default:
                    break;
            }
        });
    }, []);

    const handleStartRecording = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            return setStatus("not-allowed");
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.ondataavailable = (e) => {
                audioChunkRef.current.push(e.data);
                setDuration((d) => {
                    if (d > maxDuration) mediaRecorder.stop();
                    return new Date().getTime() - cronoRef.current;
                });
            };
            mediaRecorder.onstart = () => {
                setStatus("rec-play");
                cronoRef.current = new Date().getTime() - duration;
            };
            mediaRecorder.onresume = () => setStatus("rec-play");
            mediaRecorder.onpause = () => {
                setStatus("rec-pause");
                setAudio(new Blob(audioChunkRef.current, { type: "audio/mp4" }));
            };
            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunkRef.current, { type: "audio/mp4" });
                const url = URL.createObjectURL(audioBlob);
                const audioDuration = await getAudioDuration(audioBlob);

                setAudio(audioBlob);
                setAudioUrl(url);
                setDuration(audioDuration * 1000);
                setStatus("rev-pause");
                audioChunkRef.current = [];

                initAudioCallback();
                stop();
            };

            mediaRecorder.start(TIMESLICE);
            mediaRecorderRef.current = mediaRecorder;
        } catch (error) {
            console.error("Erreur lors de la création du MediaRecorder :", error);
            setStatus("not-allowed");
        }
    };

    const handleFileAudio = async (file) => {
        if (!file) return;
        if (file.size > 4 * 1024 * 1024) return "fileSizeError";
        const fileBlob = new Blob([file], { type: file.type });
        const url = URL.createObjectURL(fileBlob);
        let audioDuration = 0;
        try {
            audioDuration = await getAudioDuration(fileBlob);
        } catch (error) {
            console.error("Erreur lors de la récupération de la durée de l'audio :", error);
            return "fileDecodeError";
        }

        setAudio(fileBlob);
        setAudioUrl(url);
        setDuration(audioDuration * 1000);
        setStatus("rev-pause");
        audioChunkRef.current = [];

        initAudioCallback();
        stop();
    };

    const initAudioCallback = () => {
        audioRef.current.onplay = () => setStatus((s) => s.split("-")[0] + "-play");
        audioRef.current.onpause = () => setStatus((s) => s.split("-")[0] + "-pause");
        audioRef.current.ontimeupdate = () => setCurrentTime(audioRef.current.currentTime);
    };

    const handlePause = () => {
        if (mediaRecorderRef.current && status === "rec-play") {
            mediaRecorderRef.current.pause();
        } else if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    const handleResume = () => {
        if (mediaRecorderRef.current && status === "rec-pause") {
            mediaRecorderRef.current.resume();
        } else if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const handleStop = () => {
        if (mediaRecorderRef.current && status.startsWith("rec-")) {
            mediaRecorderRef.current.stop();
        } else if (audioRef.current) {
            audioRef.current.pause();
        }
    };

    const handleReset = () => {
        setAudio(null);
        setAudioUrl(null);
        setDuration(0);
        setCurrentTime(null);
        audioChunkRef.current = [];
        setStatus("idle");
        mediaRecorderRef.current = null;

        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            audioRef.current.src = "";
        }
    };

    const stop = () => {
        if (!mediaRecorderRef?.current) return;
        mediaRecorderRef?.current?.stop();
        mediaRecorderRef?.current?.stream?.getTracks().forEach((track) => track.stop());
        mediaRecorderRef.current = null;
    };

    return {
        audioRef,
        audio,
        audioUrl,
        duration,
        currentTime,
        status,
        mediaRecorderRef,
        handleStartRecording,
        handleFileAudio,
        handlePause,
        handleResume,
        handleStop,
        handleReset,
        stop,
        setStatus,
        setDuration,
        initAudioCallback,
    };
};

export default useAudioRecorder;
