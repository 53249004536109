import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

import { arrayUnion, doc, updateDoc } from "firebase/firestore";

import { db } from "../services/firebase";

import { IoClose } from "react-icons/io5";

export const ReportPopin = ({ user, type, context, ...props }) => {
    const [text, setText] = useState("");

    const handleReportSend = () => {
        if (text.length < 3) {
            alert("Veuillez saisir un message valide");
            return;
        }
        const docRef = doc(db, type, context);
        updateDoc(docRef, {
            // Array union
            reports: arrayUnion({
                reason: text,
                user: doc(db, "users", user.id),
                date: new Date(),
                status: "open",
            }),
        });
        setText("");
        props.onHide(false);
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
            <Modal.Header>
                <Modal.Title>Signaler {type === "audio" ? "ce contenu" : "cette personne"}</Modal.Title>
                <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40 }} onClick={props.onHide}>
                    <IoClose style={{ width: 40, height: 40 }} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-2">
                    <textarea placeholder={`Mon message`} className="form-control mt-2" value={text} onChange={(e) => setText(e.target.value)} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    Annuler
                </Button>
                <Button variant="primary" onClick={handleReportSend}>
                    Envoyer
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
