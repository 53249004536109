import { React, useContext, useEffect, useRef, useState } from "react";
import { Badge, Button, Card, Col, Container, Dropdown, Modal, Row, Spinner } from "react-bootstrap";
import { isAndroid, isDesktop, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment";

import { GlobalContext } from "../context/GlobalContext";
import { useUserContext } from "../context/UserContext";

import { db } from "../services/firebase";
import { event_page_vocast, event_vocast_like, event_vocast_listen } from "../services/stats";

import Promo from "../layout/Promo";
import TopBar from "../layout/TopBar";
import Layout from "../layout/index";

import { ContactPopin } from "../components/ContactPopin";
import Desc from "../components/Desc";
import { FuturPopin } from "../components/FuturPopin";
import { IframePopin } from "../components/IframePopin";
import { QRCodePopin } from "../components/QRCodePopin";
import { ReportPopin } from "../components/ReportPopin";
import { IsAuth } from "../components/Responsive";
import { Tag } from "../components/Tag";
import WaveFormPlayer from "../components/WaveFormPlayer";

import CodeIconWhite from "../Assets/pictos/code-white.svg";
import { ReactComponent as CodeIcon } from "../Assets/pictos/code.svg";
import { ReactComponent as Comment } from "../Assets/pictos/comment.svg";
import { ReactComponent as ExportIcon } from "../Assets/pictos/export.svg";
import { ReactComponent as Headphones } from "../Assets/pictos/headphones.svg";
import { ReactComponent as Heart } from "../Assets/pictos/heart.svg";
import pauseMini from "../Assets/pictos/pause-mini.svg";
import playMini from "../Assets/pictos/play-mini.svg";
import { ReactComponent as ReportIcon } from "../Assets/pictos/report.svg";

import { FaHeart, FaTimes } from "react-icons/fa";
import { FaImage } from "react-icons/fa6";
import { FaRegEnvelope } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa6";
import { HiMiniPencilSquare, HiMiniQrCode } from "react-icons/hi2";
import { IoIosMore } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const defaultUrlBg = "https://storage.googleapis.com/vocast-public/assets/background/default.jpg";
export default function AudioDetails() {
    const { i18n, t } = useTranslation();
    const { user: currentUser, toggleFollow, isFollowing } = useUserContext();
    const navigate = useNavigate();
    const { setAudioSource, audioSource, audioStatus, audioPlayer, setAudioStatus } = useContext(GlobalContext);
    const { audioId } = useParams();
    const [audio, setAudio] = useState(null);
    const [originalAudioUrl, setOriginalAudioUrl] = useState(null);
    const [user, setUser] = useState(null);
    const [picture, setPicture] = useState(defaultUrlBg);
    const [error, setError] = useState(false);
    const [listners, setListners] = useState(0);
    const [backgroundIdx, setBackgroundIdx] = useState(0);

    const [languages] = useState(["de", "en", "es", "fr", "it"]);
    const [language, setLanguage] = useState(languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");

    const [comments, setComments] = useState([]);
    const [duration, setDuration] = useState(0);
    const [showImage, setShowImage] = useState(false);
    const [imageIdx, setImageIdx] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalFutur, setModalFutur] = useState(null);

    const [modalChoice, setModalChoice] = useState(false);
    const [modalIframe, setModalIframe] = useState(false);
    const [modalQRCode, setModalQRCode] = useState(false);
    const [modalContact, setModalContact] = useState(false);
    const [modalReport, setModalReport] = useState(false);
    const [modalPost, setModalPost] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [details, setDetails] = useState({
        noDetailProfile: false,
        noStats: false,
        noAction: false,
        noTraduction: false,
        noCollection: false,
        downloadable: false,
    });
    const [currentRate, setCurrentRate] = useState(1);
    const [isLiked, setIsLiked] = useState(false);

    // var storage = getStorage();
    // const storeUrl = isAndroid ? "https://play.google.com/store/apps/details?id=com.vocast" : "https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563";
    const audioPlayerRef = useRef(null);

    const followUnfollowUser = async (e, userId) => {
        e.preventDefault();
        e.stopPropagation();
        if (!userId) return;
        if (!currentUser) return navigate("/signup");
        if (currentUser.uid === userId) return;
        await toggleFollow(userId);
    };

    const openModalFutur = (e, type) => {
        e.preventDefault();
        e.stopPropagation();
        setModalFutur(type);
    };

    useEffect(() => {
        if (!audioSource?.audio?.id || !user || !user.id) return;
        let contributorId = audioSource?.audio?.contributor?.status === "enabled" ? audioSource?.audio?.contributor?.id : null;
        let vocasterId = user?.vocasters?.[audioSource?.audio?.vocaster];
        event_vocast_listen({
            audioId: audioSource?.audio?.id,
            ownerId: user.id,
            contributorId: contributorId || vocasterId,
            audioLanguage: language || audioSource?.audio?.locale,
            listener: currentUser?.uid || "anonymous",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioSource]);

    useEffect(() => {
        setImageIdx(0);
    }, [showImage]);

    useEffect(() => {
        setBackgroundIdx(0);
    }, [audio?.pictures]);

    useEffect(() => {
        if (!audioPlayer.current) return;
        if (audioSource?.audio?.id !== audio?.id) return;
        if (audioPlayer.current.playbackRate !== currentRate) audioPlayer.current.playbackRate = currentRate;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioPlayer, audioSource, audio, currentRate]);

    useEffect(() => {
        // Vérifier si nous avons assez d'images pour la transition
        if (!audio?.pictures?.length || audio.pictures.length <= 1) return;

        // Définir l'intervalle pour les transitions récurrentes
        const interval = setInterval(() => {
            setBackgroundIdx((current) => (current + 1) % audio.pictures.length);
        }, 4000); // 4 secondes entre chaque transition

        // Nettoyer l'intervalle quand le composant est démonté ou quand audio.pictures change
        return () => clearInterval(interval);
    }, [audio?.pictures]);

    useEffect(() => {
        const audioRef = doc(db, "audio", audioId);
        const audioDoc = getDoc(audioRef);

        audioDoc
            .then(async (docRef) => {
                if (docRef.exists()) {
                    let audioData = docRef.data();
                    audioData.id = docRef.id;

                    // Event page audio
                    event_page_vocast({ vocastId: audioData.id, ownerId: audioData.ownerId });

                    document.title = audioData.title;
                    let metaDescription = document.querySelector('meta[name="description"]');
                    metaDescription.setAttribute("content", audioData.description);

                    if (audioData.pictures && audioData.pictures.length > 0) setPicture(audioData.pictures[0]);
                    setAudio({ locale: "fr", ...audioData });
                    // setOriginalAudioUrl(await getDownloadURL(ref(storage, audioData.audio)));
                    setOriginalAudioUrl(audioData.url);
                    setDuration(audioData.duration);
                    setDetails({ ...details, ...audioData.details });

                    if (currentUser && audioData?.likes?.hasOwnProperty?.(currentUser?.uid)) {
                        setIsLiked(true);
                    }

                    let lng = audioSource?.audio?.locale;
                    if (!lng && audioData.details?.noTraduction) lng = audioData.locale;
                    setLanguage(lng || languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");

                    // const userDoc = audioData.owner.get();
                    const userDoc = getDoc(audioData.owner);
                    userDoc
                        .then((docRef) => {
                            if (docRef.exists()) {
                                let userData = docRef.data();
                                userData.id = docRef.id;
                                if (userData.profilePicture && !audioData?.pictures?.length) setPicture(userData.profilePicture);
                                setUser(userData);
                            }
                        })
                        .catch((error) => {
                            console.log("Error getting document:", error);
                        });

                    // Loop over audioData.comments and get user from owner relation path synchroneously
                    let tmpComments = [];
                    // eslint-disable-next-line
                    if (audioData.comments && Object.keys(audioData.comments).length > 0) {
                        for (const [key, value] of Object.entries(audioData.comments)) {
                            const userDoc = await getDoc(value.user);
                            if (userDoc.exists()) {
                                let userData = userDoc.data();
                                userData.id = userDoc.id;
                                userData.key = key;
                                value.user = userData;
                                tmpComments.push(value);
                            }
                        }
                    }
                    // sort tmpComments by date desc
                    tmpComments.sort((a, b) => (a.date > b.date ? -1 : 1));
                    setComments(tmpComments);
                } else {
                    console.log("No such document!");
                    setError(true);
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
                setError(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioId, currentUser]);

    useEffect(() => {
        // Update isLiked state when audio or currentUser changes
        if (currentUser && audio?.likes?.hasOwnProperty?.(currentUser?.uid)) {
            setIsLiked(true);
        } else {
            setIsLiked(false);
        }
    }, [audio, currentUser]);

    useEffect(() => {
        // If audio is playing, get current time
        if (audio && audioStatus === "play" && audioPlayer && audioPlayer.current && audioSource && audioSource.audio && audio && audioSource.audio.id === audio.id) {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            audioPlayerRef.current = setInterval(() => {
                setDuration((audio.duration || audioPlayer.current.duration * 1000) - audioPlayer.current.currentTime * 1000);
            }, 1000);
        } else {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            setDuration(audio ? audio.duration : 0);
        }

        if (audio && audio.listners) {
            const listnersCounter = Object.keys(audio.listners).map((key) => {
                if (Array.isArray(audio.listners[key])) {
                    return audio.listners[key].length;
                }
                return 1;
            });
            setListners(listnersCounter.reduce((a, b) => a + b, 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioStatus, audio]);

    useEffect(() => {
        if (audioPlayer.current.isLocalyListened !== true && audio && audioPlayer && audioPlayer.current && audioPlayer.current.currentTime && audioPlayer.current.currentTime > 0) {
            audioPlayer.current.isLocalyListened = true;
            let listnersObject = {};
            // Clone audio variable
            let tmpAudio = JSON.parse(JSON.stringify(audio));
            tmpAudio.date = audio.date;
            if (audio && audio.listners) {
                listnersObject["listners.anonymous"] = audio.listners.anonymous ? [...audio.listners.anonymous, new Date()] : [new Date()];
                tmpAudio.listners.anonymous = audio.listners.anonymous ? [...audio.listners.anonymous, new Date()] : [new Date()];
            } else {
                listnersObject["listners.anonymous"] = [new Date()];
                tmpAudio.listners = {
                    anonymous: [new Date()],
                };
            }

            const audioRef = doc(db, "audio", audioId);
            setAudio(tmpAudio);
            updateDoc(audioRef, listnersObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    const handlePlay = async (audio, { percent = 0, forceLocale = false } = {}) => {
        const enUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${audioId}/${language}`;

        if (audioStatus === "stop") {
            // const storageRef = ref(storage, audio.audio);
            // const audioFile = await getDownloadURL(storageRef);
            const audioFile = audio.url;
            setAudioSource({ url: language === audio.locale ? audioFile : enUrl, audio, percent });
        } else if (audioStatus === "play" && audioSource && audioSource.audio && audio && audioSource.audio.id === audio.id) {
            audioPlayer.current.pause();
            setAudioStatus("pause");
        } else if (audioStatus === "pause" && audioSource && audioSource.audio && audio && audioSource.audio.id === audio.id) {
            audioPlayer.current.play();
            setAudioStatus("play");
        } else if (audioSource && audioSource.audio && audio && audioSource.audio.id !== audio.id) {
            // const storageRef = ref(storage, audio.audio);
            // const audioFile = await getDownloadURL(storageRef);
            const audioFile = audio.url;
            setAudioSource({ url: language === audio.locale ? audioFile : enUrl, audio, percent });
        }
    };

    useEffect(() => {
        const enUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${audioId}/${language}`;
        // Check if player is playing
        // audioSource && audioSource.audio && audioSource.audio.id === audioSource?.audio?.id
        if (audioStatus === "play" && audioSource && audioSource.audio && audioSource.audio.id && audio && audioSource.audio.id === audio.id) {
            if (language === audio.locale) {
                setAudioSource({ url: originalAudioUrl, audio: audio });
            } else {
                setAudioSource({ url: enUrl, audio: audio });
            }
        } else if (audioStatus === "pause" && originalAudioUrl) {
            // Stop player and change audio source
            audioPlayer.current.pause();
            audioPlayer.current.currentTime = 0;
            if (language === audio.locale) {
                setAudioSource({ url: originalAudioUrl, audio: audio });
            } else {
                setAudioSource({ url: enUrl, audio: audio });
            }
            // audioPlayer.current.play();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const handleShare = () => {
        // If mobile share api is available, use it
        if (navigator.share) {
            navigator
                .share({
                    url: window.location.href,
                })
                .then(() => {
                    console.log("Thanks for sharing!");
                })
                .catch(console.error);
        }
    };

    const ShowImage = () => (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: window.innerHeight,
                overflow: "hidden",
                backgroundColor: "rgba(0, 0, 0, 0.8)",
                zIndex: 1000,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {audio.pictures.length > 1 && (
                <>
                    <FaChevronLeft
                        style={{ position: "absolute", top: "50%", left: 10, color: "#fff", width: 40, height: 40, cursor: "pointer", filter: "drop-shadow( 4px 4px 4px rgba(0, 0, 0, .8))" }}
                        onClick={() => setImageIdx((mg) => (mg - 1 + audio.pictures.length) % audio.pictures.length)}
                    />
                    <FaChevronRight
                        style={{ position: "absolute", top: "50%", right: 10, color: "#fff", width: 40, height: 40, cursor: "pointer", filter: "drop-shadow( 4px 4px 4px rgba(0, 0, 0, .8))" }}
                        onClick={() => setImageIdx((mg) => (mg + 1) % audio.pictures.length)}
                    />
                </>
            )}
            <img alt="Vocast" src={audio.pictures[imageIdx]} style={{ maxHeight: "95%", maxWidth: "95%", objectFit: "contain" }} onClick={() => setShowImage(false)} />
            <IoClose style={{ position: "absolute", top: 10, right: 10, color: "#fff", width: 40, height: 40, cursor: "pointer" }} onClick={() => setShowImage(false)} />
        </div>
    );

    const ModalChoice = (props) => {
        const handleQRCode = () => {
            setModalQRCode(true);
            setModalChoice(false);
        };

        const handleEmbed = () => {
            setModalIframe(true);
            setModalChoice(false);
        };

        const handleReport = () => {
            setModalReport(true);
            setModalChoice(false);
        };

        const handlePost = () => {
            setModalPost(true);
            setModalChoice(false);
        };

        return (
            <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
                <Modal.Body>
                    <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40, position: "absolute", right: 8, top: 8 }} onClick={props.onHide}>
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                    <IsAuth>
                        {currentUser?.id && audio?.ownerId === currentUser?.id && (
                            <>
                                <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handlePost}>
                                    <HiMiniPencilSquare size={25} />
                                    <span>{t("common:actions:edit")}</span>
                                </div>
                                <hr />
                            </>
                        )}
                    </IsAuth>
                    {!details.noAction && (
                        <>
                            {!isDesktop && (
                                <>
                                    <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleQRCode}>
                                        <HiMiniQrCode size={25} />
                                        <span>{t("Audio:shareQRCode")}</span>
                                    </div>
                                    <hr />
                                </>
                            )}

                            {!isDesktop && (
                                <>
                                    <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleEmbed}>
                                        <CodeIcon style={{ width: 25, height: 25 }} />
                                        <span>{t("Audio:embedWebPage")}</span>
                                    </div>
                                    <hr />
                                </>
                            )}
                        </>
                    )}
                    <div style={{ cursor: "pointer", gap: 10, display: "flex" }} onClick={handleReport}>
                        <ReportIcon style={{ width: 25, height: 25 }} />
                        <span>{t("Audio:reportContent")}</span>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const handleOpenAppClick = async (e, url) => {
        e.stopPropagation();
        if (url && typeof url === "string") window.open(url, e.ctrlKey || e.metaKey ? "_blank" : "_self").focus();

        setTimeout(function () {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.vocast";
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563";
            } else {
                setShowModal(true);
            }
        }, 100);
    };

    const handleDownload = async () => {
        if (!audio || !audio.url) return;

        setIsDownloading(true);
        try {
            const response = await axios.get(audio.url, {
                responseType: "blob",
            });
            const blob = response.data;
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `${audio.audio.split("/").pop() || "audio.mp3"}`;
            link.click();
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error("Error downloading audio:", error);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleLike = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Redirect to signup if user is not logged in
        if (!currentUser) {
            navigate("/signup");
            return;
        }

        // Toggle like status
        const audioRef = doc(db, "audio", audioId);
        const likeField = `likes.${currentUser.uid}`;

        if (isLiked) {
            // Remove like
            const updateData = {};
            updateData[likeField] = deleteField();
            await updateDoc(audioRef, updateData);

            // Update local state
            const updatedAudio = { ...audio };
            if (updatedAudio.likes) {
                delete updatedAudio.likes[currentUser.uid];
                setAudio(updatedAudio);
            }
            setIsLiked(false);
        } else {
            // Add like
            const updateData = {};
            updateData[likeField] = new Date();
            await updateDoc(audioRef, updateData);
            event_vocast_like({ vocastId: audioId, environment: "webapp" });
            // Update local state
            const updatedAudio = { ...audio };
            if (!updatedAudio.likes) updatedAudio.likes = {};
            updatedAudio.likes[currentUser.uid] = new Date();
            setAudio(updatedAudio);
            setIsLiked(true);
        }
    };

    if (error)
        return (
            <Row>
                <Col xs={12} className="d-flex justify-content-center">
                    {t("Audio:pageNotFound")}
                </Col>
            </Row>
        );

    const isPlaying = audio && audioStatus === "play" && audioSource?.audio?.id && audioSource.audio.id === audio.id;

    let profile = {};
    const isVocasterPicture = (audio?.contributor?.status === "enabled" && audio?.vocaster?.profilePicture) || (user?.vocasters?.[audio?.vocaster] && user?.vocasters?.[audio?.vocaster]?.profilePicture);
    if (audio?.contributor?.status === "enabled") profile = audio?.contributor;
    else if (user?.vocasters?.[audio?.vocaster]) profile = user?.vocasters?.[audio?.vocaster];
    else profile = user;

    return (
        <Layout>
            <Container fluid className="p-0" style={{ display: "flex", justifyContent: "center", maxWidth: 600, paddingBottom: 50 }}>
                {showImage && <ShowImage />}
                <FuturPopin show={modalFutur} onHide={() => setModalFutur(null)} />
                <IframePopin show={modalIframe} onHide={() => setModalIframe(false)} {...{ audio, audioId }} />
                <ModalChoice show={modalChoice} onHide={() => setModalChoice(false)} />
                <QRCodePopin show={modalQRCode} onHide={() => setModalQRCode(false)} nickname={user?.nickname} audioId={audioId} isOwnerPicture={!!user?.profilePicture} isVocasterPicture={isVocasterPicture} />
                <ContactPopin show={modalContact} onHide={() => setModalContact(false)} {...{ context: audioId, user }} />
                <ReportPopin show={modalReport} onHide={() => setModalReport(false)} {...{ type: "audio", context: audioId, user }} />
                <div
                    style={{
                        minHeight: "100dvh",
                        color: "#fff",
                        maxWidth: 800,
                        overflowY: "auto",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                    }}
                >
                    <div style={{ position: "absolute", inset: 0, zIndex: 0, overflow: "hidden" }}>
                        {audio?.pictures?.length > 1 ? (
                            <>
                                {audio.pictures.map((picture, idx) => (
                                    <div
                                        key={idx}
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.3)), url(${picture})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            transition: "opacity 1s ease-in-out",
                                            opacity: idx === backgroundIdx ? 1 : 0,
                                        }}
                                    />
                                ))}
                            </>
                        ) : (
                            <div
                                style={{
                                    position: "absolute",
                                    inset: 0,
                                    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.3)), url(${picture})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                }}
                            />
                        )}
                    </div>
                    <Row
                        className="m-0"
                        style={{
                            maxWidth: 800,
                            width: "100%",
                            justifyContent: "center",
                            flex: 1,
                            alignContent: "start",
                        }}
                    >
                        <TopBar />
                        {!details.noDetailProfile && (
                            <div className="d-flex align-items-center" style={{ cursor: "pointer", gap: "1rem", zIndex: 1, flex: 1, marginLeft: 12, marginRight: 12 }} onClick={(e) => navigate(`/${user?.nickname}`)}>
                                <div className="d-flex align-items-center" style={{ gap: 10, flex: 1 }}>
                                    <div style={{ width: "auto" }}>
                                        {user?.profilePicture && (
                                            <img
                                                alt={user?.name}
                                                src={user?.profilePicture || defaultUrlBg}
                                                style={{ width: "100%", borderRadius: "42%", aspectRatio: 1, objectFit: "cover", backgroundPosition: "center", maxWidth: 52, height: "100%" }}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <h5 className="m-0" style={{ fontSize: "1.15rem" }}>
                                            {user?.name}
                                        </h5>
                                        <div>
                                            <small>@{user?.nickname}</small>
                                        </div>
                                    </div>
                                </div>
                                {currentUser?.uid !== user?.id ? (
                                    <div>
                                        <Button variant="outline-light" onClick={(e) => followUnfollowUser(e, user?.id)}>
                                            {isFollowing(user?.id) ? t("common:actions.followed") : t("common:actions.follow")}
                                        </Button>
                                    </div>
                                ) : (
                                    <div>
                                        <Button variant="outline-light" onClick={(e) => openModalFutur(e, "settings")}>
                                            {t("common:actions.editProfile")}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Row>
                    <Row
                        style={{
                            alignContent: "center",
                            flexDirection: "column",
                            margin: `20px 0 70px 0`,
                        }}
                    >
                        <Col xs={12} style={{}}>
                            <Card style={{ width: "100%", borderRadius: "2em", backgroundColor: "rgba(51, 51, 51, 0.8)", color: "#fff" }}>
                                <Card.Body>
                                    <div>
                                        <h1 style={{ marginRight: 40, marginBottom: 12 }}>{audio && audio.title}</h1>
                                        <div style={{ position: "absolute", right: 15, top: 15, cursor: "pointer" }} onClick={() => setModalChoice(true)}>
                                            <IoIosMore size={30} />
                                        </div>
                                    </div>
                                    <Desc desc={audio?.description || ""} />
                                    {!details.noCollection && <Tag collection={audio?.collections} user={user} />}
                                    <div className="d-flex align-items-center justify-content-between gap-2" style={{ marginTop: 13 }}>
                                        {profile?.profilePicture && (
                                            <div>
                                                <img
                                                    src={profile.profilePicture || defaultUrlBg}
                                                    alt={profile.profilePicture || "Vocast"}
                                                    style={{
                                                        width: 48,
                                                        height: 48,
                                                        borderRadius: "50%",
                                                        objectFit: "cover",
                                                    }}
                                                />
                                            </div>
                                        )}

                                        {(duration > 0 || isPlaying) && (
                                            <div style={{ display: "flex", gap: 1, alignItems: "center", flexDirection: "column" }}>
                                                <div style={{ fontSize: 12, width: 46, display: "flex", justifyContent: "center", alignItems: "center" }}>{duration && moment(duration).format("mm:ss")}</div>
                                                {isPlaying && (
                                                    <div
                                                        style={{
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                            cursor: "pointer",
                                                            padding: 3,
                                                            background: "radial-gradient(rgba(255, 255, 255, 0.3), transparent)",
                                                            borderRadius: 30,
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            userSelect: "none",
                                                            width: 46,
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            const playbackRates = [1, 1.2, 1.5, 2];
                                                            setCurrentRate(playbackRates[(playbackRates.indexOf(currentRate) + 1) % playbackRates.length]);
                                                        }}
                                                    >
                                                        <FaTimes size={10} />
                                                        {currentRate}
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div style={{ width: "100%", overflow: "hidden" }}>
                                            <WaveFormPlayer audio={audio} audioPlayer={audioPlayer} handlePlay={handlePlay} />
                                        </div>
                                        {!details.noTraduction && (
                                            <div style={{}} className="d-flex justify-content-center">
                                                <Dropdown data-bs-theme="dark" variant="dark">
                                                    <Dropdown.Toggle
                                                        variant="link"
                                                        className="p-0 m-0"
                                                        id="dropdown-basic"
                                                        style={{
                                                            textDecoration: "none",
                                                            color: "#fff",
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {/* {language === "VO" ? <TbWorld size={20} /> : <div style={{ fontWeight: "bold" }}>EN</div>} */}
                                                        {/* <div style={{ fontWeight: "bold" }}>{language.toUpperCase()}</div> */}
                                                        <img src={`https://storage.googleapis.com/vocast-public/assets/flags/${language}.png`} alt={language} style={{ width: 20, height: 20 }} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu style={{ backgroundColor: "#121212" }}>
                                                        {languages.map((lng) => (
                                                            <Dropdown.Item
                                                                style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    color: "#fff",
                                                                    fontWeight: "bold",
                                                                    gap: 4,
                                                                }}
                                                                onClick={() => setLanguage(lng)}
                                                                key={`embed-${lng}`}
                                                            >
                                                                <img src={`https://storage.googleapis.com/vocast-public/assets/flags/${lng}.png`} alt={lng} style={{ width: 20, height: 20 }} />
                                                                {lng.toUpperCase()} {lng !== audio?.locale && "(✨IA)"}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        )}
                                        <div style={{}}>
                                            <Button variant="empty" size="sm" onClick={(e) => handlePlay(audio)} className="p-0 m-0">
                                                {isPlaying ? (
                                                    <img alt="pause" src={pauseMini} style={{ width: "48px", height: "48px" }} />
                                                ) : audioStatus === "loading" ? (
                                                    <div style={{ width: "48px", height: "48px" }} className="d-flex justify-content-center align-items-center">
                                                        <Spinner variant="light" style={{ width: "30px", height: "30px" }} />
                                                    </div>
                                                ) : (
                                                    <img alt="play" src={playMini} style={{ width: "48px", height: "48px" }} />
                                                )}
                                            </Button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex gap-4 align-items-center" style={{ marginLeft: 12 }}>
                                            {!details.noStats && (
                                                <>
                                                    <div className="bluredIconButton nfd d-flex gap-2 m-0 p-0 align-items-center">
                                                        <Headphones alt="Listens" style={{ maxWidth: 18, marginBottom: 4 }} />
                                                        <span style={{ marginTop: 1 }}>{listners}</span>
                                                    </div>
                                                    <div className="bluredIconButton nfd d-flex gap-2 m-0 p-0 align-items-center" style={{ cursor: "pointer" }} onClick={handleLike}>
                                                        <Heart alt="likes" style={{ maxWidth: 18, color: isLiked ? "#ff4d4d" : "#fff" }} />
                                                        <span style={{ marginTop: 1 }}>{audio && audio.likes ? Object.keys(audio.likes).length : 0}</span>
                                                    </div>
                                                    {/* <div className="bluredIconButton nfd d-flex gap-1 m-0 align-items-center" style={{ padding: 11 }}>
                                                        <Comment alt="comments" style={{ maxWidth: 18 }} />
                                                        <span style={{ marginTop: 1 }}>{audio && audio.comments ? Object.keys(audio.comments).length : 0}</span>
                                                    </div> */}
                                                </>
                                            )}
                                        </div>

                                        <div className="d-flex justify-content-center align-content-center gap-2 ">
                                            {!details.noAction && (
                                                <>
                                                    {audio?.pictures?.length ? (
                                                        <div
                                                            style={{
                                                                background: "radial-gradient(rgba(255,255,255,0.3), transparent)",
                                                                width: 46,
                                                                height: 46,
                                                            }}
                                                            className="d-flex justify-content-center align-items-center bluredIconButton mini nomargin"
                                                        >
                                                            <button
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: "#fff",
                                                                    background: "transparent",
                                                                    border: "none",
                                                                }}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowImage(true);
                                                                }}
                                                            >
                                                                <FaImage size={18} />
                                                            </button>
                                                        </div>
                                                    ) : null}

                                                    {isDesktop && (
                                                        <>
                                                            <div
                                                                style={{ background: "radial-gradient(rgba(255,255,255,0.3), transparent)", width: 46, height: 46 }}
                                                                className="d-flex justify-content-center align-items-center bluredIconButton mini nomargin"
                                                            >
                                                                <button style={{ textDecoration: "none", color: "#fff", background: "transparent", border: "none" }} onClick={() => setModalQRCode(true)}>
                                                                    <HiMiniQrCode size={23} />
                                                                </button>
                                                            </div>
                                                            <div
                                                                style={{ background: "radial-gradient(rgba(255,255,255,0.3), transparent)", width: 46, height: 46 }}
                                                                className="d-flex justify-content-center align-items-center bluredIconButton mini nomargin"
                                                            >
                                                                <button style={{ textDecoration: "none", color: "#fff", background: "transparent", border: "none" }} onClick={(e) => setModalIframe(true)}>
                                                                    <img src={CodeIconWhite} alt="Code" style={{ width: 18, height: 18 }} />
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                    {navigator.canShare && navigator.canShare({ url: window.location.href }) && (
                                                        <div className="d-flex" style={{ gap: 5 }}>
                                                            {(navigator.share || document.location.hostname === "localhost" || document.location.hostname.includes("192")) && (
                                                                <div
                                                                    style={{ background: "radial-gradient(rgba(255,255,255,0.3), transparent)", width: 46, height: 46 }}
                                                                    className="d-flex justify-content-center align-items-center bluredIconButton mini nomargin"
                                                                >
                                                                    <button style={{ textDecoration: "none", color: "#fff", background: "transparent", border: "none" }} onClick={(e) => handleShare()}>
                                                                        <ExportIcon style={{ width: 18 }} />
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            {details.downloadable && (
                                                <div className="d-flex" style={{ gap: 5 }}>
                                                    <div
                                                        style={{ background: "radial-gradient(rgba(255,255,255,0.3), transparent)", width: 46, height: 46 }}
                                                        className="d-flex justify-content-center align-items-center bluredIconButton mini nomargin"
                                                    >
                                                        <button style={{ textDecoration: "none", color: "#fff", background: "transparent", border: "none" }} onClick={(e) => handleDownload()} disabled={isDownloading}>
                                                            {isDownloading ? <Spinner variant="light" size="sm" /> : <FaDownload style={{ width: 18 }} />}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            {audio?.useForm && (
                                <div>
                                    <Button
                                        style={{ color: "#fff", marginTop: 10, backgroundColor: "black", border: 0, borderRadius: 50, display: "flex", alignItems: "center", gap: 10 }}
                                        onClick={() => setModalContact(true)}
                                    >
                                        <FaRegEnvelope size={20} /> {t("Audio:contactUser", { username: user?.name })}
                                    </Button>
                                </div>
                            )}
                        </Col>

                        {comments && comments.length > 0 && (
                            <Col xs={12} style={{ marginTop: 10 }}>
                                <Card style={{ width: "100%", borderRadius: "2em", backgroundColor: "rgba(51, 51, 51, 0.8)", color: "#fff" }}>
                                    <Card.Body className="custom-scroll" style={{ overflow: "auto", maxHeight: 300 }}>
                                        <Card.Title style={{ fontSize: "1rem" }}>{t("Audio:comments", { count: comments.length })}</Card.Title>
                                        {comments.map((comment) => (
                                            <Row key={comment.commentId} className="mb-3 align-item-center">
                                                <Col xs={3}>
                                                    {comment.user?.profilePicture && (
                                                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                        <a
                                                            href={`/${comment.user && comment.user.nickname}`}
                                                            onClick={(e) => handleOpenAppClick(e, `/${comment.user && comment.user.nickname}`)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img
                                                                alt={comment.user.name}
                                                                src={comment.user.profilePicture}
                                                                style={{ width: "100%", borderRadius: "42%", aspectRatio: 1, objectFit: "cover", backgroundPosition: "center" }}
                                                            />
                                                        </a>
                                                    )}
                                                </Col>
                                                <Col xs={9}>
                                                    <div>
                                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                        <a
                                                            href={`/${comment.user && comment.user.nickname}`}
                                                            onClick={(e) => handleOpenAppClick(e, `/${comment.user?.nickname}`)}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                            className="text-decoration-none text-white"
                                                        >
                                                            <b>{comment.user?.name}</b>
                                                        </a>
                                                    </div>
                                                    <div>@{comment.user?.nickname}</div>
                                                    <div>
                                                        <small>{moment(comment.date.toDate()).fromNow()}</small>
                                                    </div>
                                                    <div>{comment.comment}</div>
                                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                                    <a href={`/${comment.user && comment.user.nickname}`} onClick={(e) => handleOpenAppClick(e, `/${comment.user?.nickname}`)} target="_blank" rel="noreferrer">
                                                        <Badge pill bg="secondary">
                                                            <FaHeart style={{ marginRight: 5 }} /> {Object.keys(comment.likes || {}).length}
                                                        </Badge>
                                                    </a>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Card.Body>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </div>

                {/* <Promo showModal={showModal} setShowModal={setShowModal} handleOpenAppClick={handleOpenAppClick} /> */}
            </Container>
        </Layout>
    );
}
