import React from "react";

import { IconButton, PauseMini, PlayerMini, RecordMini, StopMini } from "./commun";

const Btn = ({ status, inside, outer, allowRecordingPause = true, handleStopSimple, ...rc }) => {
    if (!allowRecordingPause && status === "rec-play") {
        return <IconButton icon={<StopMini style={{ width: 48, height: 48 }} inside={inside} outer={outer} />} onClick={handleStopSimple || rc.handleStop} />;
    }

    switch (status) {
        case "idle":
        case "rec-pause":
            return <IconButton icon={<RecordMini style={{ width: 48, height: 48 }} />} onClick={() => rc.handleStartRecording()} />;
        case "rec-play":
        case "rev-play":
        case "embed-play":
            return <IconButton icon={<PauseMini style={{ width: 48, height: 48 }} inside={inside} outer={outer} />} onClick={() => rc.handlePause()} />;
        case "rev-pause":
        case "embed-pause":
            return <IconButton icon={<PlayerMini style={{ width: 48, height: 48 }} inside={inside} outer={outer} />} onClick={() => rc.handleResume()} />;
        case "not-allowed":
        default:
            return null;
    }
};

export { Btn };
