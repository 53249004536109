import React, { useEffect, useRef, useState } from "react";

const TextSlide = ({ text, style, space = 4 }) => {
    const textRef = useRef(null);
    const [isSliding, setIsSliding] = useState(false);

    const canResize = () => {
        if (!textRef.current) return false;
        const parentWidth = textRef.current.offsetWidth;
        const childWidth = textRef.current.querySelector("div").offsetWidth;
        return childWidth > parentWidth;
    };

    useEffect(() => {
        if (!textRef.current) return;
        if (canResize()) setIsSliding(true);
    }, [textRef]);

    useEffect(() => {
        if (!textRef.current) return;
        const resizeObserver = new ResizeObserver(() => setIsSliding(canResize()));

        resizeObserver.observe(textRef.current);
        return () => resizeObserver.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [textRef]);

    return (
        <div className={`message-defilant ${isSliding ? "slide" : ""}`} style={{ ...style }} ref={textRef}>
            <div data-text={text + "\u00A0".repeat(space)}>
                <span>{text + "\u00A0".repeat(space)}</span>
            </div>
        </div>
    );
};

export default TextSlide;
