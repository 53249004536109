import "./App.css";

import React from "react";
import { BrowserRouter } from "react-router-dom";

import { GlobalProvider } from "./context/GlobalContext";
import I18nProvider from "./context/I18nProvider";
import { RouteListenerProvider } from "./context/RouteListenerProvider";
import { UserProvider } from "./context/UserContext";

import AudioPlayer from "./components/AudioPlayer";

import Router from "./routes";

function App() {
    return (
        <I18nProvider>
            <GlobalProvider>
                <UserProvider>
                    <AudioPlayer />
                    <BrowserRouter>
                        <RouteListenerProvider>
                            <Router />
                        </RouteListenerProvider>
                    </BrowserRouter>
                </UserProvider>
            </GlobalProvider>
        </I18nProvider>
    );
}

export default App;
