import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { initializeI18n } from "../services/i18n";

import logoBlack from "../Assets/img/logo-black.png";
import logoWhite from "../Assets/img/logo-white.png";
import MicroLogo from "../Assets/img/micro-vocast.png";

const I18nLoader = ({ children }) => {
    const url = new URL(window.location.href);
    const dark = url.searchParams.get("dark") === "true";
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        const initI18n = async () => {
            await initializeI18n();
            setIsReady(true);
        };

        initI18n();
    }, []);

    if (!isReady) {
        return (
            <div className="loader-container" style={{ backgroundColor: "var(--bg-color, #000)" }}>
                {window.location.pathname.includes("/embed/") ? (
                    <img src={dark ? logoWhite : logoBlack} alt="Micro Logo" style={{ height: "40px", width: "auto" }} />
                ) : (
                    <div className="loader">
                        <img src={MicroLogo} alt="Micro Logo" style={{ width: "100%", height: "auto" }} />
                    </div>
                )}
            </div>
        );
    }

    return <>{children}</>;
};

const I18nProvider = ({ children }) => {
    return <I18nLoader>{children}</I18nLoader>;
};

export default I18nProvider;
