import { useContext, useEffect } from "react";

import { GlobalContext } from "../context/GlobalContext";

export default function AudioPlayer() {
    const { audioPlayer, audioSource, setAudioStatus } = useContext(GlobalContext);

    useEffect(() => {
        if (audioSource && audioPlayer?.current) {
            // Get audioPlayer status
            if (audioSource && audioSource.url) setAudioStatus("loading");
            audioPlayer.current.load();
            audioPlayer.current.oncanplay = () => {
                if (audioSource.percent) {
                    audioPlayer.current.currentTime = Math.round(audioPlayer.current.duration * audioSource.percent * 100) / 100;
                }
                audioPlayer.current.play();
                setAudioStatus("play");
                audioPlayer.current.oncanplay = null;
            };
        }

        audioPlayer.current.isLocalyListened = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioSource]);

    return (
        <div className="audio-player">
            <audio
                id="audioPlayer"
                onEnded={() => setAudioStatus("stop")}
                ref={(audioRef) => {
                    audioPlayer.current = audioRef;
                }}
            >
                <source src={audioSource && audioSource.url} />
            </audio>
        </div>
    );
}
