import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { isMobile, osName } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import { GoogleAuthProvider, OAuthProvider, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { generateUsername } from "unique-username-generator";

import { useRouteListener } from "../../context/RouteListenerProvider";

import { auth, db } from "../../services/firebase";
import { event_user_create, event_user_login } from "../../services/stats";

import { nicknameFormat } from "./CompleteProfile/PseudoUsername";

import { ReactComponent as AppleIcon } from "../../Assets/img/apple-icon.svg";
import googleIcon from "../../Assets/img/google-icon.svg";
import logo from "../../Assets/img/logo-white.png";

import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";

export default function Login() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { getLastUrl } = useRouteListener();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const beforeCompleteProfile = async (user) => {
        /*
         * Ici dans le cas de Google et Apple
         *
         * dans le doute ou l'utilisateur quitte la page avant de finaliser l'inscription,
         * on créer un profil par défaut avec les informations de base du provider
         */
        let _nickname = "";
        while (true) {
            _nickname = generateUsername("_", 6, 20, nicknameFormat(user.displayName || user.email.split("@")[0]));
            const userDoc = await getDocs(query(collection(db, "users"), where("nickname", "==", _nickname)));
            if (userDoc.size === 0) break;
        }

        setDoc(doc(db, "users", user.uid), {
            name: user.displayName || user.email.split("@")[0],
            nickname: _nickname,
            creationDate: serverTimestamp(),
            email: user?.email,
            profilePicture: user.photoURL,
            unCompletedProfile: true,
            platform: `webapp_${isMobile ? "mobile" : "desktop"}_${osName}`,
            subscribeFrom: getLastUrl(["/login", "/signup", "/complete-profile", "/forgot-password"]),
        });
        return navigate(`/complete-profile`);
    };

    useEffect(() => {
        document.title = "Vocast - Login";
        const checkUser = async () => {
            if (!auth.currentUser) return;
            const userDoc = await getDoc(doc(collection(db, "users"), auth.currentUser.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                // c'est un cas qui n'existe pas pour l'instant, mais je le laisse ici pour le futur.
                return navigate(`/complete-profile`);
            }
        };
        checkUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y";
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleLoaded = () => {
        window.grecaptcha.ready(async () => {});
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const executeRecaptcha = async () => {
        if (!window.grecaptcha) {
            console.error("reCAPTCHA not loaded");
            return null;
        }

        try {
            const token = await window.grecaptcha.execute("6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y", { action: "login" });
            return token;
        } catch (error) {
            console.error("reCAPTCHA execution failed", error);
            return null;
        }
    };

    const verifyRecaptcha = async (token) => {
        try {
            const response = await axios.post("https://us-central1-vocast-app.cloudfunctions.net/webapp/recaptcha/verify", { token });
            console.log("reCAPTCHA verification response", response.data);
            return response.data.success;
        } catch (error) {
            console.error("reCAPTCHA verification failed", error);
            return false;
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        console.log("Login attempt with email:", email);

        const recaptchaToken = await executeRecaptcha();
        if (["vocast.co", "vocast.twiga.io"].includes(document.location.hostname)) {
            if (!recaptchaToken) {
                setLoading(false);
                setError(t("Login:recaptchaFailed"));
                return;
            }

            const isVerified = await verifyRecaptcha(recaptchaToken);
            if (!isVerified) {
                setLoading(false);
                setError(t("Login:recaptchaFailed"));
                return;
            }
        }

        try {
            console.log('Try auth');
            await signInWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            console.log("User logged in successfully!", user);
            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                event_user_login({ environment: "webapp", userId: user.uid, provider: "email" });
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                event_user_create({ environment: "webapp", userId: user.uid, provider: "email" });
                return navigate(`/complete-profile`);
            }
        } catch (error) {
            console.error("Error logging in:", error);
            setError(t("Login:loginError"));
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                event_user_login({ environment: "webapp", userId: user.uid, provider: "google" });
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                event_user_create({ environment: "webapp", userId: user.uid, provider: "google" });
                beforeCompleteProfile(user);
            }
        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") return;
            console.error("Error logging in Google:", error);
            setError(t("Login:loginError"));
        }
    };

    const handleAppleLogin = async () => {
        const provider = new OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            event_user_login({ environment: "webapp", userId: user.uid, provider: "apple" });
            console.log("User logged in successfully!", user);

            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                beforeCompleteProfile(user);
            }
        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") return;
            console.error("Error logging in Apple:", error);
            setError(t("Login:loginError"));
        }
    };

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://www.vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>
            <div className="text-center" style={{ padding: "24px" }}>
                <h2 style={{ fontSize: 32, color: "white" }}>
                    <b>{t("Login:login")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3" }}>{t("Login:welcomeBack")}</p>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="w-100" onSubmit={handleLogin}>
                <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder={`${t("Login:email")}*`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        required
                        style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                    />
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-2">
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder={`${t("Login:password")}*`}
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                        />
                        <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>

                {/* <div className="d-flex justify-content-start mb-3">
                    <Link to="#" className="text-decoration-none" style={{ fontSize: "14px", color: "#000000" }}>
                        {t("Login:forgotPassword")}
                    </Link>
                </div> */}

                <div className="g-recaptcha" data-sitekey="6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y" data-size="invisible" />

                <Button
                    variant="success"
                    type="submit"
                    className="w-100 mb-3"
                    style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 16, fontWeight: 700 }}
                    disabled={loading || !email || !password}
                >
                    {loading ? t("Login:loginInProcess") : t("Login:continue")}
                </Button>
            </Form>

            <p style={{ fontSize: "14px", color: "#f3f3f3" }}>
                {t("Login:noAccount")}{" "}
                <Link to="/signup" className="text-decoration-none" style={{ color: "#f3f3f3" }}>
                    <b>{t("Login:register")}</b>
                </Link>
            </p>

            <div className="w-100 text-center" style={{ marginTop: "8px", marginBottom: "16px", position: "relative" }}>
                <hr className="w-100" style={{ borderColor: "#f3f3f3" }} />
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "black", padding: "0 12px" }}>
                    <span style={{ fontSize: "12px", color: "#f3f3f3", width: 16, textTransform: "uppercase" }}>{t("Login:or")}</span>
                </div>
            </div>

            <Row className="w-100">
                <Col className="mb-2" xs={12}>
                    <Button
                        variant="outline"
                        className="h-blue w-100 text-start d-flex align-items-center"
                        style={{
                            color: "#f3f3f3",
                            borderRadius: 5,
                            border: "1px solid #6c757d",
                            fontWeight: "bold",
                            fontSize: 14,
                            padding: "12px 12px",
                        }}
                        onClick={handleGoogleLogin}
                    >
                        <img src={googleIcon} alt="Google icon" style={{ marginRight: 14, height: 20, width: "auto" }} />
                        {t("Login:continueWith")} Google
                    </Button>
                </Col>
                <Col className="mb-2" xs={12}>
                    <Button
                        variant="outline"
                        className="h-blue w-100 text-start d-flex align-items-center"
                        style={{
                            color: "#f3f3f3",
                            borderRadius: 5,
                            border: "1px solid #6c757d",
                            fontWeight: "bold",
                            fontSize: 14,
                            padding: "12px 12px",
                        }}
                        onClick={handleAppleLogin}
                    >
                        <AppleIcon style={{ marginRight: 17, color: "inherit", width: "auto", height: 20 }} />
                        {t("Login:continueWith")} Apple
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
