import "react-image-crop/dist/ReactCrop.css";

import React, { useRef, useState } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactCrop, { centerCrop, convertToPixelCrop, makeAspectCrop } from "react-image-crop";

import { collection, doc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

import { auth, db, storage } from "../../../services/firebase";

import logo from "../../../Assets/img/logo-white.png";

import { FaPencil, FaPlus } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const MIN_DIMENSION = 50;
const ASPECT_RATIO = 1;

const getCroppedCanvas = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    return canvas;
};

export default function AvatarBio({ next, user, userData }) {
    const { t } = useTranslation();
    const imgRef = useRef(null);
    const fileInputRef = useRef(null);

    const [profilePicture, setProfilePicture] = useState(null);
    const [profilePictureUrl, setProfilePictureUrl] = useState(userData?.profilePicture || "");

    const [imgSrc, setImgSrc] = useState("");
    const [crop, setCrop] = useState({ unit: "%", height: 100, x: 0, y: 0, aspect: 1 });

    const [description, setDescription] = useState("");

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const onSelectFile = (e) => {
        const file = e.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        reader.addEventListener("load", () => {
            const imageElement = new Image();
            const imageUrl = reader.result?.toString() || "";
            imageElement.src = imageUrl;

            imageElement.addEventListener("load", (e) => {
                if (error) setError("");
                const { naturalWidth, naturalHeight } = e.currentTarget;
                if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
                    setError("Image must be at least 50 x 50 pixels.");
                    return setImgSrc("");
                }
            });
            setImgSrc(imageUrl);
        });
        reader.readAsDataURL(file);
    };

    const uploadToFirebase = () => {
        return new Promise((resolve, reject) => {
            if (!profilePicture) return resolve();

            const storageRef = ref(storage, `assets/thumbnails/${user.uid}/profile.jpg`);
            const uploadTask = uploadBytesResumable(storageRef, profilePicture);

            uploadTask.on(
                "state_changed",
                (snapshot) => console.log(`Progress: ${(snapshot.bytesTransferred / snapshot.totalBytes) * 100}%`),
                (error) => console.error("Erreur d'upload : ", error),
                async () => {
                    const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                    console.log("Image disponible à : ", downloadURL);
                    resolve(downloadURL);
                },
            );
        });
    };

    const onImageLoad = (e) => {
        const { width, height } = e.currentTarget;
        const crop = makeAspectCrop({ unit: "%", width: 100 }, ASPECT_RATIO, width, height);
        const centeredCrop = centerCrop(crop, width, height);
        setCrop(centeredCrop);
    };

    const setCompletedCrop = () => {
        const canvas = getCroppedCanvas(imgRef.current, convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height));
        canvas.toBlob((blob) => {
            setProfilePicture(blob);
            setProfilePictureUrl(URL.createObjectURL(blob));
            setImgSrc(null);
            fileInputRef.current.value = null;
        }, "image/jpeg");
    };

    const handleFinalize = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        if (!profilePicture && !description) return next();
        try {
            const profilePictureUrl = await uploadToFirebase();
            const userData = {};
            if (profilePictureUrl) userData.profilePicture = profilePictureUrl;
            if (description) userData.description = description;
            await setDoc(doc(collection(db, "users"), auth.currentUser.uid), userData, { merge: true });
            next();
        } catch (e) {
            setError(t("CompleteProfile:step2:registerError"));
            console.log({ e });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <Modal show={!!imgSrc} onHide={() => setImgSrc(null)} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
                <Modal.Header>
                    <Modal.Title>{t("CompleteProfile:step2:cropAvatar")}</Modal.Title>
                    <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40 }} onClick={() => setImgSrc(null)}>
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">
                    <ReactCrop crop={crop} onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)} circularCrop keepSelection aspect={ASPECT_RATIO} minWidth={MIN_DIMENSION}>
                        <img ref={imgRef} src={imgSrc} alt="Upload" style={{ maxHeight: "70vh" }} onLoad={onImageLoad} />
                    </ReactCrop>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        type="button"
                        className="w-100 mb-3"
                        style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }}
                        onClick={setCompletedCrop}
                    >
                        {t("CompleteProfile:step2:crop")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://www.vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>

            <div className="text-center" style={{ padding: "24px 16px" }}>
                <h2 style={{ fontSize: 32, color: "#fff" }}>
                    <b>{t("CompleteProfile:step2:title")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3" }}>{t("CompleteProfile:step2:subtitle")}</p>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="w-100" onSubmit={handleFinalize}>
                <div className="d-flex justify-content-center mb-3">
                    <Form.Group controlId="formAvatar" className="mb-3 d-flex position-relative" style={{ width: 150, aspectRatio: 1 }}>
                        <Button
                            variant="link"
                            type="button"
                            style={{
                                width: "100%",
                                aspectRatio: 1,
                                borderRadius: "50%",
                                border: "1px dashed #fff",
                                color: "#fff",
                                backgroundImage: profilePictureUrl ? `url(${profilePictureUrl})` : "none",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}
                        >
                            {profilePictureUrl ? <FaPencil size={24} /> : <FaPlus size={24} />}
                        </Button>
                        <Form.Control ref={fileInputRef} type="file" accept="image/*" value={undefined} onChange={onSelectFile} className="position-absolute top-0 left-0 w-100 h-100 opacity-0" />
                    </Form.Group>
                </div>

                <Form.Group controlId="formDescription" className="mb-3">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        type="text"
                        placeholder={`${t("CompleteProfile:step2:description")}`}
                        value={description}
                        onChange={(e) => setDescription(e.target.value.substring(0, 300))}
                        style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                    />
                </Form.Group>

                <div className="d-flex gap-2 w-100 justify-content-center">
                    {/* <Button variant="success" type="button" className="w-100 mb-3" style={{ backgroundColor: "#f3f3f3", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }} onClick={() => next()}>
                        {t("CompleteProfile:step2:skip")}
                    </Button> */}
                    <Button variant="success" type="submit" className="w-50 mb-3" style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }}>
                        {loading ? t("CompleteProfile:step2:nextProcess") : t("CompleteProfile:step2:next")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}
