import React from "react";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

import AppQrCode from "../Assets/qrcode/AppQrCode.png";


import { IoClose } from "react-icons/io5";

export const FuturPopin = (props) => {
    const [showModal, setShowModal] = useState(false);

    const { i18n, t } = useTranslation();
    const handleOpenAppClick = async (url) => {
        if (url && typeof url === "string") window.location.href = url;

        setTimeout(function () {
            if (isAndroid) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.vocast";
            } else if (isIOS) {
                window.location.href = "https://apps.apple.com/fr/app/vocast-capsules-vocales/id6450795563";
            } else {
                setShowModal(true);
            }
        }, 100);
    };
    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
            <Modal.Body>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: 20 }}>
                    <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40, position: "absolute", right: 10, top: 10 }} onClick={props.onHide}>
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                    <div style={{ fontSize: 24, fontWeight: 600 }}>{t(`BO:${props.show}_popup_title`)}</div>
                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                        <div dangerouslySetInnerHTML={{ __html: t(`BO:${props.show}_popup_desc`) }} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row", gap: 10 }}>
                        {isMobile && isAndroid && (
                            <Button className="btn btn-link" onClick={handleOpenAppClick} style={{ height: 50, width: "auto", padding: 0 }}>
                                <img src={`https://storage.googleapis.com/vocast-public/assets/PlayStore/${i18n.language?.split("-").at(0)}.svg`} style={{ height: "100%", width: "auto" }} alt="Google Play" />
                            </Button>
                        )}
                        {/*{isMobile && }*/}
                        {isMobile && isIOS && (
                            <Button className="btn btn-link" onClick={handleOpenAppClick} style={{ height: 50, width: "auto", padding: 0 }}>
                                <img src={`https://storage.googleapis.com/vocast-public/assets/AppStore/${i18n.language?.split("-").at(0)}.svg`} style={{ height: "100%", width: "auto" }} alt="App Store" />
                            </Button>
                        )}
                        {!isMobile && (
                        <div style={{ display:"flex",alignItems:"center", fontSize: 16, fontWeight: 700, textAlign: "center" ,gap: 10 }}>
                            <img src={AppQrCode} alt="App QR Code" style={{ width: 150 }} />
                            {t(`BO:popup_qr_desc`)}
                        </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
