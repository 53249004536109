import React, { useState, useEffect } from "react";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { searchAlgolia } from "../../inc/algolia";
import { getAuth } from "firebase/auth";
import AudioCard from "../../components/AudioCard";
import { useGlobalContext } from "../../context/GlobalContext";
import { Collections } from "./parts/Collections";
import { useTranslation } from "react-i18next";

import { PiCactus } from "react-icons/pi";

export default function Inbox({search}) {
    const { t } = useTranslation();
    const user = getAuth().currentUser;
    const [vocasts, setVocasts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const { language, setLanguage, languages, handlePlay } = useGlobalContext();
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [nbVocasts, setNbVocasts] = useState(0);

    const fetchVocasts = async (reset) => {
        try {
            console.log('get vocasts from', user.uid);
            let params = { 
                page,
                owners: [user.uid],
                // status: ["draft"],
                status: ["draft", "pending", "published"],
                contributors: true,
            }
            if(search && search.length >= 2) params.search = search;
            if(selectedCollection) params.collections = [selectedCollection];
            const data = await searchAlgolia(params);
            if(!reset && page > 0) {
                setVocasts((prev) => [...prev, ...data.hits]);
                setNbVocasts(data.nbHits);
            } else {
                setPage(0);
                setVocasts(data.hits);
                setNbVocasts(data.nbHits);
            }
        } catch (err) {
            setError(err);
            setNbVocasts(0);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user) return;
        setLoading(true);
        fetchVocasts();
        // eslint-disable-next-line
    }, [search, user, page, selectedCollection]);

    if (!user) return;

    return (
        <Row>
            <Col xs={12} className="mt-1">
                <Collections selectedCollection={selectedCollection} setSelectedCollection={setSelectedCollection} />
            </Col>
            <Col xs={12} className="mt-1">
                {error && <div>Error: {error.message}</div>}
                {!error && (
                    <Row className="mb-3 g-1">
                        {vocasts?.map((vocast, index) => (
                            <Col xs={12} key={vocast.objectID} className="mb-3">
                                <AudioCard place={[0,0]} hit={vocast} user={user} handlePlay={handlePlay} setLanguage={setLanguage} language={language} languages={languages} />
                            </Col>
                        ))}
                    </Row>
                )}
                {loading && <Spinner animation="border" />}
                {!loading && !error && vocasts.length === 0 && <div className="text-center mt-4 mb-3 text-white">
                    <PiCactus size={100} className="mb-3" />
                    <h5 style={{
                        fontSize: "1rem",
                        fontWeight: 300,
                    }}>{t('home:empty_vocasts')}</h5>
                </div>}
                {!error && nbVocasts > vocasts.length && (
                    <Button
                        className="w-100"
                        onClick={(event) => {
                            event.preventDefault();
                            setPage((prev) => prev + 1);
                        }}
                        variant="dark"
                    >
                        {t('home:load_more')}
                    </Button>
                )}
            </Col>
        </Row>
    );
}