import { createContext, useRef, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

export const StatsData = {};
export const GlobalContext = createContext();

export const useGlobalContext = () => {
    const context = useContext(GlobalContext);
    if (!context) {
        throw new Error("useGlobalContext must be used within a GlobalProvider");
    }
    return context;
}

export const GlobalProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const audioPlayer = useRef(new Audio());
    // audioPlayer.current.crossOrigin = "anonymous";
    const [audioSource, setAudioSource] = useState("");
    const [audioStatus, setAudioStatus] = useState("stop");
    const [languages] = useState(["de", "en", "es", "fr", "it"]);
    const [language, setLanguage] = useState(audioSource?.audio?.locale || languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");
    const defaultUrlBg = "https://storage.googleapis.com/vocast-public/assets/background/default.jpg";

    const handlePlay = async (audio, { percent = 0, forceLocale = false } = {}) => {
        const translatedAudioUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${audio.id}/${language}`;

        if (audioStatus === "stop") {
            setAudioSource({ url: language === audio.locale || forceLocale ? audio.url : translatedAudioUrl, audio, percent });
        } else if (audioStatus === "play" && audioSource.audio.id && audioSource.audio.id === audio.id) {
            audioPlayer.current.pause();
            setAudioStatus("pause");
        } else if (audioStatus === "pause" && audioSource.audio.id && audioSource.audio.id === audio.id) {
            audioPlayer.current.play();
            setAudioStatus("play");
        } else if (audioSource.audio.id && audioSource.audio.id !== audio.id) {
            setAudioSource({ url: language === audio.locale || forceLocale ? audio.url : translatedAudioUrl, audio, percent });
        }
    };

    return <GlobalContext.Provider value={{ audioPlayer, audioSource, setAudioSource, audioStatus, setAudioStatus, language, setLanguage, languages, handlePlay, defaultUrlBg }}>{children}</GlobalContext.Provider>;
};
