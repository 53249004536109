import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { getAuth } from "firebase/auth";

import { useUserContext } from "../context/UserContext";

import RecordEmbed from "./Integration/RecordEmbed";

import { FaRotateLeft } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

const CreateRecorder = ({ ...props }) => {
    const { t } = useTranslation();
    const { user, userData } = useUserContext();
    const [modeTitle, setModeTitle] = useState("disabled");
    const [modeDesc, setModeDesc] = useState("disabled");

    const [oldCode, setOldCode] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isValidConfig, setIsValidConfig] = useState(false);

    const [recordOption, setRecordOption] = useState({
        collections: undefined,
        externalId: undefined,
        visibility: "public", // public/private
        status: "draft", // draft/published/archived/deleted
        showProfile: true,
        background: "vocast", // vocast/no/yes
        showBorder: true,
        inputContributorPicture: true,
        inputAudioFromFile: false,
        allowRecordingPause: false,
    });

    const colorsDefault = {
        bgColor: "#B2B2B2",
        textColor: "#FFFFFF",
        cardColor: "#4D4D4D",
        cardContentColor: "#FFFFFF",
        // waveformColor: "#80ffd7",
    };
    const [colors, setColors] = useState({ ...colorsDefault });

    const [embedHeight, setEmbedHeight] = useState(64);
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        const currentUser = auth.currentUser;
        if (!currentUser) return (window.location.href = "/login");

        const resizeObserver = new ResizeObserver((entries) => {
            setEmbedHeight(entries[0].contentRect.height);
        });
        resizeObserver.observe(document.querySelector("#embedIframe > div"));
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        setRecordOption({ ...recordOption, userId: user?.uid });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    const collectionsOptions = Object.entries(userData?.collections || {}).map(([key, value]) => ({
        value: key,
        label: value.name || key,
    }));

    const statusOptions = ["draft", "published", "archived", "deleted"].map((status) => ({
        value: status,
        label: status,
    }));

    const visibilityOptions = ["public", "private"].map((desc) => ({
        value: desc,
        label: desc,
    }));

    const backgroundOptions = ["vocast", "no", "yes"].map((background) => ({
        value: background,
        label: background,
    }));

    const titleOptions = ["disabled", "default", "input", "hidden"].map((title) => ({
        value: title,
        label: title,
    }));

    const descOptions = ["disabled", "default", "input", "hidden"].map((desc) => ({
        value: desc,
        label: desc,
    }));

    const handleLoadCode = (code, load = false) => {
        try {
            if (!code.trim()) {
                setError("");
                setSuccessMessage("");
                setIsValidConfig(false);
                return;
            }

            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = code.trim();

            const scriptTag = tempDiv.querySelector("script");
            if (!scriptTag) {
                setError("Invalid HTML: No script tag found");
                setSuccessMessage("");
                setIsValidConfig(false);
                return;
            }

            const src = scriptTag.getAttribute("src");
            if (!src) {
                setError("Invalid HTML: No src attribute found");
                setSuccessMessage("");
                setIsValidConfig(false);
                return;
            }

            // Create URL object to parse the query parameters
            const url = new URL(src, window.location.origin);
            const p = url.searchParams.get("p");

            if (!p) {
                setError("Invalid URL: No p parameter found");
                setSuccessMessage("");
                setIsValidConfig(false);
                return;
            }

            // eslint-disable-next-line no-unused-vars
            const decodedParams = JSON.parse(window.atob(decodeURIComponent(p)));
            setError("");
            setIsValidConfig(true);
            if (load) {
                decodedParams.userId = user?.uid;
                setRecordOption(decodedParams);
                setColors(decodedParams.colors);
                setModeTitle(decodedParams.titleMode || "disabled");
                setModeDesc(decodedParams.descMode || "disabled");
                setSuccessMessage("Configuration loaded successfully!");
                setTimeout(() => setSuccessMessage(""), 2000);
            } else {
                setSuccessMessage("");
            }
        } catch (error) {
            console.error("Error parsing code:", error);
            setError("Invalid code format");
            setSuccessMessage("");
            setIsValidConfig(false);
        }
    };

    useEffect(() => {
        handleLoadCode(oldCode, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [oldCode]);

    return (
        <Modal show={true} onHide={null} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
            <Modal.Header>
                <h5 className="modal-title">{t("RecordPopin:embedRecord")}</h5>
                <button type="button" className="btn-close" style={{ padding: 0, width: 40, height: 40 }} onClick={null}>
                    <IoClose size={40} />
                </button>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <h5>{t("RecordPopin:old")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div style={{ position: "relative" }}>
                        <textarea
                            className="form-control code"
                            placeholder={`<script async src="${window.location.origin}/embed/record.js?p=..."></script>`}
                            style={{ height: 200, fontSize: 14 }}
                            value={oldCode}
                            onChange={(e) => setOldCode(e.target.value)}
                        />
                        {error && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    backgroundColor: "#ff4444",
                                    color: "white",
                                    padding: "3px 8px",
                                    borderRadius: "3px",
                                    fontSize: "12px",
                                    zIndex: 9999,
                                    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                                    maxWidth: "200px",
                                    wordBreak: "break-word",
                                }}
                            >
                                {error}
                            </div>
                        )}
                        {successMessage && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: "5px",
                                    right: "5px",
                                    backgroundColor: "#4CAF50",
                                    color: "white",
                                    padding: "3px 8px",
                                    borderRadius: "3px",
                                    fontSize: "12px",
                                    zIndex: 9999,
                                    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
                                    maxWidth: "200px",
                                    wordBreak: "break-word",
                                }}
                            >
                                {successMessage}
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: 8, marginTop: 8, justifyContent: "flex-end" }}>
                        <button className="btn btn-primary" onClick={() => handleLoadCode(oldCode, true)} disabled={!isValidConfig} style={{ opacity: isValidConfig ? 1 : 0.5 }}>
                            {t("RecordPopin:load")}
                        </button>
                    </div>
                </section>

                <h5 style={{ marginTop: 16 }}>{t("RecordPopin:audioInformation")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="collections">
                            {t("RecordPopin:collections")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                placeholder="Select collections"
                                classNamePrefix="react-select"
                                isMulti
                                options={collectionsOptions}
                                isSearchable={true}
                                name="collections"
                                isClearable={true}
                                value={collectionsOptions.filter((item) => recordOption.collections?.includes(item.value))}
                                onChange={(e) => setRecordOption({ ...recordOption, collections: e.map((item) => item.value) })}
                            />
                        </div>
                    </div>

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="status">
                            {t("RecordPopin:status")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                placeholder="Select status"
                                classNamePrefix="react-select"
                                options={statusOptions}
                                defaultValue={statusOptions[0]}
                                isSearchable={true}
                                name="status"
                                value={statusOptions.find((item) => item.value === recordOption.status)}
                                onChange={(e) => setRecordOption({ ...recordOption, status: e.value })}
                            />
                        </div>
                    </div>

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="visibility">
                            {t("RecordPopin:visibility")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                placeholder="Select visibility"
                                classNamePrefix="react-select"
                                value={visibilityOptions.find((item) => item.value === recordOption.visibility)}
                                options={visibilityOptions}
                                defaultValue={visibilityOptions[0]}
                                isSearchable={true}
                                name="visibility"
                                onChange={(e) => setRecordOption({ ...recordOption, visibility: e.value })}
                            />
                        </div>
                    </div>
                </section>

                <h5 style={{ marginTop: 16 }}>{t("RecordPopin:audioDetailsCustomization")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="noTraduction">
                            {t("RecordPopin:noTraduction")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="noTraduction"
                            checked={recordOption.details?.noTraduction}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, noTraduction: !recordOption.details?.noTraduction } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="noDetailProfile">
                            {t("RecordPopin:noDetailProfile")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="noDetailProfile"
                            checked={recordOption.details?.noDetailProfile}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, noDetailProfile: !recordOption.details?.noDetailProfile } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="noStats">
                            {t("RecordPopin:noStats")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="noStats"
                            checked={recordOption.details?.noStats}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, noStats: !recordOption.details?.noStats } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="noAction">
                            {t("RecordPopin:noAction")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="noAction"
                            checked={recordOption.details?.noAction}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, noAction: !recordOption.details?.noAction } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="noCollection">
                            {t("RecordPopin:noCollection")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="noCollection"
                            checked={recordOption.details?.noCollection}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, noCollection: !recordOption.details?.noCollection } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="downloadable">
                            {t("RecordPopin:downloadable")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="downloadable"
                            checked={recordOption.details?.downloadable}
                            onChange={() => setRecordOption({ ...recordOption, details: { ...recordOption.details, downloadable: !recordOption.details?.downloadable } })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="autoArchive">
                            {t("RecordPopin:autoArchive")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="autoArchive"
                            checked={!!recordOption.details?.autoArchiveAfter}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setRecordOption({ ...recordOption, details: { ...recordOption.details, autoArchiveAfter: 1 } });
                                } else {
                                    const { autoArchiveAfter, ...rest } = recordOption?.details || {};
                                    setRecordOption({ ...recordOption, details: rest });
                                }
                            }}
                        />
                    </div>
                    {recordOption.details?.autoArchiveAfter && (
                        <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="autoArchiveAfter">
                                    {t("RecordPopin:autoArchive_time")}
                                </label>
                                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                                    <input
                                        type="number"
                                        id="autoArchiveAfter"
                                        className="form-control"
                                        min={1}
                                        style={{ maxWidth: 100 }}
                                        value={recordOption.details?.autoArchiveAfter}
                                        onChange={(e) => {
                                            setRecordOption({
                                                ...recordOption,
                                                details: {
                                                    ...recordOption.details,
                                                    autoArchiveAfter: Math.max(1, parseInt(e.target.value)),
                                                },
                                            });
                                        }}
                                    />
                                    <span>{t("RecordPopin:autoArchive_time_unit")}</span>
                                </div>
                            </li>
                        </ul>
                    )}
                </section>

                <h5 style={{ marginTop: 16 }}>{t("RecordPopin:display")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="showProfile">
                            {t("RecordPopin:showProfile")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="showProfile"
                            checked={recordOption.showProfile}
                            onChange={() => setRecordOption({ ...recordOption, showProfile: !recordOption.showProfile })}
                        />
                    </div>

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="background">
                            {t("RecordPopin:background")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={backgroundOptions}
                                defaultValue={backgroundOptions[0]}
                                value={backgroundOptions.find((item) => item.value === recordOption.background)}
                                name="background"
                                onChange={(e) => {
                                    if (e.value === recordOption.background) return;
                                    let { bgColor, textColor, ...rest } = recordOption;
                                    setRecordOption({ ...rest, background: e.value });
                                }}
                            />
                        </div>
                    </div>

                    {recordOption.background === "no" && (
                        <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="bgColor">
                                    {t("RecordPopin:bgColor")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                    <div
                                        style={{
                                            padding: 4,
                                            gap: 4,
                                            display: "flex",
                                            flexDirection: "row",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                            alignItems: "center",
                                            borderRadius: 5,
                                            width: 86,
                                            position: "relative",
                                            backgroundColor: "gray",
                                        }}
                                    >
                                        <input
                                            type="color"
                                            id="bgColor"
                                            onChange={(e) => setColors({ ...colors, bgColor: e.target.value })}
                                            value={colors.bgColor}
                                            style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                        />
                                        <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.bgColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                        <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.bgColor}</div>
                                    </div>
                                    {colors.bgColor !== colorsDefault.bgColor && (
                                        <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, bgColor: colorsDefault.bgColor })}>
                                            <FaRotateLeft />
                                        </span>
                                    )}
                                </div>
                            </li>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="textColor">
                                    {t("RecordPopin:textColor")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                    <div
                                        style={{
                                            padding: 4,
                                            gap: 4,
                                            display: "flex",
                                            flexDirection: "row",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                            alignItems: "center",
                                            borderRadius: 5,
                                            width: 86,
                                            position: "relative",
                                            backgroundColor: "gray",
                                        }}
                                    >
                                        <input
                                            type="color"
                                            id="textColor"
                                            onChange={(e) => setColors({ ...colors, textColor: e.target.value })}
                                            value={colors.textColor}
                                            style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                        />
                                        <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.textColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                        <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.textColor}</div>
                                    </div>
                                    {colors.textColor !== colorsDefault.textColor && (
                                        <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, textColor: colorsDefault.textColor })}>
                                            <FaRotateLeft />
                                        </span>
                                    )}
                                </div>
                            </li>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="cardColor">
                                    {t("RecordPopin:cardColor")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                    <div
                                        style={{
                                            padding: 4,
                                            gap: 4,
                                            display: "flex",
                                            flexDirection: "row",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                            alignItems: "center",
                                            borderRadius: 5,
                                            width: 86,
                                            position: "relative",
                                            backgroundColor: "gray",
                                        }}
                                    >
                                        <input
                                            type="color"
                                            id="cardColor"
                                            onChange={(e) => setColors({ ...colors, cardColor: e.target.value })}
                                            value={colors.cardColor}
                                            style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                        />
                                        <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.cardColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                        <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.cardColor}</div>
                                    </div>
                                    {colors.cardColor !== colorsDefault.cardColor && (
                                        <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, cardColor: colorsDefault.cardColor })}>
                                            <FaRotateLeft />
                                        </span>
                                    )}
                                </div>
                            </li>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="cardContentColor">
                                    {t("RecordPopin:cardContentColor")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                    <div
                                        style={{
                                            padding: 4,
                                            gap: 4,
                                            display: "flex",
                                            flexDirection: "row",
                                            boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                            alignItems: "center",
                                            borderRadius: 5,
                                            width: 86,
                                            position: "relative",
                                            backgroundColor: "gray",
                                        }}
                                    >
                                        <input
                                            type="color"
                                            id="cardContentColor"
                                            onChange={(e) => setColors({ ...colors, cardContentColor: e.target.value })}
                                            value={colors.cardContentColor}
                                            style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                        />
                                        <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.cardContentColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                        <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.cardContentColor}</div>
                                    </div>
                                    {colors.cardContentColor !== colorsDefault.cardContentColor && (
                                        <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, cardContentColor: colorsDefault.cardContentColor })}>
                                            <FaRotateLeft />
                                        </span>
                                    )}
                                </div>
                            </li>
                            {/* <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="waveformColor">
                                    {t("RecordPopin:waveformColor")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                    <div style={{ padding: 4, gap: 4, display: "flex", flexDirection: "row", boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)", alignItems: "center", borderRadius: 5, width: 86, position: "relative", backgroundColor: "gray" }}>
                                        <input type="color" id="waveformColor" onChange={(e) => setColors({ ...colors, waveformColor: e.target.value })} value={colors.waveformColor} style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }} />
                                        <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.waveformColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                        <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.waveformColor}</div>
                                    </div>
                                    {colors.waveformColor !== colorsDefault.waveformColor && (
                                        <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, waveformColor: colorsDefault.waveformColor })}>
                                            <FaRotateLeft />
                                        </span>
                                    )}
                                </div>
                            </li> */}
                        </ul>
                    )}

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="showBorder">
                            {t("RecordPopin:showBorder")}
                        </label>
                        <input className="form-check-input" type="checkbox" id="showBorder" checked={recordOption.showBorder} onChange={() => setRecordOption({ ...recordOption, showBorder: !recordOption.showBorder })} />
                    </div>

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="clickToRecord">
                            {t("RecordPopin:clickToRecord")}
                        </label>
                        <input
                            type="text"
                            id="clickToRecord"
                            placeholder={t("Record:clickToRecord")}
                            onChange={(e) => setRecordOption({ ...recordOption, clickToRecord: e.target.value })}
                            value={recordOption.clickToRecord}
                            style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="afterUpload">
                            {t("RecordPopin:afterUpload")}
                        </label>
                        <input
                            type="text"
                            id="afterUpload"
                            placeholder={t("Record:uploadFinished")}
                            onChange={(e) => setRecordOption({ ...recordOption, afterUpload: e.target.value })}
                            value={recordOption.afterUpload}
                            style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                        />
                    </div>
                </section>

                <h5 style={{ marginTop: 16 }}>{t("RecordPopin:userInput")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="titleMode">
                            {t("RecordPopin:title")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={titleOptions}
                                defaultValue={titleOptions[0]}
                                value={titleOptions.find((item) => item.value === recordOption.titleMode)}
                                name="titleMode"
                                onChange={(e) => {
                                    if (e.value === modeTitle) return;
                                    setModeTitle(e.value);

                                    const { titleMode, title, ...rest } = recordOption;
                                    if (e.value !== "disabled") {
                                        rest.title = title || "";
                                        rest.titleMode = e.value;
                                    }

                                    setRecordOption({ ...rest });
                                }}
                            />
                        </div>
                    </div>
                    {modeTitle !== "disabled" && (
                        <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="title">
                                    {t(`RecordPopin:title_${modeTitle}`)}
                                </label>
                                <input
                                    type="text"
                                    id="title"
                                    placeholder="Title"
                                    onChange={(e) => setRecordOption({ ...recordOption, title: e.target.value })}
                                    value={recordOption.title}
                                    style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                                />
                            </li>
                        </ul>
                    )}

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="descMode">
                            {t("RecordPopin:desc")}
                        </label>
                        <div style={{ maxWidth: 250, width: "100%" }}>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={descOptions}
                                defaultValue={descOptions[0]}
                                value={descOptions.find((item) => item.value === recordOption.descMode)}
                                name="descMode"
                                onChange={(e) => {
                                    if (e.value === modeDesc) return;
                                    setModeDesc(e.value);

                                    const { descMode, desc, ...rest } = recordOption;
                                    if (e.value !== "disabled") {
                                        rest.desc = desc || "";
                                        rest.descMode = e.value;
                                    }

                                    setRecordOption({ ...rest });
                                }}
                            />
                        </div>
                    </div>

                    {modeDesc !== "disabled" && (
                        <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                            <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                <label className="form-check-label" htmlFor="desc">
                                    {t(`RecordPopin:desc_${modeDesc}`)}
                                </label>
                                <input
                                    type="text"
                                    id="desc"
                                    placeholder="Description"
                                    onChange={(e) => setRecordOption({ ...recordOption, desc: e.target.value })}
                                    value={recordOption.desc}
                                    style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                                />
                            </li>
                        </ul>
                    )}

                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="inputContributorPicture">
                            {t("RecordPopin:inputContributorPicture")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="inputContributorPicture"
                            checked={recordOption.inputContributorPicture}
                            onChange={() => setRecordOption({ ...recordOption, inputContributorPicture: !recordOption.inputContributorPicture })}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="inputAudioFromFile">
                            {t("RecordPopin:inputAudioFromFile")}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="inputAudioFromFile"
                            checked={recordOption.inputAudioFromFile}
                            onChange={() => setRecordOption({ ...recordOption, inputAudioFromFile: !recordOption.inputAudioFromFile })}
                        />
                    </div>
                </section>

                <h5 style={{ marginTop: 16 }}>{t("RecordPopin:extra")}</h5>
                <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="allowRecordingPause">
                            {t("RecordPopin:allowRecordingPause") || "Autoriser la pause pendant l'enregistrement"}
                        </label>
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="allowRecordingPause"
                            checked={recordOption.allowRecordingPause}
                            onChange={() => {
                                setRecordOption({ ...recordOption, allowRecordingPause: !recordOption.allowRecordingPause });
                            }}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="externalId">
                            {t("RecordPopin:externalId")}
                        </label>
                        <input
                            type="text"
                            id="externalId"
                            placeholder="External ID"
                            onChange={(e) => setRecordOption({ ...recordOption, externalId: e.target.value })}
                            value={recordOption.externalId}
                            style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                        />
                    </div>
                    <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                        <label className="form-check-label" htmlFor="callbackUrl">
                            {t("RecordPopin:callback")}
                        </label>
                        <input
                            type="text"
                            id="callbackUrl"
                            placeholder="Callback URL"
                            onChange={(e) => setRecordOption({ ...recordOption, callback: e.target.value })}
                            value={recordOption.callback}
                            style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }}
                        />
                    </div>
                </section>

                {/* <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="maxPicture">
                        {t("RecordPopin:maxPicture")}
                    </label>
                    <input type="number" id="maxPicture" placeholder="Max picture" onChange={(e) => setRecordOption({ ...recordOption, maxPicture: e.target.value })} value={recordOption.maxPicture} style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5 }} />
                </div> */}

                <div style={{ marginTop: 20 }} id="embedIframe">
                    <RecordEmbed params={window.btoa(JSON.stringify({ id: 10, ...recordOption, colors: recordOption.background === "no" ? colors : undefined, height: embedHeight }))} demo />
                </div>
                <div style={{ border: "1px solid #ccc", padding: 10, borderRadius: 10, backgroundColor: "#f8f9fa", overflow: "auto", marginTop: 20 }}>
                    <pre style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere", marginBottom: 0 }} id="codeEmbed">
                        {`<script async src="${window.location.origin}/recorder.js?p=${encodeURIComponent(window.btoa(JSON.stringify({ id: 10, ...recordOption, colors: recordOption.background === "no" ? colors : undefined, height: embedHeight + "px" })))}"></script>`}
                    </pre>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-primary"
                    onClick={() => {
                        navigator.clipboard.writeText(document.getElementById("codeEmbed").innerText);
                        setCopied(true);
                        setTimeout(() => {
                            setCopied(false);
                        }, 2000);
                    }}
                >
                    {copied ? t("RecordPopin:copied") : t("RecordPopin:copyCode")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateRecorder;
