import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

let firebaseConfig = {};

if (window.location.host.includes("localhost") && localStorage.getItem("developer") !== 'thierry') {
    firebaseConfig = {
        apiKey: "AIzaSyAJd4Puq3yKP0byTFg841fnrVi4hn4qCtI",
        authDomain: "vocast-developpement.firebaseapp.com",
        projectId: "vocast-developpement",
        storageBucket: "vocast-developpement.firebasestorage.app",
        messagingSenderId: "958577431394",
        appId: "1:958577431394:web:77f52ca66274164244923b",
        measurementId: "G-6DNW1DGPFX",
    };
} else {
    firebaseConfig = {
        apiKey: "AIzaSyBQ3od4wlgHbhkT8sqlobJk04o5RZWUHfw",
        authDomain: "vocast-app.firebaseapp.com",
        projectId: "vocast-app",
        storageBucket: "vocast-app.appspot.com",
        messagingSenderId: "189995229501",
        appId: "1:189995229501:web:02da90894b06fb2be4a554",
        measurementId: "G-XM6EED0KRH",
    };
}

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
export default app;
