import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const Desc = ({ desc }) => {
    const { t } = useTranslation();
    const [slicedDesc, setSlicedDesc] = useState(desc);
    const descRef = useRef(null);

    const calculateZone = (text) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        context.font = `16px poppins`;

        const words = text.split(" ");
        let line = "";
        const lines = [];

        words.forEach((word) => {
            const testLine = line + word + " ";
            const testWidth = context.measureText(testLine).width;

            if (testWidth > descRef.current.clientWidth) {
                lines.push(line.trim());
                line = word + " ";
            } else {
                line = testLine;
            }
        });

        if (line) lines.push(line.trim());
        return lines.length;
    };

    useEffect(() => {
        if (!desc || !descRef.current) return;
        if (!slicedDesc && desc) return setSlicedDesc(desc);
        if (descRef.current.clientHeight <= 48) return;
        let tmpDesc = desc;
        do {
            tmpDesc = tmpDesc.slice(0, tmpDesc.lastIndexOf(" ")).trim();
        } while (calculateZone(tmpDesc + "... more ") > 2);
        setSlicedDesc(tmpDesc);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [desc, descRef.current]);

    if (!desc || desc.length === 0) {
        return null;
    }

    return (
        <div>
            <p ref={descRef} style={{ marginBottom: ".5rem" }}>
                {slicedDesc} {slicedDesc.length < desc.length && "..."}
                {slicedDesc.length < desc.length && (
                    <span
                        style={{ cursor: "pointer", fontWeight: "bold", color: "#ccc", fontSize: 14 }}
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setSlicedDesc(desc);
                        }}
                    >
                        &nbsp;{t("common:actions.readMore")}
                    </span>
                )}
            </p>
        </div>
    );
};

export default Desc;
