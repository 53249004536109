import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaSearch } from 'react-icons/fa';

export const SearchField = ({ search, setSearch }) => {
    const { t } = useTranslation();
    const handleSearch = (e) => {
        setSearch(e.target.value);
    };

    return (
        <div className="mb-3" style={{ position: "relative" }}>
            <span
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "15px",
                    transform: "translateY(-50%)",
                    color: "#adb5bd", // Light gray color for the icon
                    pointerEvents: "none",
                }}
            >
                <FaSearch /> {/* React Icons search icon */}
            </span>
            <Form.Control
                type="text"
                placeholder={t("home:search")}
                value={search}
                onChange={handleSearch}
                className="form-control"
                style={{
                    borderRadius: "20px",
                    padding: "10px 20px 10px 40px", // Add padding to the left for the icon
                    fontSize: "14px",
                    backgroundColor: "#343a40", // Dark background
                    color: "#f8f9fa", // Light text
                    border: "1px solid #495057", // Darker border
                    boxShadow: "none",
                }}
            />
        </div>
    );
}