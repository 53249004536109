import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { useUserContext } from "../../context/UserContext";

export const VocastTab = ({ options = {}, onOptionsChange }) => {
    const { t } = useTranslation();
    const { userData } = useUserContext();

    const collectionsOptions = Object.entries(userData?.collections || {}).map(([key, value]) => ({
        value: key,
        label: value.name || key,
    }));

    const statusOptions = ["draft", "published", "archived", "deleted"].map((status) => ({
        value: status,
        label: t(`Recorder:vocastConfiguration.statusOptions.${status}`),
    }));

    const visibilityOptions = ["public", "private"].map((desc) => ({
        value: desc,
        label: t(`Recorder:vocastConfiguration.visibilityOptions.${desc}`),
    }));

    const handleOptionChange = (setting, value) => {
        onOptionsChange({
            ...options,
            details: {
                ...options.details,
                [setting]: value,
            },
        });
    };

    const selectStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            "&:hover": {
                borderColor: "#fff",
            },
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: "#2c2c2c",
            borderColor: "#ccc",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#3c3c3c" : "transparent",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#3c3c3c",
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: "#fff",
        }),
    };

    return (
        <div>
            <h5 style={{ color: "#fff" }}>{t("Recorder:vocastConfiguration.audioInformation")}</h5>
            <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="collections" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.collections")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            placeholder={t("Recorder:vocastConfiguration.selectCollections")}
                            classNamePrefix="react-select"
                            isMulti
                            options={collectionsOptions}
                            isSearchable={true}
                            name="collections"
                            isClearable={true}
                            styles={selectStyles}
                            value={collectionsOptions.filter((item) => (options?.collections || []).includes(item.value))}
                            onChange={(e) => onOptionsChange({ ...options, collections: e.map((item) => item.value) })}
                        />
                    </div>
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="status" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.status")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            placeholder={t("Recorder:vocastConfiguration.selectStatus")}
                            classNamePrefix="react-select"
                            options={statusOptions}
                            defaultValue={statusOptions[0]}
                            isSearchable={true}
                            name="status"
                            styles={selectStyles}
                            value={statusOptions.find((item) => item.value === (options?.status || "draft"))}
                            onChange={(e) => onOptionsChange({ ...options, status: e.value })}
                        />
                    </div>
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="visibility" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.visibility")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            placeholder={t("Recorder:vocastConfiguration.selectVisibility")}
                            classNamePrefix="react-select"
                            value={visibilityOptions.find((item) => item.value === (options?.visibility || "public"))}
                            options={visibilityOptions}
                            defaultValue={visibilityOptions[0]}
                            isSearchable={true}
                            name="visibility"
                            styles={selectStyles}
                            onChange={(e) => onOptionsChange({ ...options, visibility: e.value })}
                        />
                    </div>
                </div>
            </section>

            <h5 style={{ marginTop: 16, color: "#fff" }}>{t("Recorder:vocastConfiguration.audioDetailsCustomization")}</h5>
            <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="noTraduction" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.noTranslation")}
                    </label>
                    <Form.Check type="switch" id="noTraduction" checked={options.details?.noTraduction || false} onChange={(e) => handleOptionChange("noTraduction", e.target.checked)} className="custom-switch" />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="noDetailProfile" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.noDetailProfile")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="noDetailProfile"
                        checked={options.details?.noDetailProfile || false}
                        onChange={(e) => handleOptionChange("noDetailProfile", e.target.checked)}
                        className="custom-switch"
                    />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="noStats" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.noStats")}
                    </label>
                    <Form.Check type="switch" id="noStats" checked={options.details?.noStats || false} onChange={(e) => handleOptionChange("noStats", e.target.checked)} className="custom-switch" />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="noAction" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.noAction")}
                    </label>
                    <Form.Check type="switch" id="noAction" checked={options.details?.noAction || false} onChange={(e) => handleOptionChange("noAction", e.target.checked)} className="custom-switch" />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="noCollection" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.noCollection")}
                    </label>
                    <Form.Check type="switch" id="noCollection" checked={options.details?.noCollection || false} onChange={(e) => handleOptionChange("noCollection", e.target.checked)} className="custom-switch" />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="downloadable" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.downloadable")}
                    </label>
                    <Form.Check type="switch" id="downloadable" checked={options.details?.downloadable || false} onChange={(e) => handleOptionChange("downloadable", e.target.checked)} className="custom-switch" />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="autoArchive" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.autoArchive")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="autoArchive"
                        checked={!!options.details?.autoArchiveAfter}
                        onChange={(e) => {
                            if (e.target.checked) {
                                handleOptionChange("autoArchiveAfter", 1);
                            } else {
                                const { autoArchiveAfter, ...rest } = options.details || {};
                                onOptionsChange({ ...options, details: rest });
                            }
                        }}
                        className="custom-switch"
                    />
                </div>

                {options.details?.autoArchiveAfter && (
                    <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                        <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                            <label className="form-check-label" htmlFor="autoArchiveAfter" style={{ color: "#fff" }}>
                                {t("Recorder:vocastConfiguration.autoArchiveTime")}
                            </label>
                            <input
                                type="number"
                                id="autoArchiveAfter"
                                className="form-control"
                                min={1}
                                style={{ maxWidth: 100, backgroundColor: "transparent", color: "#fff", border: "1px solid #ccc" }}
                                value={options.details?.autoArchiveAfter}
                                onChange={(e) => handleOptionChange("autoArchiveAfter", Math.max(1, parseInt(e.target.value)))}
                            />
                        </li>
                    </ul>
                )}
            </section>

            <h5 style={{ marginTop: 16, color: "#fff" }}>{t("Recorder:vocastConfiguration.extra")}</h5>
            <section style={{ display: "flex", flexDirection: "column", gap: 10, marginLeft: 16 }}>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="externalId" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.externalId")}
                    </label>
                    <input
                        type="text"
                        id="externalId"
                        placeholder={t("Recorder:vocastConfiguration.externalId")}
                        onChange={(e) => onOptionsChange({ ...options, externalId: e.target.value })}
                        value={options?.externalId || ""}
                        style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                    />
                </div>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="callbackUrl" style={{ color: "#fff" }}>
                        {t("Recorder:vocastConfiguration.callbackUrl")}
                    </label>
                    <input
                        type="text"
                        id="callbackUrl"
                        placeholder={t("Recorder:vocastConfiguration.callbackUrl")}
                        onChange={(e) => onOptionsChange({ ...options, callback: e.target.value })}
                        value={options?.callback || ""}
                        style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                    />
                </div>
            </section>

            <style>
                {`
                .custom-switch .form-check-input {
                    height: 1.5rem;
                    width: 2.5rem;
                    margin-top: 0.2rem;
                }
                .custom-switch .form-check-input:checked {
                    background-color: #80fed8;
                    border-color: #80fed8;
                }
                .custom-switch .form-check-input:checked:focus {
                    background-color: #80fed8;
                    border-color: #80fed8;
                }
                .custom-switch .form-check-input:focus {
                    border-color: rgba(0, 0, 0, 0.25);
                    box-shadow: none;
                }
                .form-switch .form-check-input:checked::after {
                    background-color: #000;
                    transform: translateX(1rem);
                }
                .form-switch .form-check-input::after {
                    background-color: #fff;
                }
                `}
            </style>
        </div>
    );
};
