import { Card } from "react-bootstrap";
import { useGlobalContext } from "../context/GlobalContext";

export default function CollectionCard({ collectionKey, collection, onClick, selectedCollection }) {
    const { defaultUrlBg } = useGlobalContext();
    console.log(collectionKey, collection);

    return (
        <Card
            className="d-flex justify-content-center align-items-center m-0 p-0"
            key={collection}
            style={{ 
                minWidth: "150px", 
                minHeight: "80px",
                cursor: "pointer",
                backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, ${selectedCollection === collectionKey ? 0.8 : 0.6}), rgba(0, 0, 0, ${selectedCollection === collectionKey ? 0.7 : 0.5})), url(${collection.picture || defaultUrlBg})`,
                backgroundSize: "cover",
                border: selectedCollection === collectionKey ? "1px solid #fff" : "none",
                borderRadius: "1.5rem",
            }}
            onClick={onClick}
        >
            <Card.Title className="d-flex justify-content-center align-items-center m-0 p-2" style={{ height: "100%" }}>
                <h4 className="text-white truncate-2-lines text-center m-0" style={{
                    fontSize: "0.8rem",
                    WebkitBoxOrient: "vertical",
                }}>
                    {collection.name}
                </h4>
            </Card.Title>
        </Card>
    );
}