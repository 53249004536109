import Layout from "."
import TopBar from "./TopBar";
import { Col, Row } from "react-bootstrap";

export const BaseLayout = ({ children }) => {
    return <Layout>
        <div style={{ paddingBottom: 50 }}>
            <div
                style={{
                    paddingBottom: 16,
                    paddingLeft: 0,
                    paddingRight: 0,
                    paddingTop: 0,
                    borderBottomLeftRadius: 44,
                    borderBottomRightRadius: 44,
                }}
                className="d-flex justify-content-center align-items-center flex-column position-relative"
            >
                <Row className="w-100 h-100" style={{ flex: 1 }}>
                    <TopBar />
                    <Col xs={12}>
                        {children}
                    </Col>
                </Row>
            </div>
        </div>
    </Layout>
}