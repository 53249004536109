import { useEffect } from "react";

function Redirect() {
    useEffect(() => {
        document.location.href = "https://www.vocast.co/";
    }, []);

    return <></>;
}

export default Redirect;
