import { useCallback, useEffect, useRef, useState } from "react";

import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

const useFFmpegConverter = () => {
    const [status, setStatus] = useState("");
    const [isReady, setIsReady] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isConverting, setIsConverting] = useState(false);
    const ffmpegRef = useRef(new FFmpeg());

    const onProgress = (callback) => {
        console.log("onProgress", callback);
    };

    // Initialisation de FFmpeg
    useEffect(() => {
        const load = async () => {
            console.log("Start loading FFmpeg");
            const baseURL = "https://unpkg.com/@ffmpeg/core@latest/dist/umd";
            const ffmpeg = ffmpegRef.current;
            ffmpeg.on("log", ({ message }) => console.log("log", message));
            await ffmpeg.load({
                coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
                wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
            });
            console.log("FFmpeg loaded");
            setIsReady(true);
        };

        load();
    }, []);

    // Fonction de conversion
    const convertToAAC = useCallback(
        async (file, options = {}) => {
            if (!isReady) {
                throw new Error("FFmpeg n'est pas encore chargé");
            }

            const { bitrate = "128k", outputFileName = "output.mp4", autoDownload = false } = options;
            try {
                setIsConverting(true);
                const inputFileName = "input.mp4";
                await ffmpegRef.current.writeFile(inputFileName, await fetchFile(file));
                await ffmpegRef.current.exec(["-i", inputFileName, "-c:a", "aac", "-profile:a", "aac_low", "-b:a", bitrate, "-movflags", "+faststart", outputFileName]);
                const data = await ffmpegRef.current.readFile(outputFileName);
                const blob = new Blob([data.buffer], { type: "audio/mp4" });
                if (autoDownload) {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = outputFileName;
                    a.click();
                    URL.revokeObjectURL(url);
                }
                await ffmpegRef.current.deleteFile(inputFileName);
                await ffmpegRef.current.deleteFile(outputFileName);

                setStatus("Conversion terminée !");
                return blob;
            } catch (error) {
                setStatus(`Erreur lors de la conversion: ${error.message}`);
                throw error;
            } finally {
                setIsConverting(false);
                setProgress(0);
            }
        },
        [isReady],
    );

    return {
        isReady,
        isConverting,
        status,
        progress,
        convertToAAC,
        onProgress,
    };
};

export default useFFmpegConverter;
