import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { collection, doc, getDoc } from "firebase/firestore";

import { auth, db } from "../../../services/firebase";

import iphone from "../../../Assets/img/iphone.jpg";
import iphoneMask from "../../../Assets/img/iphone_mask.svg";
import logo from "../../../Assets/img/logo-white.png";

export default function ShowProfile({ navigate }) {
    const { t } = useTranslation();
    const [userData, setUserData] = useState({});

    useEffect(() => {
        if (!auth.currentUser) return navigate("/login");
        getDoc(doc(collection(db, "users"), auth.currentUser.uid))
            .then((userDoc) => setUserData(userDoc.data()))
            .catch(() => navigate("/login"));

        // eslint-disable-next-line
    }, []);

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>

            <div className="text-center" style={{ padding: "24px 16px" }}>
                <h2 style={{ fontSize: 32, color: "#fff" }}>
                    <b>{t("CompleteProfile:step4:title")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3", marginBottom: 4 }}>{t("CompleteProfile:step4:subtitle")}</p>
                <p style={{ fontSize: 14, color: "#fff", fontWeight: 600 }}>vocast.co/{userData.nickname}</p>
            </div>
            <Button
                variant="success"
                type="submit"
                className="mb-3"
                style={{ backgroundColor: "#80ffd7", padding: "12px 24px", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }}
                onClick={() => navigate(`/${userData.nickname}`)}
            >
                {t("CompleteProfile:step4:goToProfile")}
            </Button>
            {/* <div
                style={{
                    position: "relative",
                    width: 368,
                    height: 639,
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: 368,
                        height: 639,
                        backgroundColor: "white",
                        backgroundImage: `url(${iphone})`,
                        backgroundSize: "368px 639px",
                        backgroundRepeat: "no-repeat",
                        WebkitMaskSize: "368px 639px",
                        WebkitMaskImage: `url(${iphoneMask})`,
                        WebkitMaskRepeat: "no-repeat",
                        zIndex: 2,
                        pointerEvents: "none",
                    }}
                />
                <iframe
                    title="Vocast Profile"
                    src={`https://www.vocast.co/${userData.nickname}`}
                    style={{
                        position: "absolute",
                        marginLeft: 19,
                        marginTop: 17,
                        height: 597,
                        width: 330,
                        border: "none",
                        zIndex: 1,
                        top: 0,
                        left: 0,
                    }}
                />
                </div> */}
            <iframe title="Vocast Profile" src={`${window.location.protocol}//${window.location.host}/${userData.nickname}`} className="phone-frame" />
        </Container>
    );
}
