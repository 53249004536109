import { useEffect, useState } from "react";
import { useUserContext } from "../../../context/UserContext";
import CollectionCard from "../../CollectionCard";
import { Row, Col } from "react-bootstrap";

export const Collections = ({ selectedCollection, setSelectedCollection }) => {
    const { userData } = useUserContext();
    const [ collections, setCollections ] = useState([]);
    
    const handleCollectionClick = (collection) => {
        if(selectedCollection === collection) {
            setSelectedCollection(null);
            return;
        }
        setSelectedCollection(collection);
    };

    useEffect(() => {
        if (!userData) return;
        setCollections(userData.collections || []);
    }, [userData]);

    return (
        <Row 
            className="overflow-auto gx-2 flex-nowrap mb-3" 
            style={{ 
                scrollbarWidth: "none",
                scrollbarColor: "rgba(0, 0, 0, 0.5) transparent"
            }}
        >
            {Object.keys(collections).map((collection) => (
                <Col>
                    <CollectionCard
                        key={collection}
                        collectionKey={collection}
                        collection={collections[collection]}
                        onClick={() => handleCollectionClick(collection)}
                        selectedCollection={selectedCollection}
                    />
                </Col>
            ))}
        </Row>
    );
}