import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDoc } from "firebase/firestore";

import { db } from "../services/firebase";

import { FuturPopin } from "../components/FuturPopin";
import { IsAuth, IsLarger, IsNotAuth, IsSmaller } from "../components/Responsive";

import VocastLogo from "../Assets/img/logo-white.png";
import { ReactComponent as BellIconRegular } from "../Assets/pictos/bell-regular.svg";

import MicroVocastLogo from "../Assets/pictos/micro-vocast.png";


import { FaCircleUser, FaRegCircleUser } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

import AdminMenus from "../routes/Admin/Utils";

const Layout = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [minimal, setMinimal] = useState(false);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (_user) => {
            if (_user) {
                setUser(_user);
                const userDoc = await getDoc(doc(collection(db, "users"), _user.uid));
                if (!userDoc.exists()) return;
                const userData = userDoc.data();
                setUserData(userData);
            } else {
                setUser(null);
                setUserData(null);
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const boRight = document.getElementById("bo-right");
        const boLeft = document.getElementById("bo-left");
        const boContent = document.getElementById("bo-content");
        if (!boRight || !boLeft || !boContent) return;

        const handleScroll = (e) => {
            e.preventDefault();
            e.stopPropagation();
            boContent.scrollTop += e.deltaY;
        };

        boRight.addEventListener("wheel", handleScroll);
        boLeft.addEventListener("wheel", handleScroll);

        return () => {
            boRight.removeEventListener("wheel", handleScroll);
            boLeft.removeEventListener("wheel", handleScroll);
        };
    }, [minimal]);
    

    const openModal = (e, modal) => {
        e.preventDefault();
        e.stopPropagation();
        setModal(modal);
    };

    const logout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        getAuth().signOut();
        localStorage.removeItem("user");
    };

    const maxWidth = window.location.pathname.includes("/admin") ? 1162 : 600;

    return (
        <>
            <FuturPopin show={modal} onHide={() => setModal(null)} />
            <IsSmaller width={650}>{children}</IsSmaller>
            <IsLarger width={650} onChange={(_state) => setMinimal(_state)}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ position: "sticky", top: 0, bottom: 0, height: "100vh", flex: 1, backgroundColor: "black", padding: 20, display: "flex", flexDirection: "column", justifyContent: "space-between", paddingTop: 18 }} id="bo-left">
                        <div>
                            <div>
                                <a href="https://www.vocast.co" target="_blank" rel="noreferrer">
                                    <IsLarger width={940}>
                                        <img src={VocastLogo} style={{ height: 41, width: "auto", margin: "4px 0 0 10px" }} alt="Vocast" />
                                    </IsLarger>
                                    <IsSmaller width={940}>
                                        <img src={MicroVocastLogo} style={{ height: 51, width: "auto" }} alt="Vocast" />
                                    </IsSmaller>
                                </a>
                            </div>
                            <IsAuth>
                                {/* <div style={{ marginTop: 40, display: "flex", flexDirection: "column", gap: 16, width: "min-content" }}>
                                    <NavLinkCustom to={`/feed`} title="home" icon="Home" onClick={(e) => openModal(e, "feed")} />
                                    <NavLinkCustom to={`/search`} title="search" icon="Search" onClick={(e) => openModal(e, "search")} />
                                    <NavLinkCustom to={`/messages`} title="messages" icon="Envelope" onClick={(e) => openModal(e, "messages")} />
                                    <hr style={{ margin: "10px 0", width: "100%", height: 3, backgroundColor: "#fff", borderRadius: 4, opacity: 0.6 }} />
                                    <NavLinkCustom to={`/${userData?.nickname}`} title="posts" icon="Microphone" />
                                    <NavLinkCustom to={`/collections`} title="collections" icon="Collection" onClick={(e) => openModal(e, "collections")} />
                                    <NavLinkCustom to={`/analytics`} title="analytics" icon="ChartArea" onClick={(e) => openModal(e, "analytics")} />
                                    <NavLink to={`/new`} style={{ padding: "10px 15px", textDecoration: "none", color: "black", fontWeight: 600, backgroundColor: "#80fed8", borderRadius: 15, display: "flex", alignItems: "center", justifyContent: "center", whiteSpace: "nowrap", gap: 8 }} onClick={(e) => openModal(e, "new")}>
                                        <PlusIconSolid height={20} />
                                        <IsLarger width={940}>{t("BO:new_title")}</IsLarger>
                                    </NavLink>
                                </div> */}
                            </IsAuth>
                        </div>
                        <div>
                            <AdminMenus />
                        </div>
                        <div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 16, width: "min-content" }}>
                                {/* <NavLinkCustom to={`/settings`} title="settings" icon="Settings" onClick={(e) => openModal(e, "settings")} /> */}
                                <Dropdown>
                                    <Dropdown.Toggle
                                        id="dropdown-basic"
                                        style={{
                                            background: "none",
                                            color: "#fff",
                                            fontSize: 14,
                                            borderRadius: 50,
                                            borderColor: "#fff",
                                        }}
                                    >
                                        <TbWorld size={20} style={{ marginRight: 8 }} />
                                        <IsLarger width={940}>{t(`common:lang.${i18n.language.split("-").at(0)}`)}</IsLarger>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ backgroundColor: "#000", border: "1px solid #fff" }}>
                                        {i18n.options.supportedLngs
                                            .filter((v) => v !== "cimode")
                                            .map((lang) => (
                                                <Dropdown.Item
                                                    key={lang}
                                                    style={{
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                    onClick={() => i18n.changeLanguage(lang)}
                                                >
                                                    {t(`common:lang.${lang.split("-").at(0)}`)}
                                                </Dropdown.Item>
                                            ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: maxWidth, width: "100%", overflow: "hidden" }}>{children}</div>
                    <div style={{ position: "sticky", top: 0, bottom: 0, height: "100vh", flex: 1, backgroundColor: "black", padding: 20, display: "flex", flexDirection: "column", justifyContent: "space-between" }} id="bo-right">
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: 4, flexWrap: "wrap-reverse" }}>
                            <IsNotAuth>
                                <IsLarger width={1153}>
                                    <Link to="/login" style={{ padding: "12px 20px 12px 0", color: "#80ffd7", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14, textDecoration: "none" }}>
                                        {t("Login:login")}
                                    </Link>
                                    <Link to="/signup" className="btn btn-primary" style={{ padding: "12px 20px", backgroundColor: "#80ffd7", color: "#000", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14 }}>
                                        {t("Login:register")}
                                    </Link>
                                </IsLarger>
                                <IsSmaller width={1153}>
                                    <Link to="/signup" style={{ padding: "8px", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14 }}>
                                        <FaRegCircleUser style={{ color: "#fff" }} size={36} />
                                    </Link>
                                </IsSmaller>
                            </IsNotAuth>
                            <IsAuth>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ marginRight: 20 }}>
                                        <BellIconRegular height={25} fill="#fff" />
                                    </div>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            id="dropdown-basic"
                                            className="dropdown-toggle-custom"
                                            style={{
                                                background: "none",
                                                border: "none",
                                                padding: 0,
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
                                                {userData?.profilePicture || user?.profilePicture ? (
                                                    <img
                                                        src={userData?.profilePicture || user?.profilePicture}
                                                        alt="Profile"
                                                        style={{
                                                            height: 36,
                                                            width: 36,
                                                            borderRadius: "50%",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                ) : (
                                                    <FaCircleUser style={{ color: "#fff" }} size={36} />
                                                )}
                                                <IsLarger width={940}>
                                                    <div style={{ marginLeft: 10, textAlign: "left" }}>
                                                        <p className="mb-0" style={{ color: "#fff", fontSize: 16 }}>
                                                            {userData?.name}
                                                        </p>
                                                        <p className="mb-0" style={{ color: "#aaa", fontSize: 14 }}>
                                                            @{userData?.nickname}
                                                        </p>
                                                    </div>
                                                </IsLarger>
                                            </div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{ backgroundColor: "#000", border: "1px solid #fff" }}>
                                            <Dropdown.Header>
                                                <div>
                                                    <p className="mb-0" style={{ color: "#fff" }}>
                                                        {userData?.name}
                                                    </p>
                                                    <p className="mb-0" style={{ color: "#fff", fontSize: 12 }}>
                                                        @{userData?.nickname}
                                                    </p>
                                                </div>
                                            </Dropdown.Header>
                                            <Dropdown.Item onClick={(e) => logout(e)} style={{ color: "#fff" }}>
                                                <MdLogout style={{ marginRight: 8 }} size={20} />
                                                {t("TopBar:logout")}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </IsAuth>
                        </div>
                    </div>
                </div>
            </IsLarger>
        </>
    );
};

export default Layout;
