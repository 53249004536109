import { useEffect, useState } from "react";

import { interpolateSpline } from "../utils/audio";

export default function WaveFormEmpty({ waveform, currentTime, duration, width, height = 60, barWidth = 3, gap = 3, color = "#80ffd7" } = {}) {
    const [unique] = useState(Math.random().toString());

    const generateWaveform = async () => {
        const svg = document.getElementById(`waveform-empty-${unique}`);
        const bars = document.getElementById(`waveform-empty-${unique}-bars`);
        if (!svg || !bars) return;
        const width = svg.parentElement.clientWidth;
        svg.setAttribute("width", width);
        let waveformData = Array(Math.floor(width / (barWidth + gap))).fill(barWidth);
        if (waveform) {
            waveformData = interpolateSpline(waveform, Math.floor(width / (barWidth + gap)));
            const max = Math.max(...waveformData);
            waveformData = waveformData.map((v) => (v / max) * (height - barWidth) + barWidth);
        }

        bars.innerHTML = "";
        for (let i = 0, x = 0; i < waveformData.length; i++) {
            let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", x);
            rect.setAttribute("y", (height - waveformData[i]) / 2);
            rect.setAttribute("width", barWidth);
            rect.setAttribute("height", Math.abs(waveformData[i]));
            rect.setAttribute("rx", barWidth / 2);
            // rect.setAttribute("fill", color);
            bars.appendChild(rect);
            x += gap + barWidth;
            if (x >= width) break;
        }
    };

    useEffect(() => {
        generateWaveform();
        // eslint-disable-next-line
    }, [width, waveform]);

    return (
        <svg id={`waveform-empty-${unique}`} style={{ height }}>
            <defs>
                <mask id={`waveform-empty-${unique}-mask`}>
                    <rect width="100%" height="100%" fill="#7b7b7b" />
                    <rect width={`${currentTime == null ? 100 : ((currentTime * 1000) / duration) * 100}%`} height="100%" fill="white" />
                </mask>
            </defs>
            <g id={`waveform-empty-${unique}-bars`} mask={`url(#waveform-empty-${unique}-mask)`} fill={color} />
        </svg>
    );
}
