export async function blobToSamples(blob, samples = 50, minValue = 2, maxValue = 30) {
    if (minValue >= maxValue) throw new Error("minValue doit être inférieur à maxValue.");
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    const channelData = audioBuffer.getChannelData(0);
    const segmentSize = Math.floor(channelData.length / samples);
    const waveform = [];

    for (let i = 0; i < samples; i++) {
        const start = i * segmentSize;
        const end = start + segmentSize;
        const segment = channelData.slice(start, end);
        const average = segment.reduce((sum, value) => sum + Math.abs(value), 0) / segment.length;
        waveform.push(average);
    }

    const max = Math.max(...waveform) || 1;
    const duration = audioBuffer.duration;
    const waveformNormalized = waveform.map((v) => (v / max) * (maxValue - minValue) + minValue);
    return { waveform: waveformNormalized, duration };
}

export const calculateBarData = (frequencyData, width, barWidth, gap, maxHeight) => {
    let units = width / (barWidth + gap);
    let step = Math.floor(frequencyData.length / units);

    if (units > frequencyData.length) {
        units = frequencyData.length;
        step = 1;
    }

    const data = [];

    for (let i = 0; i < units; i++) {
        let sum = 0;

        for (let j = 0; j < step && i * step + j < frequencyData.length; j++) {
            sum += frequencyData[i * step + j];
        }
        data.push(sum / step);
    }

    const max = Math.max(...data) || 1;
    const waveformNormalized = data.map((v) => (v / max) * (maxHeight - barWidth) + barWidth);
    return waveformNormalized;
};

export async function getAudioDuration(blob) {
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const arrayBuffer = await blob.arrayBuffer();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
    return audioBuffer.duration;
}

export function interpolateSpline(points, numPoints) {
    try {
        const n = points.length - 1,
            h = new Array(Math.max(n, 1)).fill(1), // Since x[i+1] - x[i] = 1 for uniformly spaced x
            a = [...points],
            b = new Array(n),
            d = new Array(n),
            alpha = new Array(n),
            c = new Array(n + 1).fill(0),
            l = new Array(n + 1).fill(0),
            mu = new Array(n + 1).fill(0),
            z = new Array(n + 1).fill(0);

        // Step 1: Calculate h and alpha
        for (let i = 0; i < n; i++) {
            alpha[i] = (3 / h[i]) * (a[i + 1] - a[i]) - (3 / h[i - 1]) * (a[i] - a[i - 1]);
        }

        // Step 2: Decomposition
        l[0] = 1;
        mu[0] = 0;
        z[0] = 0;

        for (let i = 1; i < n; i++) {
            l[i] = 4 - mu[i - 1]; // 2 * 2 - h[i - 1] * mu[i - 1];
            mu[i] = 1 / l[i]; // h[i] / l[i];
            z[i] = (alpha[i] - z[i - 1]) / l[i]; // (alpha[i] - h[i - 1] * z[i - 1]) / l[i];
        }

        l[n] = 1;
        z[n] = 0;
        c[n] = 0;

        // Step 3: Solve
        for (let j = n - 1; j >= 0; j--) {
            c[j] = z[j] - mu[j] * c[j + 1];
            b[j] = a[j + 1] - a[j] - (c[j + 1] + 2 * c[j]) / 3; //(a[j + 1] - a[j]) / h[j] - (h[j] * (c[j + 1] + 2 * c[j])) / 3;
            d[j] = (c[j + 1] - c[j]) / 3; // (c[j + 1] - c[j]) / (3 * h[j]);
        }

        // Interpolate
        const step = n / (numPoints - 1),
            interpolatedPoints = [];

        for (let i = 0; i < numPoints; i++) {
            const xi = Math.min(i * step, n), // Prevent overflow (i * step)
                j = Math.min(Math.floor(xi), n - 1), // Prevent overflow (Math.floor(xi))
                dx = xi - j,
                value = a[j] + dx * (b[j] + dx * (c[j] + dx * d[j])); // Horner's https://fr.wikipedia.org/wiki/M%C3%A9thode_de_Ruffini-Horner (a[j] + b[j] * dx + c[j] * dx * dx + d[j] * dx * dx * dx;)
            interpolatedPoints.push(Math.abs(value));
        }

        return interpolatedPoints;
    } catch (e) {
        return Array(numPoints).fill(2);
    }
}
