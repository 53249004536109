import { initReactI18next } from "react-i18next";

import { collection, getDocs } from "firebase/firestore";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/it";

import { StatsData } from "../context/GlobalContext";

import { db } from "./firebase";

const loadTranslations = async () => {
    const translations = {};
    const querySnapshot = await getDocs(collection(db, `i18n`));
    querySnapshot.forEach((doc) => {
        translations[doc.id] = doc.data();
    });
    return translations;
};

export const initializeI18n = async () => {
    const resources = await loadTranslations();
    const spptLngs = ["fr", "en", "es", "de", "it"];

    await i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            supportedLngs: spptLngs,
            nonExplicitSupportedLngs: true,
            resources,
            fallbackLng: "fr",
            interpolation: {
                escapeValue: false,
            },
            // debug: window.location.host.includes("localhost") || window.location.host.includes("127.0.0.1"),
            detection: {
                lookupQuerystring: "lang",
                order: ["querystring", "navigator", "localStorage"],
            },
        });

    i18n.on("languageChanged", (lng) => {
        moment.locale(lng.split("-")[0].toLowerCase());
        StatsData.language = lng.split("-")[0].toLowerCase();
        const url = new URL(window.location.href);
        if (url.searchParams.get("lang") === lng) return;
        url.searchParams.set("lang", lng);
        window.history.pushState({}, "", url);
    });

    const url = new URL(window.location.href);
    const lang = url.searchParams.get("lang")?.toLowerCase();
    if (lang && spptLngs.includes(lang)) {
        i18n.changeLanguage(lang);
    }

    moment.locale(i18n.language.split("-")[0].toLowerCase());
    StatsData.language = i18n.language.split("-")[0].toLowerCase();
};

export default i18n;
