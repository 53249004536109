import { useEffect, useState } from "react";

import WaveFormEmpty from "./WaveFormEmpty";
import WaveFormLive from "./WaveFormLive";
import WaveFormView from "./WaveFormView";

export default function WaveForm({ waveform, audioBlob, mediaRecorder, duration, height = 65, color = "#80ffd7", barWidth = 3, gap = 3, currentTime = null, setWarning = null, isLive = false } = {}) {
    const [unique] = useState(Math.random().toString());
    const [waveWidth, setWaveWidth] = useState(0);
    const [obs, setObs] = useState(null);

    useEffect(() => {
        const div = document.getElementById(`waveform-container-${unique}`);
        if (!obs && div) setObs(new ResizeObserver((entries) => setWaveWidth(entries[0].contentRect.width)).observe(div));
        return () => obs?.disconnect();
        // eslint-disable-next-line
    }, []);

    if (!audioBlob && !mediaRecorder)
        return (
            <div style={{ height }} id={`waveform-container-${unique}`}>
                <WaveFormEmpty width={waveWidth} height={height} gap={gap} color={color} waveform={waveform} currentTime={currentTime} duration={duration} />
            </div>
        );

    if (isLive)
        return (
            <div style={{ height }} id={`waveform-container-${unique}`}>
                <WaveFormLive mediaRecorder={mediaRecorder} height={height} width={waveWidth} color={color} barWidth={barWidth} gap={gap} />
            </div>
        );

    return (
        <div style={{ height }} id={`waveform-container-${unique}`}>
            <WaveFormView audioBlob={audioBlob} width={waveWidth} height={height} gap={gap} color={color} currentTime={currentTime} barWidth={barWidth} setWarning={setWarning} />
        </div>
    );
}
