import { createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SESSION_COOKIE_NAME, SESSION_DURATION, USER_COOKIE_NAME, USER_DURATION, generateRandomId, getCookie, setCookie } from "../services/stats";

const LOCAL_STORAGE_KEY = "history";

const encodeBase64 = (data) => {
    return window.btoa(JSON.stringify(data));
};

const decodeBase64 = (data) => {
    try {
        return JSON.parse(window.atob(data));
    } catch (e) {
        return [];
    }
};

const cleanOldEntries = (entries) => {
    const now = new Date().getTime();
    return entries.filter((entry) => now - entry.date < SESSION_DURATION);
};

const RouteListenerContext = createContext();

export const useRouteListener = () => useContext(RouteListenerContext);

export const RouteListenerProvider = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const now = new Date().getTime();

        // Handle session cookie
        let sessionId = getCookie(SESSION_COOKIE_NAME);
        if (!sessionId) sessionId = generateRandomId();
        setCookie(SESSION_COOKIE_NAME, sessionId, SESSION_DURATION);

        // Handle user cookie
        let userId = getCookie(USER_COOKIE_NAME);
        if (!userId) userId = generateRandomId();
        setCookie(USER_COOKIE_NAME, userId, USER_DURATION);

        // Existing history logic
        const savedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        let visitedUrls = savedData ? decodeBase64(savedData) : [];
        visitedUrls = cleanOldEntries(visitedUrls);
        const newEntry = {
            url: location.pathname,
            date: now,
        };
        visitedUrls.push(newEntry);
        const encodedData = encodeBase64(visitedUrls);
        localStorage.setItem(LOCAL_STORAGE_KEY, encodedData);
    }, [location]);

    const getLastUrl = (filt = []) => {
        const savedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        let visitedUrls = savedData ? decodeBase64(savedData) : [];
        visitedUrls = cleanOldEntries(visitedUrls);
        if (filt.length > 0) visitedUrls = visitedUrls.filter((entry) => !filt.includes(entry.url));

        return visitedUrls.length > 0 ? visitedUrls[visitedUrls.length - 1].url : null;
    };

    return <RouteListenerContext.Provider value={{ currentRoute: location.pathname, getLastUrl }}>{children}</RouteListenerContext.Provider>;
};
