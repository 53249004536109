import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { generateRandomId } from "../../utils/id";
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";

import { useUserContext } from "../../context/UserContext";

import { db } from "../../services/firebase";

import TopBar from "../../layout/TopBar";
import Layout from "../../layout/index";

import { FiSettings } from "react-icons/fi";

export const RecorderList = () => {
    const { t } = useTranslation();
    const { user: currentUser } = useUserContext();
    const navigate = useNavigate();

    const [recorders, setRecorders] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!currentUser) return;

        const fetchRecorders = async () => {
            try {
                // Create a reference to the user document
                const userRef = doc(db, "users", currentUser.uid);
                console.log("userRef", userRef.path);

                // Query the recorder collection where owner equals the user reference
                const recordersQuery = query(collection(db, "recorder"), where("owner", "==", userRef));
                const querySnapshot = await getDocs(recordersQuery);

                const recordersData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setRecorders(recordersData);
                setLoading(false);
            } catch (err) {
                console.error("Error fetching recorders:", err);
                setError(t("common:errors.load_failed"));
                setLoading(false);
            }
        };

        fetchRecorders();
    }, [currentUser, t]);

    const handleCreateRecorder = async () => {
        try {
            if (!currentUser) return;

            // Generate a unique ID
            let recorderId = generateRandomId();
            let exists = true;
            let attempts = 0;
            const maxAttempts = 10;

            while (exists && attempts < maxAttempts) {
                const docRef = doc(db, "recorder", recorderId);
                const docSnap = await getDoc(docRef);
                exists = docSnap.exists();

                if (exists) {
                    recorderId = generateRandomId(attempts + 6);
                    attempts++;
                }
            }

            if (attempts >= maxAttempts) {
                throw new Error("Failed to generate unique ID after maximum attempts");
            }

            // Create the new recorder document
            const userRef = doc(db, "users", currentUser.uid);
            const newRecorder = {
                name: `Recorder #${recorders.length + 1}`,
                owner: userRef,
                createdAt: new Date(),
            };

            await setDoc(doc(db, "recorder", recorderId), newRecorder);

            navigate(`/recorder/${recorderId}`);
        } catch (err) {
            console.error("Error creating recorder:", err);
            setError(t("common:errors.create_failed"));
        }
    };

    if (loading) {
        return (
            <Layout>
                <Container className="mt-3 p-0 pb-5">
                    <div className="d-flex justify-content-center align-items-center">
                        <h1>{t("common:status.loading")}</h1>
                    </div>
                </Container>
            </Layout>
        );
    }

    return (
        <Layout>
            <div fluid style={{ paddingBottom: 50 }}>
                <div style={{ marginLeft: 12, marginRight: 12 }}>
                    <TopBar />
                </div>

                <Container className="mt-3 p-0 pb-5" style={{ marginLeft: "18px", marginRight: "18px", maxWidth: "calc(100% - 36px)" }}>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <h1 style={{ margin: 0 }}>Recorder</h1>
                        <Button
                            variant="primary"
                            onClick={handleCreateRecorder}
                            size="sm"
                            style={{
                                backgroundColor: "#80fed8",
                                borderColor: "#80fed8",
                                color: "#000",
                                fontWeight: 600,
                                padding: "0.25rem 0.75rem",
                                fontSize: "0.875rem",
                                lineHeight: "1.5",
                                borderRadius: "0.2rem",
                            }}
                        >
                            {t("Create Recorder")}
                        </Button>
                    </div>

                    {error && (
                        <div className="d-flex justify-content-center align-items-center mb-4">
                            <h1 style={{ color: "#333" }}>{error}</h1>
                        </div>
                    )}

                    <Row xs={1} sm={2} className="g-3">
                        {recorders.map((recorder) => (
                            <Col key={recorder.id}>
                                <Card
                                    className="h-100 shadow"
                                    style={{
                                        backgroundColor: "#000",
                                        borderColor: "#444",
                                        borderWidth: "1px",
                                        borderStyle: "solid",
                                        color: "white",
                                    }}
                                >
                                    <Card.Body>
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <Card.Title className="mb-0 fw-bold" style={{ color: "white" }}>
                                                {recorder.name}
                                            </Card.Title>
                                            <FiSettings size={18} style={{ cursor: "pointer", color: "white" }} onClick={() => navigate(`/recorder/${recorder.id}`)} />
                                        </div>
                                        <Card.Subtitle className="mb-2 text-muted">ID: {recorder.id}</Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}

                        {recorders.length === 0 && !loading && !error && (
                            <Col xs={12}>
                                <div className="text-center p-4">
                                    <p>{t("No recorders found. Create your first recorder.")}</p>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};
