import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link, NavLink, useMatch } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { getAuth } from "firebase/auth";
import { collection, doc, getDoc } from "firebase/firestore";

import { db } from "../../services/firebase";

import { IsLarger, IsSmaller } from "../../components/Responsive";

import defaultBg from "../../Assets/img/default-bg.jpg";

import { FaMicrophoneAlt } from "react-icons/fa";
// Image par défau
import { FaCircleUser } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export const convertTimestampToDate = (timestamp) => {
    if (timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Mois commence à 0
        const day = String(date.getDate()).padStart(2, "0");
        if (year === 1970) {
            return ``;
        }
        return `${day}-${month}-${year}`;
    } else {
        return ``;
    }
};

export const AdminMenus = () => {
    const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
    const [menuWidth, setMenuWidth] = useState(120); // Largeur initiale du menu

    const handleResize = () => {
        const windowWidth = window.innerWidth;
        setIsMenuCollapsed(menuWidth > windowWidth / 10);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Vérifie au chargement initial
        return () => window.removeEventListener("resize", handleResize);
    }, [menuWidth]);

    const toggleMenu = () => {
        setIsMenuCollapsed((prev) => !prev);
    };
    const location = useLocation(); // Obtenez la route actuelle

    return (
        <div className="app-container">
            {/* Menu latéral */}
            <div className={`sidebar ${isMenuCollapsed ? "collapsed" : ""}`} style={{ width: isMenuCollapsed ? "60px" : `${menuWidth}px` }}>
                <button className="toggle-button" onClick={toggleMenu}>
                    {isMenuCollapsed ? "☰" : "Menu"}
                </button>
                {!isMenuCollapsed && (
                    <div class="dropdown">
                        <ul className="dropdown-items" style={{ color: "#fff" }}>
                            <li className="dropdown-item" style={{ color: "#fff", marginBottom: 30, gap: 10, display: "flex" }}>
                                <Link to="/admin/users" className={location.pathname === "/admin/users" ? "active fw-bold text-decoration-none" : "text-decoration-none"} style={{ color: "#fff" }}>
                                    <FaCircleUser style={{ color: "#fff", marginRight: 10 }} size={20} />
                                    Users
                                </Link>
                            </li>
                            <li className="dropdown-item">
                                <Link to="/admin/audios" className={location.pathname === "/admin/audios" ? "active fw-bold text-decoration-none" : "text-decoration-none"} style={{ color: "#fff", marginBottom: 30 }}>
                                    <FaMicrophoneAlt style={{ color: "#fff", marginRight: 10 }} size={20} />
                                    Vocasts
                                </Link>
                            </li>
                            <li className="dropdown-item">
                                <Link to="/admin/messages" className={location.pathname === "/admin/messages" ? "active fw-bold text-decoration-none" : "text-decoration-none"} style={{ color: "#fff", marginBottom: 30 }}>
                                    <MdEmail style={{ color: "#fff", marginRight: 10 }} size={20} />
                                    Messages
                                </Link>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
export default AdminMenus;

export const ImageGallery = ({ images }) => {
    const nombreImg = images?.length || 0;
    const tailleImg = nombreImg > 2 ? "remImg2" : "remImg3";
    if (nombreImg === 0) return <img src={defaultBg} alt="default" className={`imageGallery ${tailleImg}`} />;
    return (
        <div
            style={{
                display: "flex",
                gap: "10px",
                justifyContent: "center",
                flexWrap: "wrap",
            }}
        >
            {images.map((url, index) => (
                // eslint-disable-next-line jsx-a11y/img-redundant-alt
                <img key={index} src={url} alt={`Image ${index + 1}`} className={`imageGallery ${tailleImg}`} />
            ))}
        </div>
    );
};

export const LienImgUser = (userThumb) => {
    // Crée l'URL de l'image utilisateur
    const urlBase = "https://firebasestorage.googleapis.com:443/v0/b/vocast-app.appspot.com/o/assets%2Fthumbnails%2F";
    const url = `${urlBase}${userThumb}%2Fprofile.jpg?alt=media`;

    // Retourne l'URL pour validation dans un composant React
    return url;
};

export function AudioPlayer({ src }) {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    if (src === "audio/12345.mp4") {
        return <p>Rien</p>;
    }
    const url = `https://firebasestorage.googleapis.com/v0/b/vocast-app.appspot.com/o/${src.replaceAll("/", "%2F")}?alt=media`;

    const togglePlayPause = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <div>
            <audio ref={audioRef} src={url}></audio>
            <button style={{ border: "black 0", backgroundColor: "black" }} onClick={togglePlayPause}>
                {isPlaying ? "⏸️" : "▶️"}
            </button>
        </div>
    );
}
export const Connection = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // if not logged in, redirect /
        const checkAuth = async () => {
            const auth = getAuth();
            if (!auth.currentUser) {
                navigate("/");
                return;
            }

            const userDoc = await getDoc(doc(collection(db, "users"), auth.currentUser.uid));
            if (!userDoc.exists() || !userDoc.data().isAdmin) {
                navigate("/");
                return;
            }
            setLoading(false);
        };
        checkAuth();
        // eslint-disable-next-line
    }, []);

    if (loading) return <div>Loading...</div>;
};
