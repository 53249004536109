import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import AudioEmbed, { EMBED_DEFAULT_OPTIONS } from "../routes/Integration/AudioEmbed";

import { FaRotateLeft } from "react-icons/fa6";
import { FaCopy } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";

export const IframePopin = ({ audio, audioId, ...props }) => {
    const { t } = useTranslation();
    const [iframeOption, setIframeOption] = useState(EMBED_DEFAULT_OPTIONS);
    const [colorPicker, setColorPicker] = useState(false);

    const colorsDefault = {
        bgColor: "#2B6147",
        textColor: "#FFFFFF",
        cardColor: "#333333CC",
        cardContentColor: "#FFFFFF",
        // waveformColor: "#80ffd7",
    };

    const backgroundOptions = [
        { value: "vocast", label: t("IframePopin:vocast") },
        { value: "custom", label: t("IframePopin:custom") },
    ];

    const [maxWidth, setMaxWidth] = useState([600, "px"]);
    const [colors, setColors] = useState({ ...colorsDefault });
    const [embedHeight, setEmbedHeight] = useState(64);
    const [copied, setCopied] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const embedRef = useRef(null);

    useEffect(() => {
        if (!embedRef.current) return;
        const resizeObserver = new ResizeObserver((entries) => {
            setEmbedHeight(entries[0].contentRect.height);
        });
        resizeObserver.observe(document.querySelector("#embedIframe > div"));
        return () => {
            resizeObserver.disconnect();
        };
    }, [embedRef]);

    const buildDesign = () => {
        return window.btoa(
            JSON.stringify({
                ...Object.entries(iframeOption).reduce((acc, [key, value]) => {
                    if (EMBED_DEFAULT_OPTIONS[key] !== value) {
                        acc[key] = value;
                    }
                    return acc;
                }, {}),
                colors: iframeOption.background === "custom" ? colors : undefined,
                maxWidth: maxWidth.join(""),
                height: embedHeight + "px",
                api: "v2",
            }),
        );
    };

    const updateDesign = (key, value) => {
        let _iframeOption = { ...iframeOption, [key]: value };
        let isOldReduced =
            iframeOption.background === "custom" &&
            iframeOption.showProfile === false &&
            iframeOption.showTitle === false &&
            iframeOption.showDescription === false &&
            iframeOption.showIcons === false &&
            iframeOption.showLogo === false;

        let isNewReduced =
            _iframeOption.background === "custom" &&
            _iframeOption.showProfile === false &&
            _iframeOption.showTitle === false &&
            _iframeOption.showDescription === false &&
            _iframeOption.showIcons === false &&
            _iframeOption.showLogo === false;

        setIframeOption(_iframeOption);
        if (isOldReduced === isNewReduced) return;
        if (isNewReduced && colors.cardColor.length > 7) setColors({ ...colors, cardColor: colors.cardColor.slice(0, 7) });
        else if (!isNewReduced && colors.cardColor.length <= 7) setColors({ ...colors, cardColor: colors.cardColor + "CC" });
    };

    const buildUrl = () => {
        let origin = window.location.origin;
        let id = encodeURIComponent(audioId);
        let _design = encodeURIComponent(buildDesign());

        return `<script src="${origin}/integration.js?id=${id}&design=${_design}"></script>`;
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal lg">
            <Modal.Header>
                <h5 className="modal-title">{t("IframePopin:embedVocast")}</h5>
                <button type="button" className="btn-close" style={{ padding: 0, width: 40, height: 40 }} onClick={props.onHide}>
                    <IoClose size={40} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Row style={{ justifyContent: "space-between" }}>
                    <Col xs={12} md={5} style={{ position: "relative" }}>
                        {colorPicker && (
                            <div style={{ position: "absolute", height: "100%", width: "100%", zIndex: 1000, backgroundColor: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ backgroundColor: "#fff", padding: 16, borderRadius: 10, position: "relative" }}>
                                    <SketchPicker
                                        color={colors[colorPicker]}
                                        onChange={(color, ...rest) => {
                                            const AlphaToHex = (a) => `0${Math.round((255 / 100) * a).toString(16)}`.slice(-2).toUpperCase();
                                            let hex = color.rgb.a !== undefined && color.rgb.a !== 1 ? `${color.hex}${AlphaToHex(color.rgb.a * 100)}` : color.hex;
                                            setColors({ ...colors, [colorPicker]: hex.toUpperCase() });
                                        }}
                                        styles={{ default: { picker: { boxShadow: "none" } } }}
                                        presetColors={[
                                            { color: "#2B6147", title: "Default Background" },
                                            { color: "#FFFFFF", title: "Default Text" },
                                            { color: "#333333cc", title: "Default Card" },
                                            { color: "#FFFFFF", title: "Default Card Content" },
                                        ]}
                                        disableAlpha={false}
                                    />
                                    <button type="button" className="btn-close" style={{ width: 18, height: 18, position: "absolute", right: 4, top: 4 }} onClick={() => setColorPicker(false)}></button>
                                </div>
                            </div>
                        )}
                        <div className="d-md-none" style={{ marginTop: 20 }} />
                        <h5 style={{ marginBottom: 16 }}>{t("IframePopin:config")}</h5>
                        <section style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                            <div className="form-check" style={{ display: "flex", flexDirection: "row", gap: 16, justifyContent: "space-between" }}>
                                <label className="form-check-label" htmlFor="showProfile">
                                    {t("IframePopin:showProfile")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showProfile" checked={iframeOption.showProfile} onChange={() => updateDesign("showProfile", !iframeOption.showProfile)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", gap: 16, justifyContent: "space-between" }}>
                                <label className="form-check-label" htmlFor="showTitle">
                                    {t("IframePopin:showTitle")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showTitle" checked={iframeOption.showTitle} onChange={() => updateDesign("showTitle", !iframeOption.showTitle)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showDescription">
                                    {t("IframePopin:showDescription")}
                                </label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="showDescription"
                                    checked={iframeOption.showDescription}
                                    onChange={() => updateDesign("showDescription", !iframeOption.showDescription)}
                                />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showVocaster">
                                    {t("IframePopin:showVocaster")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showVocaster" checked={iframeOption.showVocaster} onChange={() => updateDesign("showVocaster", !iframeOption.showVocaster)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showFlag">
                                    {t("IframePopin:showFlag")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showFlag" checked={iframeOption.showFlag} onChange={() => updateDesign("showFlag", !iframeOption.showFlag)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showIcons">
                                    {t("IframePopin:showIcons")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showIcons" checked={iframeOption.showIcons} onChange={() => updateDesign("showIcons", !iframeOption.showIcons)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showLogo">
                                    {t("IframePopin:showLogo")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showLogo" checked={iframeOption.showLogo} onChange={() => updateDesign("showLogo", !iframeOption.showLogo)} />
                            </div>
                        </section>
                        <h5 style={{ marginTop: 20, marginBottom: 16 }}>{t("IframePopin:design")}</h5>
                        <section style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8 }}>
                                <label className="form-check-label" htmlFor="background">
                                    {t("IframePopin:background")}
                                </label>
                                <div style={{}}>
                                    <Select
                                        className="react-select-container"
                                        classNamePrefix="react-select"
                                        options={backgroundOptions}
                                        defaultValue={backgroundOptions[0]}
                                        value={backgroundOptions.find((item) => item.value === iframeOption.background)}
                                        name="background"
                                        onChange={(e) => {
                                            if (e.value === iframeOption.background) return;
                                            let { bgColor, textColor, ...rest } = iframeOption;
                                            setIframeOption({ ...rest, background: e.value });
                                        }}
                                    />
                                </div>
                            </div>
                            {iframeOption.background === "custom" && (
                                <>
                                    <div className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                                        <label className="form-check-label" htmlFor="bgColor">
                                            {t("IframePopin:bgColor")}
                                        </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    gap: 4,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                                    alignItems: "center",
                                                    borderRadius: 5,
                                                    width: 100,
                                                    position: "relative",
                                                    backgroundColor: "gray",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setColorPicker("bgColor")}
                                            >
                                                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.bgColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                                <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.bgColor}</div>
                                            </div>
                                            {colors.bgColor !== colorsDefault.bgColor && (
                                                <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, bgColor: colorsDefault.bgColor })}>
                                                    <FaRotateLeft />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                                        <label className="form-check-label" htmlFor="textColor">
                                            {t("IframePopin:textColor")}
                                        </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    gap: 4,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                                    alignItems: "center",
                                                    borderRadius: 5,
                                                    width: 100,
                                                    position: "relative",
                                                    backgroundColor: "gray",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setColorPicker("textColor")}
                                            >
                                                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.textColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                                <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.textColor}</div>
                                            </div>
                                            {colors.textColor !== colorsDefault.textColor && (
                                                <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, textColor: colorsDefault.textColor })}>
                                                    <FaRotateLeft />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                                        <label className="form-check-label" htmlFor="cardColor">
                                            {t("IframePopin:cardColor")}
                                        </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    gap: 4,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                                    alignItems: "center",
                                                    borderRadius: 5,
                                                    width: 100,
                                                    position: "relative",
                                                    backgroundColor: "gray",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setColorPicker("cardColor")}
                                            >
                                                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.cardColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                                <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.cardColor}</div>
                                            </div>
                                            {colors.cardColor !== colorsDefault.cardColor && (
                                                <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, cardColor: colorsDefault.cardColor })}>
                                                    <FaRotateLeft />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                                        <label className="form-check-label" htmlFor="cardContentColor">
                                            {t("IframePopin:cardContentColor")}
                                        </label>
                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                            <div
                                                style={{
                                                    padding: 4,
                                                    gap: 4,
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                                    alignItems: "center",
                                                    borderRadius: 5,
                                                    width: 100,
                                                    position: "relative",
                                                    backgroundColor: "gray",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => setColorPicker("cardContentColor")}
                                            >
                                                <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors.cardContentColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                                <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase" }}>{colors.cardContentColor}</div>
                                            </div>
                                            {colors.cardContentColor !== colorsDefault.cardContentColor && (
                                                <span style={{ cursor: "pointer" }} onClick={() => setColors({ ...colors, cardContentColor: colorsDefault.cardContentColor })}>
                                                    <FaRotateLeft />
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16 }}>
                                <label className="form-check-label" htmlFor="showBorder">
                                    {t("IframePopin:showBorder")}
                                </label>
                                <input className="form-check-input" type="checkbox" id="showBorder" checked={iframeOption.showBorder} onChange={() => updateDesign("showBorder", !iframeOption.showBorder)} />
                            </div>

                            <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 16, alignItems: "center" }}>
                                <label className="form-check-label" htmlFor="maxWidth">
                                    {t("IframePopin:maxWidth")}
                                </label>
                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <input
                                        type="number"
                                        id="maxWidth"
                                        value={maxWidth[0]}
                                        onChange={(e) => setMaxWidth((v) => [e.target.value, v[1]])}
                                        style={{
                                            width: 100,
                                            height: 38,
                                            borderTopLeftRadius: 5,
                                            borderBottomLeftRadius: 5,
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            border: "1px solid #ccc",
                                            borderRight: "none",
                                            padding: 5,
                                            textAlign: "center",
                                            fontSize: 18,
                                        }}
                                    />
                                    <select
                                        value={maxWidth[1]}
                                        onChange={(e) => setMaxWidth((v) => [v[0], e.target.value])}
                                        style={{
                                            width: 70,
                                            height: 38,
                                            borderRadiusTopLeft: 0,
                                            borderRadiusBottomLeft: 0,
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                            border: "1px solid #ccc",
                                            padding: 0,
                                            fontSize: 18,
                                        }}
                                    >
                                        <option value="px">px</option>
                                        <option value="%">%</option>
                                        <option value="em">em</option>
                                        <option value="rem">rem</option>
                                        <option value="vw">vw</option>
                                        <option value="vh">vh</option>
                                        <option value="vmin">vmin</option>
                                        <option value="vmax">vmax</option>
                                        <option value="cm">cm</option>
                                        <option value="mm">mm</option>
                                        <option value="in">in</option>
                                        <option value="pt">pt</option>
                                        <option value="pc">pc</option>
                                    </select>
                                </div>
                            </div>
                        </section>
                    </Col>
                    <Col xs={12} md={6} style={{ display: "flex", flexDirection: "column" }}>
                        <div>
                            <div className="d-md-none" style={{ marginTop: 20 }} />
                            <h5 style={{ marginBottom: 16 }}>{t("IframePopin:preview")}</h5>
                            <div style={{ backgroundColor: "#fff", borderRadius: 10, padding: 10 }} id="embedIframe" ref={embedRef}>
                                <div style={{ width: "100%", height: "100%", maxWidth: maxWidth.join("") }}>
                                    <AudioEmbed design={buildDesign()} inApp={audioId} />
                                </div>
                            </div>
                        </div>
                        <div>
                            <h5 style={{ marginBottom: 16, marginTop: 20 }}>{t("IframePopin:copy")}</h5>
                            <div style={{ border: "1px solid #ccc", padding: 10, borderRadius: 10, backgroundColor: "#f8f9fa", overflow: "auto", position: "relative" }}>
                                <pre
                                    style={{ whiteSpace: "pre-wrap", overflowWrap: "anywhere", marginBottom: 0, fontSize: 12, cursor: "pointer" }}
                                    id="codeEmbed"
                                    onClick={() => {
                                        if (document.selection) {
                                            let range = document.body.createTextRange();
                                            range.moveToElementText(document.getElementById("codeEmbed"));
                                            range.select();
                                        } else if (window.getSelection) {
                                            let range = document.createRange();
                                            range.selectNode(document.getElementById("codeEmbed"));
                                            window.getSelection().removeAllRanges();
                                            window.getSelection().addRange(range);
                                        }
                                        navigator.clipboard.writeText(document.getElementById("codeEmbed").innerText);
                                        setCopied(true);
                                        setIsHovered(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                            setIsHovered(false);
                                        }, 2000);
                                    }}
                                >
                                    {buildUrl()}
                                </pre>
                                <button
                                    className="btn btn-primary"
                                    style={{
                                        position: "absolute",
                                        bottom: 5,
                                        right: 5,

                                        maxWidth: isHovered ? 200 : 40,
                                        minWidth: 40,
                                        padding: 10,
                                        overflow: "hidden",
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        transition: "all 0.3s ease",
                                        gap: 12,
                                        height: 40,
                                    }}
                                    onClick={() => {
                                        navigator.clipboard.writeText(document.getElementById("codeEmbed").innerText);
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2000);
                                    }}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <FaCopy style={{ color: "#000" }} size={16} />
                                    {isHovered && (
                                        <span style={{ whiteSpace: "nowrap", opacity: isHovered ? 1 : 0, transition: "opacity 0.3s ease", fontWeight: 600 }}>
                                            {copied ? t("IframePopin:copied") : t("IframePopin:copyCode")}
                                        </span>
                                    )}
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};
