import { isDesktop, isMobile, isSmartTV, isTablet, osName } from "react-device-detect";

import { getCountry } from "../utils/country";

import { StatsData } from "../context/GlobalContext";

const TRACK_URL = "https://europe-west1-vocast-app.cloudfunctions.net/track";
export const SESSION_COOKIE_NAME = "session_cookie";
export const USER_COOKIE_NAME = "user_cookie";
export const SESSION_DURATION = 30 * 60 * 1000; // 30 minutes
export const USER_DURATION = 365 * 24 * 60 * 60 * 1000; // 1 year

// {
//     "type": "visit",
//     "value": "test 1",
//     "meta": [
//         {"key": "meta_key", "value": "meta_value"},
//         {"key": "meta_key_1", "value": "meta_value_2"}
//     ],
//     "sid": "GF85767UGJKUTU",
//     "cid": "user_cookie_id", // User cookie
//     "uid": "KJHKkjhKHKJHKjhkjh7658", // Firebase user id
// }

const getDeviceType = () => {
    if (isMobile) return "mobile";
    if (isTablet) return "tablet";
    if (isSmartTV) return "smart_tv";
    if (isDesktop) return "desktop";
    return "other";
};

export const setCookie = (name, value, expirationMs) => {
    const date = new Date();
    date.setTime(date.getTime() + expirationMs);
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/;SameSite=None;Secure";
};

export const getCookie = (name) => {
    const cookieName = name + "=";
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length);
        }
    }
    return "";
};

export const generateRandomId = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
};

const event_fetch = (from, data) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(data);
    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    fetch(TRACK_URL, requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(from, data, result))
        .catch((error) => console.error(from, data, error));
};

// get utm_source && utm_medium form url
const getUtmSourceAndMedium = () => {
    const url = new URL(document.location.href);
    return {
        source: url.searchParams.get("utm_source"),
        medium: url.searchParams.get("utm_medium"),
    };
};

const getReferrerHostname = (referrer) => {
    return referrer || document.referrer || document.location.href || "unknown";
};

export const event_page_vocast = ({ vocastId, ownerId, environment = "webapp", referrer }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "vocast_view",
        value: vocastId,
        meta: [
            { key: "country", value: getCountry().toLowerCase() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "referer", value: referrerHostname },
            { key: "ownerId", value: ownerId },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: StatsData.user,
    };

    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_page_vocast", event);
};

export const event_page_profile = ({ profileId, environment = "webapp", referrer }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "profil_view",
        value: profileId,
        meta: [
            { key: "country", value: getCountry().toLowerCase() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "referer", value: referrerHostname },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: StatsData.user,
    };

    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_page_profile", event);
};

export const event_page_recorder = ({ ownerId, environment = "webapp", referrer, extraCallback, extraExternalId, extraAllowPause }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "recorder_view",
        value: ownerId,
        meta: [
            { key: "country", value: getCountry().toLowerCase() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "referer", value: referrerHostname },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: StatsData.user,
    };
    if (extraCallback) event.meta.push({ key: "extra_callback", value: extraCallback });
    if (extraExternalId) event.meta.push({ key: "extra_external_id", value: extraExternalId });
    if (extraAllowPause) event.meta.push({ key: "extra_allow_pause", value: extraAllowPause.toString() });

    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_page_recorder", event);
};

export const event_user_create = ({ environment = "webapp", userId, provider = "email" }) => {
    let event = {
        type: "user_create",
        value: userId || StatsData.user,
        meta: [
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "country", value: getCountry().toLowerCase() },
            { key: "provider", value: provider },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: userId || StatsData.user,
    };

    event_fetch("event_user_create", event);
};

export const event_user_login = ({ environment = "webapp", userId, provider = "email" }) => {
    let event = {
        type: "user_login",
        value: userId || StatsData.user,
        meta: [
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "country", value: getCountry().toLowerCase() },
            { key: "provider", value: provider },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: userId || StatsData.user,
    };

    event_fetch("event_user_login", event);
};

export const event_vocast_listen = ({ audioId, ownerId, contributorId, listener, audioLanguage, environment = "webapp", referrer }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "vocast_listen",
        value: audioId,
        meta: [
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "country", value: getCountry().toLowerCase() },
            { key: "referrer", value: referrerHostname },
            { key: "owner", value: ownerId },
            { key: "audio_language", value: audioLanguage },
            { key: "listener", value: listener },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: StatsData.user,
    };

    if (contributorId) event.meta.push({ key: "contributor", value: contributorId });
    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_vocast_listen", event);
};

export const event_vocast_create = ({ ownerId, environment = "webapp", privacy, vocastId, referrer }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "vocast_create",
        value: ownerId || StatsData.user,
        meta: [
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "country", value: getCountry().toLowerCase() },
            { key: "referrer", value: referrerHostname },
            { key: "privacy", value: privacy },
            { key: "vocast_id", value: vocastId },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: ownerId || StatsData.user,
    };

    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_vocast_create", event);
};

export const event_vocast_like = ({ vocastId, environment = "webapp", referrer }) => {
    let referrerHostname = getReferrerHostname(referrer);

    let event = {
        type: "vocast_like",
        value: vocastId,
        meta: [
            { key: "os", value: osName.replace(/ /g, "_").toLowerCase() },
            { key: "environment", value: environment },
            { key: "device", value: getDeviceType() },
            { key: "language", value: StatsData.language.toLowerCase() },
            { key: "country", value: getCountry().toLowerCase() },
            { key: "referrer", value: referrerHostname },
        ],
        sid: getCookie(SESSION_COOKIE_NAME),
        cid: getCookie(USER_COOKIE_NAME),
        uid: StatsData.user,
    };

    const { source, medium } = getUtmSourceAndMedium();
    if (source) event.meta.push({ key: "source", value: source });
    if (medium) event.meta.push({ key: "medium", value: medium });

    event_fetch("event_vocast_like", event);
};
