import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { doc, setDoc } from "firebase/firestore";
import Cookies from "js-cookie";

import { db } from "../services/firebase";

import { IoClose } from "react-icons/io5";

export const ContactPopin = ({ user, context, ...props }) => {
    const { t } = useTranslation();
    // user, setModalContact, formSent, handleFormSend, formMessage, setFormMessage, formName, setFormName, formEmail, setFormEmail, formCheckSubscription, setFormCheckSubscription
    const [formName, setFormName] = useState(Cookies.get("visitorName") ? Cookies.get("visitorName") : "");
    const [formEmail, setFormEmail] = useState(Cookies.get("visitorEmail") ? Cookies.get("visitorEmail") : "");
    const [formMessage, setFormMessage] = useState("");
    // const [formCheckSubscription, setFormCheckSubscription] = useState(false);
    const [formSent, setFormSent] = useState(false);

    const handleFormSend = () => {
        const messageRef = doc(db, "messages", `${context}-${new Date().getTime()}`);
        // Check if form email is valid with regexp
        if (!formEmail.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
            alert(t("common:alerts.invalidEmail"));
            return;
        }
        if (formName.length < 3) {
            alert(t("common:alerts.invalidName"));
            return;
        }

        if (formMessage.length < 3) {
            alert(t("common:alerts.invalidMessage"));
            return;
        }

        Cookies.set("visitorEmail", formEmail, { expires: 365 });
        Cookies.set("visitorName", formName, { expires: 365 });

        setDoc(messageRef, {
            email: formEmail,
            name: formName,
            message: formMessage,
            subscription: false, //formCheckSubscription,
            recipient: user.id,
            context: context,
            status: "unread",
            date: new Date(),
        });
        setFormSent(true);
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
            <Modal.Header>
                <Modal.Title>{t("ContactPopin:contact", { username: user?.name })}</Modal.Title>
                <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40 }} onClick={props.onHide}>
                    <IoClose style={{ width: 40, height: 40 }} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="mt-2">
                    {formSent ? (
                        <p>{t("ContactPopin:messageSent")}</p>
                    ) : (
                        <div>
                            <textarea placeholder={t("common:placeholders.message")} className="form-control mt-2" value={formMessage} onChange={(e) => setFormMessage(e.target.value)} />
                            <input name="name" type="text" className="form-control mt-2" placeholder={t("common:placeholders.name")} value={formName} onChange={(e) => setFormName(e.target.value)} />
                            <input name="email" type="email" className="form-control mt-2" placeholder={t("common:placeholders.email")} value={formEmail} onChange={(e) => setFormEmail(e.target.value)} />
                            {/* <div className="form-check mt-2">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={formCheckSubscription} onChange={(e) => setFormCheckSubscription(e.target.checked)} />
                                <label className="form-check-label text-truncate" htmlFor="flexCheckDefault">
                                    {t("ContactPopin:subscribe", { nickname: user?.nickname })}
                                </label>
                            </div> */}
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.onHide}>
                    {formSent ? t("common:actions.close") : t("common:actions.cancel")}
                </Button>
                {!formSent && (
                    <Button variant="primary" onClick={handleFormSend}>
                        {t("common:actions.send")}
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
