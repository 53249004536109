import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { FaRotateLeft } from "react-icons/fa6";

export const WidgetTab = ({ options, onOptionsChange, colors, onColorChange, colorsDefault }) => {
    const { t } = useTranslation();
    const titleOptions = ["disabled", "default", "input", "hidden"].map((title) => ({
        value: title,
        label: t(`Recorder:widgetConfiguration.titleOptions.${title}`),
    }));

    const descOptions = ["disabled", "default", "input", "hidden"].map((desc) => ({
        value: desc,
        label: t(`Recorder:widgetConfiguration.descOptions.${desc}`),
    }));

    const titleBlockOptions = ["disabled", "default"].map((option) => ({
        value: option,
        label: t(`Recorder:widgetConfiguration.titleBlockOptions.${option}`),
    }));

    const descBlockOptions = ["disabled", "default"].map((option) => ({
        value: option,
        label: t(`Recorder:widgetConfiguration.descBlockOptions.${option}`),
    }));

    const backgroundOptions = ["vocast", "no", "yes"].map((background) => ({
        value: background,
        label: t(`Recorder:widgetConfiguration.backgroundOptions.${background}`),
    }));

    const selectStyles = {
        control: (base) => ({
            ...base,
            backgroundColor: "transparent",
            borderColor: "#ccc",
            "&:hover": {
                borderColor: "#fff",
            },
        }),
        menu: (base) => ({
            ...base,
            backgroundColor: "#2c2c2c",
            borderColor: "#ccc",
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isFocused ? "#3c3c3c" : "transparent",
            color: "#fff",
            "&:hover": {
                backgroundColor: "#3c3c3c",
            },
        }),
        singleValue: (base) => ({
            ...base,
            color: "#fff",
        }),
    };

    const handleWidgetSettingChange = (setting, value) => {
        onOptionsChange({
            ...options,
            [setting]: value,
        });
    };

    return (
        <div>
            <h5 style={{ color: "#fff", marginBottom: 16 }}>{t("Recorder:widgetConfiguration.title")}</h5>
            <section style={{ display: "flex", flexDirection: "column", gap: 16, marginLeft: 16 }}>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="showProfile" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.showProfile")}
                    </label>
                    <Form.Check type="switch" id="showProfile" checked={options?.showProfile || false} onChange={(e) => handleWidgetSettingChange("showProfile", e.target.checked)} className="custom-switch" />
                </div>

                {!options.showProfile && (
                    <>
                        <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                            <label className="form-check-label" htmlFor="titleBlockMode" style={{ color: "#fff" }}>
                                {t("Recorder:widgetConfiguration.titleBlockLabel")}
                            </label>
                            <div style={{ maxWidth: 250, width: "100%" }}>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={titleBlockOptions}
                                    defaultValue={titleBlockOptions[0]}
                                    value={titleBlockOptions.find((item) => item.value === options.titleBlockMode)}
                                    name="titleBlockMode"
                                    styles={selectStyles}
                                    onChange={(e) => {
                                        const newOptions = {
                                            ...options,
                                            titleBlockMode: e.value,
                                            titleBlock: e.value === "default" ? options.titleBlock || "" : "",
                                        };
                                        console.log("Configuration changed:", newOptions);
                                        onOptionsChange(newOptions);
                                    }}
                                />
                            </div>
                        </div>

                        {options.titleBlockMode === "default" && (
                            <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                                <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                    <label className="form-check-label" htmlFor="titleBlock" style={{ color: "#fff" }}>
                                        {t("Recorder:widgetConfiguration.titleBlockInput")}
                                    </label>
                                    <input
                                        type="text"
                                        id="titleBlock"
                                        placeholder={t("Recorder:widgetConfiguration.titleBlockInputPlaceholder")}
                                        onChange={(e) => {
                                            const newOptions = { ...options, titleBlock: e.target.value };
                                            console.log("Configuration changed:", newOptions);
                                            onOptionsChange(newOptions);
                                        }}
                                        value={options.titleBlock}
                                        style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                                    />
                                </li>
                            </ul>
                        )}

                        <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                            <label className="form-check-label" htmlFor="descBlockMode" style={{ color: "#fff" }}>
                                {t("Recorder:widgetConfiguration.descBlockLabel")}
                            </label>
                            <div style={{ maxWidth: 250, width: "100%" }}>
                                <Select
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    options={descBlockOptions}
                                    defaultValue={descBlockOptions[0]}
                                    value={descBlockOptions.find((item) => item.value === options.descBlockMode)}
                                    name="descBlockMode"
                                    styles={selectStyles}
                                    onChange={(e) => {
                                        const newOptions = {
                                            ...options,
                                            descBlockMode: e.value,
                                            descBlock: e.value === "default" ? options.descBlock || "" : "",
                                        };
                                        console.log("Configuration changed:", newOptions);
                                        onOptionsChange(newOptions);
                                    }}
                                />
                            </div>
                        </div>

                        {options.descBlockMode === "default" && (
                            <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                                <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                                    <label className="form-check-label" htmlFor="descBlock" style={{ color: "#fff" }}>
                                        {t("Recorder:widgetConfiguration.descBlockInput")}
                                    </label>
                                    <input
                                        type="text"
                                        id="descBlock"
                                        placeholder={t("Recorder:widgetConfiguration.descBlockInputPlaceholder")}
                                        onChange={(e) => {
                                            const newOptions = { ...options, descBlock: e.target.value };
                                            console.log("Configuration changed:", newOptions);
                                            onOptionsChange(newOptions);
                                        }}
                                        value={options.descBlock}
                                        style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                                    />
                                </li>
                            </ul>
                        )}
                    </>
                )}

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="titleMode" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.titleLabel")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={titleOptions}
                            defaultValue={titleOptions[0]}
                            value={titleOptions.find((item) => item.value === options.titleMode)}
                            name="titleMode"
                            styles={selectStyles}
                            onChange={(e) => {
                                const newOptions = {
                                    ...options,
                                    titleMode: e.value,
                                    title: e.value === "default" ? options.title || "" : "",
                                };
                                console.log("Configuration changed:", newOptions);
                                onOptionsChange(newOptions);
                            }}
                        />
                    </div>
                </div>

                {options.titleMode !== "disabled" && (
                    <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                        <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                            <label className="form-check-label" htmlFor="title" style={{ color: "#fff" }}>
                                {t("Recorder:widgetConfiguration.titleInput")}
                            </label>
                            <input
                                type="text"
                                id="title"
                                placeholder={t("Recorder:widgetConfiguration.titleInputPlaceholder")}
                                onChange={(e) => {
                                    const newOptions = { ...options, title: e.target.value };
                                    console.log("Configuration changed:", newOptions);
                                    onOptionsChange(newOptions);
                                }}
                                value={options.title}
                                style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                            />
                        </li>
                    </ul>
                )}

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="descMode" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.descLabel")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={descOptions}
                            defaultValue={descOptions[0]}
                            value={descOptions.find((item) => item.value === options.descMode)}
                            name="descMode"
                            styles={selectStyles}
                            onChange={(e) => {
                                const newOptions = {
                                    ...options,
                                    descMode: e.value,
                                    desc: e.value === "default" ? options.desc || "" : "",
                                };
                                console.log("Configuration changed:", newOptions);
                                onOptionsChange(newOptions);
                            }}
                        />
                    </div>
                </div>

                {options.descMode !== "disabled" && (
                    <ul style={{ paddingLeft: 20, gap: 5, display: "flex", flexDirection: "column" }}>
                        <li className="form-check" style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: 8, padding: 0 }}>
                            <label className="form-check-label" htmlFor="desc" style={{ color: "#fff" }}>
                                {t("Recorder:widgetConfiguration.descInput")}
                            </label>
                            <input
                                type="text"
                                id="desc"
                                placeholder={t("Recorder:widgetConfiguration.descInputPlaceholder")}
                                onChange={(e) => {
                                    const newOptions = { ...options, desc: e.target.value };
                                    console.log("Configuration changed:", newOptions);
                                    onOptionsChange(newOptions);
                                }}
                                value={options.desc}
                                style={{ border: "1px solid #ccc", padding: 4, borderRadius: 5, backgroundColor: "transparent", color: "#fff", width: "250px" }}
                            />
                        </li>
                    </ul>
                )}

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="inputContributorPicture" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.inputContributorPicture")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="inputContributorPicture"
                        checked={options?.inputContributorPicture || false}
                        onChange={(e) => handleWidgetSettingChange("inputContributorPicture", e.target.checked)}
                        className="custom-switch"
                    />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="inputAudioFromFile" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.inputAudioFromFile")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="inputAudioFromFile"
                        checked={options?.inputAudioFromFile || false}
                        onChange={(e) => handleWidgetSettingChange("inputAudioFromFile", e.target.checked)}
                        className="custom-switch"
                    />
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="allowRecordingPause" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.allowRecordingPause")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="allowRecordingPause"
                        checked={options?.allowRecordingPause || false}
                        onChange={(e) => handleWidgetSettingChange("allowRecordingPause", e.target.checked)}
                        className="custom-switch"
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8 }}>
                    <label htmlFor="background" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.backgroundLabel")}
                    </label>
                    <div style={{ maxWidth: 250, width: "100%" }}>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            options={backgroundOptions}
                            defaultValue={backgroundOptions[0]}
                            value={backgroundOptions.find((item) => item.value === options.background)}
                            name="background"
                            styles={selectStyles}
                            onChange={(e) => {
                                if (e.value === options.background) return;
                                const newOptions = {
                                    ...options,
                                    background: e.value,
                                    hasStyleChanges: true,
                                };
                                console.log("Configuration changed:", newOptions);
                                onOptionsChange(newOptions);
                            }}
                        />
                    </div>
                </div>

                {options.background === "no" && (
                    <>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, marginLeft: 20 }}>
                            <label htmlFor="bgColor" style={{ color: "#fff" }}>
                                {t("Recorder:colors.bgColor")}
                            </label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                <div
                                    style={{
                                        padding: 4,
                                        gap: 4,
                                        display: "flex",
                                        flexDirection: "row",
                                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        width: 100,
                                        position: "relative",
                                        backgroundColor: "gray",
                                    }}
                                >
                                    <input
                                        type="color"
                                        id="bgColor"
                                        value={colors?.bgColor || colorsDefault.bgColor}
                                        onChange={(e) => onColorChange("bgColor", e.target.value)}
                                        style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                    />
                                    <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors?.bgColor || colorsDefault.bgColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                    <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase", color: "#fff" }}>{colors?.bgColor || colorsDefault.bgColor}</div>
                                </div>
                                {colors?.bgColor !== colorsDefault.bgColor && (
                                    <span style={{ cursor: "pointer", color: "#fff" }} onClick={() => onColorChange("bgColor", colorsDefault.bgColor)}>
                                        <FaRotateLeft />
                                    </span>
                                )}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, marginLeft: 20 }}>
                            <label htmlFor="textColor" style={{ color: "#fff" }}>
                                {t("Recorder:colors.textColor")}
                            </label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                <div
                                    style={{
                                        padding: 4,
                                        gap: 4,
                                        display: "flex",
                                        flexDirection: "row",
                                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        width: 100,
                                        position: "relative",
                                        backgroundColor: "gray",
                                    }}
                                >
                                    <input
                                        type="color"
                                        id="textColor"
                                        value={colors?.textColor || colorsDefault.textColor}
                                        onChange={(e) => onColorChange("textColor", e.target.value)}
                                        style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                    />
                                    <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors?.textColor || colorsDefault.textColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                    <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase", color: "#fff" }}>{colors?.textColor || colorsDefault.textColor}</div>
                                </div>
                                {colors?.textColor !== colorsDefault.textColor && (
                                    <span style={{ cursor: "pointer", color: "#fff" }} onClick={() => onColorChange("textColor", colorsDefault.textColor)}>
                                        <FaRotateLeft />
                                    </span>
                                )}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, marginLeft: 20 }}>
                            <label htmlFor="cardColor" style={{ color: "#fff" }}>
                                {t("Recorder:colors.cardColor")}
                            </label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                <div
                                    style={{
                                        padding: 4,
                                        gap: 4,
                                        display: "flex",
                                        flexDirection: "row",
                                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        width: 100,
                                        position: "relative",
                                        backgroundColor: "gray",
                                    }}
                                >
                                    <input
                                        type="color"
                                        id="cardColor"
                                        value={colors?.cardColor || colorsDefault.cardColor}
                                        onChange={(e) => onColorChange("cardColor", e.target.value)}
                                        style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                    />
                                    <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors?.cardColor || colorsDefault.cardColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                    <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase", color: "#fff" }}>{colors?.cardColor || colorsDefault.cardColor}</div>
                                </div>
                                {colors?.cardColor !== colorsDefault.cardColor && (
                                    <span style={{ cursor: "pointer", color: "#fff" }} onClick={() => onColorChange("cardColor", colorsDefault.cardColor)}>
                                        <FaRotateLeft />
                                    </span>
                                )}
                            </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, marginLeft: 20 }}>
                            <label htmlFor="cardContentColor" style={{ color: "#fff" }}>
                                {t("Recorder:colors.cardContentColor")}
                            </label>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                                <div
                                    style={{
                                        padding: 4,
                                        gap: 4,
                                        display: "flex",
                                        flexDirection: "row",
                                        boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                        alignItems: "center",
                                        borderRadius: 5,
                                        width: 100,
                                        position: "relative",
                                        backgroundColor: "gray",
                                    }}
                                >
                                    <input
                                        type="color"
                                        id="cardContentColor"
                                        value={colors?.cardContentColor || colorsDefault.cardContentColor}
                                        onChange={(e) => onColorChange("cardContentColor", e.target.value)}
                                        style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                                    />
                                    <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: colors?.cardContentColor || colorsDefault.cardContentColor, boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                                    <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase", color: "#fff" }}>{colors?.cardContentColor || colorsDefault.cardContentColor}</div>
                                </div>
                                {colors?.cardContentColor !== colorsDefault.cardContentColor && (
                                    <span style={{ cursor: "pointer", color: "#fff" }} onClick={() => onColorChange("cardContentColor", colorsDefault.cardContentColor)}>
                                        <FaRotateLeft />
                                    </span>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="showBorder" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.showBorder")}
                    </label>
                    <Form.Check type="switch" id="showBorder" checked={options?.showBorder || false} onChange={(e) => handleWidgetSettingChange("showBorder", e.target.checked)} className="custom-switch" />
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <label htmlFor="clickToRecord" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.clickToRecord")}
                    </label>
                    <Form.Control
                        type="text"
                        id="clickToRecord"
                        style={{ marginLeft: 20 }}
                        value={options?.clickToRecord || ""}
                        onChange={(e) => handleWidgetSettingChange("clickToRecord", e.target.value)}
                        placeholder={t("Record:clickToRecord")}
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <label htmlFor="afterUpload" style={{ color: "#fff" }}>
                        {t("Recorder:widgetConfiguration.afterUpload")}
                    </label>
                    <Form.Control
                        type="text"
                        id="afterUpload"
                        style={{ marginLeft: 20 }}
                        value={options?.afterUpload || ""}
                        onChange={(e) => handleWidgetSettingChange("afterUpload", e.target.value)}
                        placeholder={t("Record:uploadFinished")}
                    />
                </div>
            </section>

            <style>
                {`
                .custom-switch .form-check-input {
                    height: 1.5rem;
                    width: 2.5rem;
                    margin-top: 0.2rem;
                }
                .custom-switch .form-check-input:checked {
                    background-color: #80fed8;
                    border-color: #80fed8;
                }
                .custom-switch .form-check-input:checked:focus {
                    background-color: #80fed8;
                    border-color: #80fed8;
                }
                .custom-switch .form-check-input:focus {
                    border-color: rgba(0, 0, 0, 0.25);
                    box-shadow: none;
                }
                .form-switch .form-check-input:checked::after {
                    background-color: #000;
                    transform: translateX(1rem);
                }
                .form-switch .form-check-input::after {
                    background-color: #fff;
                }
                `}
            </style>
        </div>
    );
};
