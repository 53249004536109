import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";

export const QRCodePopin = (props) => {
    const { t } = useTranslation();
    const { nickname, audioId, isOwnerPicture, isVocasterPicture, mode } = props;
    const [qrCode, setQrCode] = useState(null);
    const [selectedImage, setSelectedImage] = useState(isVocasterPicture ? "vocasters" : isOwnerPicture ? "profile" : "vocast");
    const [copied, setCopied] = useState(false);

    // Set initial selection based on isVocaster and audioId
    useEffect(() => {
        setSelectedImage(isVocasterPicture ? "vocasters" : isOwnerPicture ? "profile" : "vocast");
    }, [audioId, isVocasterPicture, isOwnerPicture]);

    useEffect(() => {
        const currentDomain = window.location.hostname;

        const domain = currentDomain === "localhost" || currentDomain === "127.0.0.1" ? "vocast.twiga.io" : currentDomain;
        // const domain = "127.0.0.1:8080";
        if (!nickname && !audioId) return;

        let url = `${window.location.protocol}//${domain}/${nickname}/`;
        if (audioId) url += `${audioId}/`;
        url += "qrcode";

        if (selectedImage === "vocast") {
            url += "?useVocast=true";
        } else if (selectedImage === "profile") {
            url += "?useOwner=true";
        } else if (selectedImage === "vocasters") {
            url += "?useVocaster=true";
        }

        setQrCode(url);
    }, [nickname, audioId, selectedImage]);

    const handleCopyImage = async () => {
        try {
            if ("ClipboardItem" in window) {
                const response = await fetch(qrCode);
                const blob = await response.blob();
                await navigator.clipboard.write([
                    new window.ClipboardItem({
                        [blob.type]: blob,
                    }),
                ]);
            } else {
                // Fallback: Copy the URL instead
                await navigator.clipboard.writeText(qrCode);
            }
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error("Failed to copy image:", err);
        }
    };

    const handleDownloadImage = async () => {
        try {
            const response = await fetch(qrCode);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `qrcode-${nickname}${audioId ? "-" + audioId : ""}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (err) {
            console.error("Failed to download image:", err);
        }
    };

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered contentClassName="new-modal">
            <Modal.Header>
                <Modal.Title>{t("QRCodePopin:generateQRCode")}</Modal.Title>
                {mode !== "webview" && (
                    <button type="button" className="btn-close" style={{ background: "unset", padding: 0, width: 40, height: 40 }} onClick={props.onHide}>
                        <IoClose style={{ width: 40, height: 40 }} />
                    </button>
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-5">
                        <h5 style={{ marginBottom: "16px" }}>{t("QRCodePopin:centerImage")}</h5>
                        <div className="d-flex flex-column gap-2" style={{ marginLeft: "1.5em" }}>
                            <div className="form-check">
                                <input
                                    type="radio"
                                    id="vocastLogo"
                                    name="imageOption"
                                    className="form-check-input"
                                    value="vocast"
                                    checked={selectedImage === "vocast"}
                                    onChange={(e) => setSelectedImage(e.target.value)}
                                />
                                <label className="form-check-label" htmlFor="vocastLogo">
                                    {t("QRCodePopin:vocastLogo")}
                                </label>
                            </div>
                            <div className="form-check" style={{ opacity: !isOwnerPicture ? 0.75 : 1 }}>
                                <input
                                    type="radio"
                                    id="profilePicture"
                                    name="imageOption"
                                    className="form-check-input"
                                    value="profile"
                                    checked={selectedImage === "profile"}
                                    onChange={(e) => setSelectedImage(e.target.value)}
                                    disabled={!isOwnerPicture}
                                />
                                <label className="form-check-label" htmlFor="profilePicture" style={{ cursor: !isOwnerPicture ? "not-allowed" : "pointer" }}>
                                    {t("QRCodePopin:profilePicture")}
                                </label>
                            </div>
                            {audioId && (
                                <div className="form-check" style={{ opacity: !isVocasterPicture ? 0.75 : 1 }}>
                                    <input
                                        type="radio"
                                        id="vocasters"
                                        name="imageOption"
                                        className="form-check-input"
                                        value="vocasters"
                                        checked={selectedImage === "vocasters"}
                                        onChange={(e) => setSelectedImage(e.target.value)}
                                        disabled={!isVocasterPicture}
                                    />
                                    <label className="form-check-label" htmlFor="vocasters" style={{ cursor: !isVocasterPicture ? "not-allowed" : "pointer" }}>
                                        {t("QRCodePopin:vocasters")}
                                    </label>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-7" style={{ marginTop: "20px" }}>
                        <div className="d-flex flex-column align-items-center gap-3">
                            <img src={qrCode} alt="QR Code" style={{ width: 200, height: 200, marginBottom: "20px" }} />
                            <div className="d-flex gap-2">
                                {mode !== "webview" && (
                                    <button className="btn btn-primary d-flex align-items-center gap-2" onClick={handleCopyImage} disabled={!qrCode}>
                                        {/* <FaCopy style={{ color: "#000" }} size={16} /> */}
                                        {copied ? t("QRCodePopin:copied") : t("QRCodePopin:copyImage")}
                                    </button>
                                )}
                                <button className="btn btn-primary d-flex align-items-center gap-2" onClick={handleDownloadImage} disabled={!qrCode}>
                                    {/* <FaDownload style={{ color: "#000" }} size={16} /> */}
                                    {t("QRCodePopin:downloadImage")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};
