import React from "react";

const defaultUrlBg = "https://storage.googleapis.com/vocast-public/assets/background/default.jpg";
export const Tag = ({ collection, user }) => {
    if (!user || !collection || collection.length === 0) return null;
    return (
        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            {collection.map((coll) => (
                <div
                    key={coll}
                    style={{
                        paddingLeft: 8,
                        paddingRight: 8,
                        paddingTop: 4,
                        paddingBottom: 4,
                        borderRadius: 8,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.5)), url(${user?.collections?.[coll]?.picture || defaultUrlBg})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundPositionX: "left",
                        fontSize: 12,
                    }}
                >
                    {user?.collections?.[coll]?.name || coll}
                </div>
            ))}
        </div>
    );
};
