import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";

import { DropdownItemCustom } from ".";

import { useUserContext } from "../context/UserContext";

import { FuturPopin } from "../components/FuturPopin";
import { IsAuth, IsLarger, IsNotAuth, IsSmaller } from "../components/Responsive";

import VocastLogo from "../Assets/img/logo-white.png";
import { ReactComponent as UserLightIcon } from "../Assets/pictos/user-circle-light.svg";
import { ReactComponent as VocastIcon } from "../Assets/pictos/vocast.svg";

const TopBar = ({ children }) => {
    const { t } = useTranslation();
    const { userData, logout } = useUserContext();
    const [modal, setModal] = useState(null);

    const openModal = (e, modal) => {
        e.preventDefault();
        e.stopPropagation();
        setModal(modal);
    };

    const handleLogout = (e) => {
        e.preventDefault();
        e.stopPropagation();
        logout();
    };

    return (
        <>
            <FuturPopin show={modal} onHide={() => setModal(null)} />
            <IsSmaller width={650}>
                <div
                    style={{
                        backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
                        height: 70,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        zIndex: 2,
                    }}
                >
                    <a href="https://www.vocast.co" target="_blank" rel="noreferrer" style={{ height: "40%", width: "auto" }}>
                        <img src={VocastLogo} style={{ height: "100%", width: "auto" }} alt="Vocast" />
                    </a>
                    <IsAuth>
                        <Dropdown>
                            <Dropdown.Toggle
                                id="dropdown-basic"
                                style={{
                                    background: "none",
                                    border: "none",
                                    padding: 0,
                                }}
                            >
                                {userData?.profilePicture ? (
                                    <img
                                        src={userData.profilePicture}
                                        alt="Profile"
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                        }}
                                    />
                                ) : (
                                    <UserLightIcon height={36} fill="#fff" />
                                )}
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ backgroundColor: "#121212", paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                                <Dropdown.Header>
                                    <div>
                                        <p className="mb-0" style={{ color: "#fff", fontSize: "1.2rem" }}>
                                            {userData?.name}
                                        </p>
                                        <p className="mb-0" style={{ color: "#fff", fontSize: "1rem" }}>
                                            @{userData?.nickname}
                                        </p>
                                    </div>
                                </Dropdown.Header>
                                <NavLink
                                    to={`/new`}
                                    style={{
                                        padding: "10px 15px",
                                        textDecoration: "none",
                                        color: "black",
                                        fontWeight: 600,
                                        backgroundColor: "#80fed8",
                                        borderRadius: 15,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        whiteSpace: "nowrap",
                                        gap: 8,
                                    }}
                                    onClick={(e) => openModal(e, "new")}
                                >
                                    <VocastIcon height={20} />
                                    {t("BO:new_title")}
                                </NavLink>
                                <DropdownItemCustom to={`/feed`} title="BO:feed_title" icon="Home" onClick={(e) => openModal(e, "search")} />
                                <DropdownItemCustom to={`/search`} title="BO:search_title" icon="Search" onClick={(e) => openModal(e, "search")} />
                                <DropdownItemCustom to={`/messages`} title="BO:messages_title" icon="Envelope" onClick={(e) => openModal(e, "messages")} />
                                <DropdownItemCustom to={`/vocasts`} title="BO:posts_title" icon="Microphone" />
                                <DropdownItemCustom to={`/collections`} title="BO:collections_title" icon="Collection" onClick={(e) => openModal(e, "collections")} />
                                <DropdownItemCustom to={`/analytics`} title="BO:analytics_title" icon="ChartArea" onClick={(e) => openModal(e, "analytics")} />
                                <DropdownItemCustom to={`/${userData?.nickname}`} title="seeProfile" icon="User" />
                                <DropdownItemCustom to={`/settings`} title="settings" icon="Settings" onClick={(e) => openModal(e, "settings")} />

                                <DropdownItemCustom to="/logout" title="logout" icon="Logout" onClick={(e) => handleLogout(e)} />
                            </Dropdown.Menu>
                        </Dropdown>
                    </IsAuth>
                    <IsNotAuth>
                        <IsLarger width={360}>
                            <div>
                                <Link to="/login" style={{ padding: "12px 20px 12px 0", color: "#FFFFFF", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14, textDecoration: "none" }}>
                                    {t("Login:login")}
                                </Link>
                                <Link to="/signup" className="btn btn-primary" style={{ padding: "8px 20px", backgroundColor: "#80ffd7", color: "#000", fontWeight: 700, borderRadius: 50, border: 0, fontSize: 14 }}>
                                    {t("Login:register")}
                                </Link>
                            </div>
                        </IsLarger>
                        <IsSmaller width={360}>
                            <Link to="/signup" style={{ padding: "8px", borderRadius: 50, border: 0, fontSize: 14 }}>
                                <UserLightIcon height={36} fill="#fff" />
                            </Link>
                        </IsSmaller>
                    </IsNotAuth>
                </div>
                {children}
            </IsSmaller>
            <IsLarger width={650}>
                <div
                    style={{
                        backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))",
                        height: 70,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: 18,
                    }}
                >
                    {children}
                </div>
            </IsLarger>
        </>
    );
};

export default TopBar;
