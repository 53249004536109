import "../Admin/admin.css";

import React from "react";
import { Button, Row } from "react-bootstrap";
import { HitsPerPage, InstantSearch, Pagination, useHits, useSearchBox } from "react-instantsearch";

import { liteClient as algoliasearch } from "algoliasearch/lite";
import axios from "axios";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import Layout from "../../layout/indexds";

import { Connection, convertTimestampToDate } from "./Utils";

import defaultBg from "../../Assets/img/default-bg.jpg";

const searchClient = algoliasearch("QEZOD7FQY7", "c41c105d487e573ff5541b209eb60e27");

const TableHeader = () => {
    return (
        <div className="table-header">
            <div className="table-cell colo-1"></div>
            <div className="table-cell colo-2">Image</div>
            <div className="table-cell colo-4 ellipsis">Nom</div>
            <div className="table-cell colo-3 ellipsis">Alias</div>
            <div className="table-cell colo-3">Date</div>
            <div className="table-cell colo-2">Vocast</div>
            <div className="table-cell colo-2">Followers</div>
            <div className="table-cell colo-2">Login As</div>
        </div>
    );
};

function Rows({ authenticateAsAnotherUser, ...props }) {
    const { items } = useHits(props);

    return (
        <div>
            {items.map((hit) => (
                <>
                    <div className="table-row" key={hit.objectID}>
                        <div className="table-cell colo-1">
                            <input type="checkbox" />
                        </div>
                        <div className="table-cell colo-2 image-container">
                            <img src={hit.profilePicture || defaultBg} alt={hit.name} className="imageGallery remImg3 image" />
                        </div>
                        <div className="table-cell colo-4 ellipsis">
                            <a
                                style={{
                                    color: "white",
                                    textDecoration: "none ",
                                }}
                                href={`/${hit.name}`}
                            >
                                {hit.name}
                                {/*<FaExternalLinkAlt size={12} style={{ marginLeft: 2 }} />*/}
                            </a>
                        </div>
                        <div className="table-cell colo-3 ellipsis">
                            <a
                                style={{
                                    color: "white",
                                    textDecoration: "none ",
                                }}
                                href={`/${hit.nickname}`}
                            >
                                {hit.nickname}
                                {/*<FaExternalLinkAlt size={12} style={{ marginLeft: 2 }} />*/}
                            </a>
                        </div>
                        <div className="table-cell colo-3">{(hit.formattedDate = convertTimestampToDate(hit.creationDate))}</div>
                        <div className="table-cell colo-2">{hit.vocasts}</div>
                        <div className="table-cell colo-2">{hit.following}</div>
                        <div className="table-cell colo-2">
                            <Button variant="outline-light" onClick={(e) => authenticateAsAnotherUser(hit.objectID)}>
                                Login As
                            </Button>
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
}

function SearchBox() {
    const { query, refine } = useSearchBox();
    return (
        <input
            type="text"
            value={query}
            onChange={(e) => refine(e.target.value)}
            style={{
                borderRadius: 15,
                border: "1px solid black",
                padding: "0.4rem .5rem",
            }}
            placeholder="Chercher"
        />
    );
}

function PageUsers() {
    if (!window.location.host.includes("localhost")) {
        Connection();
    }

    async function authenticateAsAnotherUser(uid) {
        try {
            const url = `${["localhost", "127.0.0.1"].includes(document.location.hostname) ? "http://127.0.0.1:8080" : ""}/auth/authenticateAsUser`;
            const auth = getAuth();

            const response = await axios.post(url, { uid }, { headers: { Authorization: `Bearer ${auth.currentUser.accessToken}` } });
            const { customToken, home } = response.data;

            await signInWithCustomToken(auth, customToken);
            window.location.href = `/${home}`;

            console.log("Authentifié en tant qu'utilisateur:", uid);
        } catch (error) {
            console.error("Erreur lors de l'authentification :", error.message);
        }
    }

    return (
        <Layout>
            <div
                className="flex  gap-4 "
                style={{
                    backgroundColor: "#000",
                    padding: "1rem",
                    borderRadius: "1rem",
                }}
            >
                <InstantSearch searchClient={searchClient} indexName="users">
                    <div className="container">
                        <Row>
                            <div className="col-4">
                                <h1>Users</h1>
                            </div>
                            <div className="col-4 offset-4">
                                <SearchBox />
                            </div>
                        </Row>
                    </div>

                    <div className="table-admin">
                        <div>
                            <TableHeader />
                            {/* <Hits /> */}
                            <Rows authenticateAsAnotherUser={authenticateAsAnotherUser} />
                        </div>
                        <HitsPerPage
                            style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            items={[
                                { label: "8 hits per page", value: 8, default: true },
                                {
                                    label: "12 hits per page",
                                    value: 12,
                                },
                                { label: "16 hits per page", value: 16 },
                                { label: "20 hits per page", value: 20 },
                            ]}
                        />
                        <Pagination
                            totalPages={10}
                            classNames={{
                                list: "pagination-list",
                                item: "pagination-item",
                                selectedItem: "item-selected",
                            }}
                        />
                    </div>
                </InstantSearch>
            </div>
        </Layout>
    );
}
export default PageUsers;
