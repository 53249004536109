import React, { createContext, useContext, useEffect, useState } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, deleteField, doc, onSnapshot, writeBatch } from "firebase/firestore";

import { db } from "../services/firebase";

import { StatsData } from "./GlobalContext";

import logoBlack from "../Assets/img/logo-black.png";
import logoWhite from "../Assets/img/logo-white.png";
import MicroLogo from "../Assets/img/micro-vocast.png";

const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const url = new URL(window.location.href);
    const dark = url.searchParams.get("dark") === "true";

    const [user, setUser] = useState(null);
    const [isAuthReady, setIsAuthReady] = useState(false);
    const [userData, setUserData] = useState(null);

    const follow = async (userId) => {
        if (!userId) return;
        const batch = writeBatch(db);
        const currentUserDocRef = doc(db, "users", user.uid);
        const followUserDocRef = doc(db, "users", userId);
        batch.update(currentUserDocRef, { [`following.${userId}`]: { user: followUserDocRef, date: new Date() } });
        batch.update(followUserDocRef, { [`followers.${user.uid}`]: { user: currentUserDocRef, date: new Date() } });
        await batch.commit();
    };

    const unfollow = async (userId) => {
        if (!userId) return;
        const batch = writeBatch(db);
        const currentUserDocRef = doc(db, "users", user.uid);
        const followUserDocRef = doc(db, "users", userId);
        batch.update(currentUserDocRef, { [`following.${userId}`]: deleteField() });
        batch.update(followUserDocRef, { [`followers.${user.uid}`]: deleteField() });
        await batch.commit();
    };

    const toggleFollow = async (userId) => {
        if (!userId) return;
        if (userData?.following?.[userId]) return await unfollow(userId);
        return await follow(userId);
    };

    const isFollowing = (userId) => {
        if (!userId) return false;
        if (!userData) return false;
        return userData?.following?.[userId] ? true : false;
    };

    useEffect(() => {
        if (!user) return;
        const unsubscribe = onSnapshot(doc(collection(db, "users"), user.uid), (doc) => {
            if (!doc.exists()) return;
            setUserData(doc.data());
        });
        return () => unsubscribe();
    }, [user]);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (_user) => {
            setIsAuthReady(true);
            if (_user) {
                setUser(_user);
                StatsData.user = _user.uid;
                return;
            }
            setUser(null);
            setUserData(null);
            StatsData.user = null;
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const auth = getAuth();
    }, []);

    const logout = () => {
        getAuth().signOut();
        localStorage.removeItem("user");
    };

    if (!isAuthReady)
        return (
            <div className="loader-container" style={{ backgroundColor: "var(--bg-color, #000)" }}>
                {window.location.pathname.includes("/embed/") ? (
                    <img src={dark ? logoWhite : logoBlack} alt="Micro Logo" style={{ height: "40px", width: "auto" }} />
                ) : (
                    <div className="loader">
                        <img src={MicroLogo} alt="Micro Logo" style={{ width: "100%", height: "auto" }} />
                    </div>
                )}
            </div>
        );
    return <UserContext.Provider value={{ user, userData, logout, follow, unfollow, toggleFollow, isFollowing }}>{children}</UserContext.Provider>;
};
