import React, { useEffect, useState } from "react";
import { Alert, Button, Container, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { collection, doc, getDocs, query, setDoc, where } from "firebase/firestore";

import { auth, db } from "../../../services/firebase";

import logo from "../../../Assets/img/logo-white.png";

import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";

export const nicknameFormat = (_) =>
    _.toLowerCase()
        .replace(/\s+/g, "_")
        .trim()
        .replace(/[^a-zA-Z0-9_]/g, "")
        .substring(0, 30);

export default function PseudoUsername({ next }) {
    const { t } = useTranslation();

    const [name, setName] = useState("");
    const [nickname, setNickname] = useState("");
    const [nicknameError, setNicknameError] = useState("");

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const setNicknameFormat = (_nickname) => {
        setNickname(nicknameFormat(_nickname));
    };

    useEffect(() => {
        const checkNickname = async () => {
            if (!nickname.length) return;
            if (nickname.length < 3) {
                setNicknameError(t("CompleteProfile:step1:nicknameMinLength"));
                return;
            }

            if (nickname.length > 30) {
                setNicknameError(t("CompleteProfile:step1:nicknameMaxLength"));
                return;
            }

            const userDoc = await getDocs(query(collection(db, "users"), where("nickname", "==", nickname)));
            if (userDoc.size && userDoc.docs[0].id !== auth.currentUser.uid) {
                setNicknameError(t("CompleteProfile:step1:nicknameAlreadyExists"));
            } else {
                setNicknameError("");
            }
        };
        checkNickname();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nickname]);

    const handleFinalize = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        if (!name || !nickname) {
            setError(t("CompleteProfile:step1:fillAllFields"));
            setLoading(false);
            return;
        }

        if (3 > nickname.length) {
            setError(t("CompleteProfile:step1:nicknameMinLength"));
            setLoading(false);
            return;
        }

        if (nickname.length > 30) {
            setError(t("CompleteProfile:step1:nicknameMaxLength"));
            setLoading(false);
            return;
        }

        const userDoc = await getDocs(query(collection(db, "users"), where("nickname", "==", nickname)));
        if (userDoc.size && userDoc.docs[0].id !== auth.currentUser.uid) {
            setError(t("CompleteProfile:step1:nicknameAlreadyExists"));
            setLoading(false);
            return;
        }

        try {
            const userData = {
                name: name.trim(),
                nickname,
            };
            await setDoc(doc(collection(db, "users"), auth.currentUser.uid), userData, { merge: true });
            next();
        } catch (error) {
            setError(t("CompleteProfile:step1:registerError"));
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://www.vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>

            <div className="text-center" style={{ padding: "24px 16px" }}>
                <h2 style={{ fontSize: 32, color: "#fff" }}>
                    <b>{t("CompleteProfile:step1:title")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3" }}>{t("CompleteProfile:step1:subtitle")}</p>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="w-100" onSubmit={handleFinalize}>
                <Form.Group controlId="formName" className="mb-3">
                    <Form.Control
                        type="text"
                        placeholder={`${t("CompleteProfile:step1:name")}*`}
                        value={name}
                        onChange={(e) => setName(e.target.value.substring(0, 30))}
                        required
                        style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                    />
                </Form.Group>

                <Form.Group controlId="formNickname" className="mb-3">
                    <InputGroup>
                        <InputGroup.Text style={{ backgroundColor: "#f8f9fa", border: "none", fontSize: 16, fontWeight: 600, color: "#000" }}>vocast.co/</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder={`${t("CompleteProfile:step1:nickname")}*`}
                            value={nickname}
                            onChange={(e) => setNicknameFormat(e.target.value)}
                            required
                            style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                        />
                        {nicknameError && (
                            <InputGroup.Text>
                                <FaRegCircleXmark style={{ color: "red" }} size={18} />
                            </InputGroup.Text>
                        )}
                        {nickname.length > 0 && !nicknameError && (
                            <InputGroup.Text>
                                <FaRegCircleCheck style={{ color: "green" }} size={18} />
                            </InputGroup.Text>
                        )}
                    </InputGroup>
                    {nicknameError && <Form.Text className="text-danger">{nicknameError}</Form.Text>}
                </Form.Group>

                <div className="d-flex gap-2 w-100 justify-content-center">
                    <Button
                        variant="success"
                        type="submit"
                        className="w-50 mb-3"
                        style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }}
                        disabled={loading || nicknameError || !nickname.length || !name.length}
                    >
                        {loading ? t("CompleteProfile:step1:nextProcess") : t("CompleteProfile:step1:next")}
                    </Button>
                </div>
            </Form>
        </Container>
    );
}
