export const RoundButton = (props) => {
    return (
        <button className="rounded-circle" onClick={props.onClick} style={{
            width: '40px',
            height: '40px',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
            ...props.style
        }}>
            {props.children}
        </button>
    )
}