import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";

import { db } from "../services/firebase";

import { QRCodePopin } from "../components/QRCodePopin";

export const QRCode = () => {
    const { audioId } = useParams();
    const [modalQRCode, setModalQRCode] = useState(false);
    const [user, setUser] = useState(null);
    const [audio, setAudio] = useState(null);
    const [isVocasterPicture, setIsVocasterPicture] = useState(false);

    useEffect(() => {
        const audioRef = doc(db, "audio", audioId);
        const audioDoc = getDoc(audioRef);

        audioDoc
            .then(async (docRef) => {
                if (docRef.exists()) {
                    const audio = docRef.data();
                    audio.id = docRef.id;

                    const userDoc = await getDoc(audio.owner);
                    const user = userDoc.data();
                    user.id = userDoc.id;

                    const tmpIVocasterPicture = (audio?.contributor?.status === "enabled" && audio?.vocaster?.profilePicture) || (user?.vocasters?.[audio?.vocaster] && user?.vocasters?.[audio?.vocaster]?.profilePicture);

                    setUser(user);
                    setAudio(audio);
                    setIsVocasterPicture(tmpIVocasterPicture);
                    setModalQRCode(true);
                }
            }
        );
    }, [audioId]);

    return <div style={{
        backgroundColor: "#000",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }}>
        <QRCodePopin mode="webview" show={modalQRCode} onHide={() => setModalQRCode(false)} nickname={user?.nickname} audioId={audio?.id} isOwnerPicture={!!user?.profilePicture} isVocasterPicture={isVocasterPicture} />
    </div>
};