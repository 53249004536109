import { useContext, useEffect, useState } from "react";

import { interpolateSpline } from "../utils/audio";

import { GlobalContext } from "../context/GlobalContext";

export default function WaveFormPlayer({ audio, audioPlayer, handlePlay, color = "#fff" } = {}) {
    const { audioSource } = useContext(GlobalContext);
    const [unique] = useState(Math.random().toString());
    const [audioPercentage, setAudioPercentage] = useState(1);
    const [audioPl, setAudioPl] = useState(null);
    const [waveWidth, setWaveWidth] = useState(0);
    const [obs, setObs] = useState(null);
    // const [swipe, setSwipe] = useState(null);

    useEffect(() => {
        function callback() {
            if (!audioPl) return;
            let percentage = audioPl.currentTime / audioPl.duration;
            setAudioPercentage(percentage);
        }

        if (!audioPl || !audioSource?.audio?.id) return;
        if (audioSource?.audio?.id !== audio?.id) return;
        audioPl?.addEventListener("timeupdate", callback);
        return () => audioPl?.removeEventListener("timeupdate", callback);

        // eslint-disable-next-line
    }, [audio?.url, audioPl?.paused]);

    useEffect(() => {
        setAudioPl(audioPlayer?.current ?? document.querySelector("audio"));
    }, [audioPlayer]);

    useEffect(() => {
        if (!audio) return;
        const svg = document.getElementById(`waveform-${audio.id}-${unique}`);
        if (!obs && svg) setObs(new ResizeObserver((entries) => setWaveWidth(entries[0].contentRect.width)).observe(svg.parentElement));
        return () => obs?.disconnect();
        // eslint-disable-next-line
    }, [audio]);

    useEffect(() => {
        if (!audio && !audio?.waveform?.length) return;
        const svg = document.getElementById(`waveform-${audio.id}-${unique}`);
        const bars = document.getElementById(`waveform-${audio.id}-${unique}-bars`);
        if (!svg || !bars) return;
        const height = 25,
            width = svg.parentElement.clientWidth;
        svg.setAttribute("width", width);

        let _waveformData = audio.waveform || [],
            barWidth = Math.max(Math.min((width / _waveformData.length) * 0.6, 2.5), 1),
            x = 0,
            max = Math.max(..._waveformData);

        const _waveWidth = _waveformData.length * (barWidth * 3);
        if (_waveWidth < width) {
            let barsToAdd = Math.floor((width - _waveWidth) / (barWidth * 3));
            const newSize = (_waveformData.length + barsToAdd) * (barWidth * 3);
            if (newSize > width) barsToAdd -= 1;
            _waveformData = interpolateSpline(_waveformData, barsToAdd + _waveformData.length);
            max = Math.max(..._waveformData);
            _waveformData = _waveformData.map((v) => (v / max) * (height - barWidth) + barWidth);
        }

        bars.innerHTML = "";
        for (let i = 0; i < _waveformData.length; i++) {
            let y = (height - _waveformData[i]) / 2,
                radius = barWidth / 2,
                rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", x);
            rect.setAttribute("y", y);
            rect.setAttribute("width", barWidth);
            rect.setAttribute("height", Math.abs(_waveformData[i]));
            rect.setAttribute("rx", radius);
            bars.appendChild(rect);
            x += barWidth * 3;
            if (x >= width) break;
        }
        // eslint-disable-next-line
    }, [audio, waveWidth]);

    if (!audio) return <div style={{ height: 25 }} />;

    return (
        <div style={{ height: 25 }}>
            {/* 
                onMouseMove={(e) => {
                    if (e.nativeEvent.buttons !== 1) return;
                    if (audioSource?.audio?.id !== audio?.id) return;
                    setSwipe(Math.round((e.nativeEvent.offsetX / waveWidth) * 100) / 100);
                }}
                onMouseUp={async (e) => {
                    setAudioPercentage(swipe);
                    setSwipe(null);
                    const percentage = Math.round((e.nativeEvent.offsetX / waveWidth) * 100) / 100;
                    if (audioSource?.audio?.id !== audio?.id) {
                        console.log("handlePlay", audio, percentage);
                        await handlePlay(audio, { percentage });
                        return;
                    }
                    audioPl.currentTime = Math.round(audioPl.duration * percentage * 100) / 100;
                }}
            /> */}
            <svg id={`waveform-${audio.id}-${unique}`} style={{ cursor: "pointer", height: 25 }}>
                <defs>
                    <mask id={`waveform-${audio.id}-${unique}-mask`}>
                        <rect width="100%" height="100%" fill="#7b7b7b" />
                        <rect width={`${audioPercentage * 100}%`} height="100%" fill="white" />
                    </mask>
                </defs>
                <g id={`waveform-${audio.id}-${unique}-bars`} mask={`url(#waveform-${audio.id}-${unique}-mask)`} fill={color} />
            </svg>
        </div>
    );
}
