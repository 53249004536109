import React from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import RecordEmbed from "../../Integration/RecordEmbed";

import logo from "../../../Assets/img/logo-white.png";

const ProgressBar = ({ progress }) => {
    return (
        <div style={{ height: 12, backgroundColor: "#f3f3f3", borderRadius: 10, width: "100%", overflow: "hidden" }}>
            <div className="progress" style={{ width: `${progress}%`, height: 12, backgroundColor: "#80ffd7", borderRadius: 10 }} />
        </div>
    );
};

export default function FirstVocast({ user, next }) {
    const { t } = useTranslation();

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://www.vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>

            <div className="text-center" style={{ padding: "24px 16px" }}>
                <h2 style={{ fontSize: 32, color: "#fff" }}>
                    <b>{t("CompleteProfile:step3:title")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3" }}>{t("CompleteProfile:step3:subtitle")}</p>
            </div>

            <div style={{ maxWidth: 600, marginBottom: 24, width: "100%" }}>
                <RecordEmbed
                    params={window.btoa(
                        JSON.stringify({
                            id: 10,
                            visibility: "public",
                            status: "published",
                            showProfile: true,
                            background: "vocast",
                            showBorder: true,
                            inputContributorPicture: false,
                            userId: user.uid,
                            title: "Mon premier vocast",
                            titleMode: "input",
                            desc: "Description",
                            descMode: "input",
                            height: "332px",
                            externalId: "first-vocast",
                        }),
                    )}
                    inApp
                />
            </div>
            <div className="d-flex gap-2 w-100 justify-content-center">
                <Button variant="success" type="button" className="w-50 mb-3" style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }} onClick={next}>
                    {t("CompleteProfile:step3:skip")}
                </Button>
            </div>
        </Container>
    );
}
