import React from "react";
import { useTranslation } from "react-i18next";

const PlayerMini = ({ outer = "#F7FDFC", inside = "#000000", style }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M56 28C56 50.12 48.16 56 28 56C7.84 56 0 50.4 0 28C0 5.6 7.84 0 28 0C48.16 0 56 5.88 56 28Z" fill={outer} />
            <path
                d="M24.7215 18.5469C22.7333 17.1537 20 18.576 20 21.0038V34.9965C20 37.4243 22.7334 38.8466 24.7216 37.4534L34.7297 30.4406C36.4229 29.2541 36.4229 26.746 34.7297 25.5595L24.7215 18.5469Z"
                fill={inside}
            />
        </svg>
    );
};

const PauseMini = ({ outer = "#F7FDFC", inside = "#000000", style }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M56 28C56 50.12 48.16 56 28 56C7.84 56 0 50.4 0 28C0 5.6 7.84 0 28 0C48.16 0 56 5.88 56 28Z" fill={outer} />
            <path
                d="M19.218 20.092C19 20.5198 19 21.0799 19 22.2V33.8C19 34.9201 19 35.4802 19.218 35.908C19.4097 36.2843 19.7157 36.5903 20.092 36.782C20.5198 37 21.0799 37 22.2 37H22.8C23.9201 37 24.4802 37 24.908 36.782C25.2843 36.5903 25.5903 36.2843 25.782 35.908C26 35.4802 26 34.9201 26 33.8V22.2C26 21.0799 26 20.5198 25.782 20.092C25.5903 19.7157 25.2843 19.4097 24.908 19.218C24.4802 19 23.9201 19 22.8 19H22.2C21.0799 19 20.5198 19 20.092 19.218C19.7157 19.4097 19.4097 19.7157 19.218 20.092Z"
                fill={inside}
            />
            <path
                d="M30.218 20.092C30 20.5198 30 21.0799 30 22.2V33.8C30 34.9201 30 35.4802 30.218 35.908C30.4097 36.2843 30.7157 36.5903 31.092 36.782C31.5198 37 32.0799 37 33.2 37H33.8C34.9201 37 35.4802 37 35.908 36.782C36.2843 36.5903 36.5903 36.2843 36.782 35.908C37 35.4802 37 34.9201 37 33.8V22.2C37 21.0799 37 20.5198 36.782 20.092C36.5903 19.7157 36.2843 19.4097 35.908 19.218C35.4802 19 34.9201 19 33.8 19H33.2C32.0799 19 31.5198 19 31.092 19.218C30.7157 19.4097 30.4097 19.7157 30.218 20.092Z"
                fill={inside}
            />
        </svg>
    );
};

const StopMini = ({ outer = "#F7FDFC", inside = "#000000", style }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M56 28C56 50.12 48.16 56 28 56C7.84 56 0 50.4 0 28C0 5.6 7.84 0 28 0C48.16 0 56 5.88 56 28Z" fill={outer} />
            <rect x="19" y="19" width="18" height="18" rx="2" fill={inside} />
        </svg>
    );
};

const RecordMini = ({ outer = "#FF2E2E", inside = "#F7FDFC", style }) => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <path d="M80 40C80 71.6 68.8 80 40 80C11.2 80 0 72 0 40C0 8 11.2 0 40 0C68.8 0 80 8.4 80 40Z" fill={outer} />
            <path
                d="M31 38.5C31 37.6716 30.3284 37 29.5 37C28.6716 37 28 37.6716 28 38.5C28 44.6194 32.5805 49.669 38.5 50.4072V53.5C38.5 54.3284 39.1716 55 40 55C40.8284 55 41.5 54.3284 41.5 53.5V50.4072C47.4195 49.669 52 44.6194 52 38.5C52 37.6716 51.3284 37 50.5 37C49.6716 37 49 37.6716 49 38.5C49 43.4706 44.9706 47.5 40 47.5C35.0294 47.5 31 43.4706 31 38.5Z"
                fill={inside}
            />
            <path d="M34 31C34 27.6863 36.6863 25 40 25C43.3137 25 46 27.6863 46 31V38.5C46 41.8137 43.3137 44.5 40 44.5C36.6863 44.5 34 41.8137 34 38.5V31Z" fill={inside} />
        </svg>
    );
};

const IconButton = ({ icon, onClick, style, disabled = false }) => {
    return (
        <div style={{ ...style, cursor: disabled ? "default" : "pointer", opacity: disabled ? 0.5 : 1 }} onClick={disabled ? null : onClick}>
            {icon}
        </div>
    );
};

const Tip = ({ children, active, position = "center", style, inside, outer, text }) => {
    const { t } = useTranslation();

    return (
        <div className={`tip ${active ? "active" : ""} ${position}`} style={{ ...style }}>
            <p style={{ "--color": inside, "--color-outer": outer }}>{text}</p>
            {children}
        </div>
    );
};

export { IconButton, PlayerMini, PauseMini, Tip, RecordMini, StopMini };
