import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Row, Col, Nav } from "react-bootstrap";

import Published from "../components/Home/Published";
import Drafts from "../components/Home/Drafts";
import Inbox from "../components/Home/Inbox";
import { SearchField } from "../components/Home/parts/SearchField";

import { BaseLayout } from "../layout/BaseLayout";
import { useUserContext } from "../context/UserContext";

const Vocasts = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState("vocasts");
    const { user } = useUserContext();
    const [ search, setSearch ] = useState("");

    const tabs = [
        { key: "vocasts", label: t('home:published'), title: t('home:published'), component: Published },
        { key: "drafts", label: t('home:drafts'), title: t('home:drafts'), component: Drafts },
        { key: "inbox", label: t('home:inbox'), title: t('home:inbox'), component: Inbox },
    ];

    useEffect(() => {
        if(!user) navigate("/login");
    }, [user, navigate]);

    if(!user) return null;
    return (
        <BaseLayout>
            <h1 style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
            }}>{t('BO:posts_title')}</h1>
            <Nav className="homeTabs" variant="tabs" defaultActiveKey={selectedTab} onSelect={(key) => setSelectedTab(key)}>
                {tabs.map(tab => (
                    <Nav.Item key={tab.key}>
                        <Nav.Link eventKey={tab.key}>{tab.label}</Nav.Link>
                    </Nav.Item>
                ))}
            </Nav>
            <Row>
                <Col xs={12} className="mt-4">
                    <SearchField search={search} setSearch={setSearch} />
                </Col>
            </Row>
            {tabs.map(tab => {
                if (tab.key !== selectedTab) return null;
                return <Row key={tab.key}>
                    <Col>
                        {tab.component && <tab.component search={search} />}
                    </Col>
                </Row>
            })}
        </BaseLayout>
    );
}

export default Vocasts;