import { useContext, useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, Dropdown, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { doc, getDoc, updateDoc } from "firebase/firestore";
import moment from "moment";

import { GlobalContext } from "../../context/GlobalContext";

import { db } from "../../services/firebase";
import { event_page_vocast, event_vocast_listen } from "../../services/stats";

import { IsLarger } from "../../components/Responsive";
import TextSlide from "../../components/TextSlide";
import WaveFormPlayer from "../../components/WaveFormPlayer";

import logoBlack from "../../Assets/img/logo-black.png";
import logoWhite from "../../Assets/img/logo-white.png";
import { ReactComponent as Comment } from "../../Assets/pictos/comment.svg";
import { ReactComponent as Headphones } from "../../Assets/pictos/headphones.svg";
import { ReactComponent as Heart } from "../../Assets/pictos/heart.svg";

import { FaTimes } from "react-icons/fa";

const PlayerMini = ({ outer = "#F7FDFC", inside = "#000000" }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56 28C56 50.12 48.16 56 28 56C7.84 56 0 50.4 0 28C0 5.6 7.84 0 28 0C48.16 0 56 5.88 56 28Z" fill={outer} />
            <path
                d="M24.7215 18.5469C22.7333 17.1537 20 18.576 20 21.0038V34.9965C20 37.4243 22.7334 38.8466 24.7216 37.4534L34.7297 30.4406C36.4229 29.2541 36.4229 26.746 34.7297 25.5595L24.7215 18.5469Z"
                fill={inside}
            />
        </svg>
    );
};

const PauseMini = ({ outer = "#F7FDFC", inside = "#000000" }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M56 28C56 50.12 48.16 56 28 56C7.84 56 0 50.4 0 28C0 5.6 7.84 0 28 0C48.16 0 56 5.88 56 28Z" fill={outer} />
            <path
                d="M19.218 20.092C19 20.5198 19 21.0799 19 22.2V33.8C19 34.9201 19 35.4802 19.218 35.908C19.4097 36.2843 19.7157 36.5903 20.092 36.782C20.5198 37 21.0799 37 22.2 37H22.8C23.9201 37 24.4802 37 24.908 36.782C25.2843 36.5903 25.5903 36.2843 25.782 35.908C26 35.4802 26 34.9201 26 33.8V22.2C26 21.0799 26 20.5198 25.782 20.092C25.5903 19.7157 25.2843 19.4097 24.908 19.218C24.4802 19 23.9201 19 22.8 19H22.2C21.0799 19 20.5198 19 20.092 19.218C19.7157 19.4097 19.4097 19.7157 19.218 20.092Z"
                fill={inside}
            />
            <path
                d="M30.218 20.092C30 20.5198 30 21.0799 30 22.2V33.8C30 34.9201 30 35.4802 30.218 35.908C30.4097 36.2843 30.7157 36.5903 31.092 36.782C31.5198 37 32.0799 37 33.2 37H33.8C34.9201 37 35.4802 37 35.908 36.782C36.2843 36.5903 36.5903 36.2843 36.782 35.908C37 35.4802 37 34.9201 37 33.8V22.2C37 21.0799 37 20.5198 36.782 20.092C36.5903 19.7157 36.2843 19.4097 35.908 19.218C35.4802 19 34.9201 19 33.8 19H33.2C32.0799 19 31.5198 19 31.092 19.218C30.7157 19.4097 30.4097 19.7157 30.218 20.092Z"
                fill={inside}
            />
        </svg>
    );
};

export const EMBED_DEFAULT_OPTIONS = {
    showProfile: true,
    showTitle: true,
    showDescription: true,
    showVocaster: true,
    showFlag: true,
    showIcons: true,
    background: "vocast",
    showBorder: true,
    showLogo: true,
    showRate: true,
};

const convertOldParams = (urlSource) => {
    let embedConfig = { background: "vocast" };
    let oldParams = {
        noProfile: "showProfile",
        noTitle: "showTitle",
        noDesc: "showDescription",
        noIcons: "showIcons",
        noLogo: "showLogo",
        noFlag: "showFlag",
        noRate: "showRate",
        noBorder: "showBorder",
        noVocaster: "showVocaster",
        height: "height",
    };
    let specialParams = { bgColor: null, textColor: null };
    urlSource.searchParams.forEach((value, key) => {
        if (!value && !key) return;
        if (oldParams[key]) embedConfig[oldParams[key]] = false;
        else if (specialParams.hasOwnProperty(key)) specialParams[key] = value;
        else if (key === "noImage") embedConfig.background = "custom";
        else embedConfig[key] = value;
    });

    if (embedConfig.background === "custom") {
        embedConfig.colors = {
            bgColor: specialParams.bgColor || "#B2B2B2",
            textColor: specialParams.textColor || "#FFFFFF",
            cardColor: "#333333cc",
            cardContentColor: specialParams.textColor || "#FFFFFF",
        };
        if (!embedConfig.showProfile && !embedConfig.showIcons && !embedConfig.showLogo) {
            embedConfig.colors.cardColor = specialParams.bgColor;
        }
    }

    return embedConfig;
};

export default function AudioEmbed({ design, inApp = false, ref }) {
    const { i18n, t } = useTranslation();
    const { setAudioSource, audioSource, audioStatus, audioPlayer, setAudioStatus } = useContext(GlobalContext);
    const { audioId: paramsAudioId } = useParams();
    const audioId = inApp !== false ? inApp : paramsAudioId;

    const [searchParams] = useSearchParams();
    // const [loading, setLoading] = useState(true);
    const [embedConfig, setEmbedConfig] = useState({ ...EMBED_DEFAULT_OPTIONS, ...JSON.parse(window.atob(design || searchParams.get("design") || "e30=")) });

    const [audio, setAudio] = useState(null);
    const [originalAudioUrl, setOriginalAudioUrl] = useState(null);
    const [picture, setPicture] = useState("https://firebasestorage.googleapis.com/v0/b/boosty-app.appspot.com/o/assets%2Fimg%2Fdefault-bg.jpg?alt=media&token=ff27c7d6-8dbe-4cbc-8135-13d9fd38893e");
    const [error, setError] = useState(false);
    const [listners, setListners] = useState(0);
    const [languages] = useState(["de", "en", "es", "fr", "it"]);
    const [language, setLanguage] = useState(languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");
    const [duration, setDuration] = useState(0);
    const [user, setUser] = useState(null);
    const [currentRate, setCurrentRate] = useState(1);
    const [showNickname, setShowNickname] = useState(true);
    const audioPlayerRef = useRef(null);
    const nameRef = useRef(null);

    const vocastUrl = {
        home: "https://www.vocast.co",
        profile: `https://www.vocast.co/${user?.nickname}`,
        audio: `https://www.vocast.co/${user?.nickname}/${audioId}`,
    };
    if (!inApp) document.body.style.backgroundColor = "transparent";

    const getAlphafloat = (a, alpha) => {
        if (typeof a !== "undefined") return a / 255;
        if (typeof alpha != "number" || alpha < 0 || alpha > 1) return 1;
        return alpha;
    };

    const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, "g"));

    const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);

    const hexToRGBA = (hex, alpha) => {
        const chunkSize = Math.floor((hex.length - 1) / 3);
        const hexArr = getChunksFromString(hex.slice(1), chunkSize);
        const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
        return { r, g, b, a: getAlphafloat(a, alpha) };
    };

    const isDarkColor = (hex) => {
        const { r, g, b } = hexToRGBA(hex) || { r: 0, g: 0, b: 0 };
        return Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b)) <= 127.5;
    };

    const decodeValue = (value) => {
        if (value === "true") return true;
        if (value === "false") return false;
        return value;
    };

    useEffect(() => {
        if (!audioSource?.audio?.id || !!inApp) return;
        let contributorId = audio?.contributor?.status === "enabled" ? audio?.contributor?.id : null;
        let vocasterId = user?.vocasters?.[audio?.vocaster];

        event_vocast_listen({
            audioId: audio.id,
            ownerId: user.id,
            contributorId: contributorId || vocasterId,
            audioLanguage: language || audio.locale,
            listener: "anonymous",
            environment: "iframe",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioSource]);

    useEffect(() => {
        let override = {};
        if (design || searchParams.get("design")) {
            const base = JSON.parse(window.atob(design || searchParams.get("design") || "e30="));
            override = { ...EMBED_DEFAULT_OPTIONS, ...base };
            searchParams.forEach((value, key) => {
                if (key === "design") return;
                override[key] = decodeValue(value);
            });
        } else {
            override = { ...EMBED_DEFAULT_OPTIONS, ...convertOldParams(new URL(window.location.href)) };
        }
        override.isReduced = !override.showProfile && !override.showIcons && !override.showLogo && override.background === "custom" && !override.showTitle && !override.showDescription;
        override.logoDark = isDarkColor(override.colors?.bgColor || "#000000");
        setEmbedConfig(override);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [design, searchParams]);

    useEffect(() => {
        if (!audioPlayer.current) return;
        if (audioSource?.audio?.id !== audio?.id) return;
        if (audioPlayer.current.playbackRate !== currentRate) audioPlayer.current.playbackRate = currentRate;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioPlayer, audioSource, audio, currentRate]);

    useEffect(() => {
        const audioRef = doc(db, "audio", audioId);
        const audioDoc = getDoc(audioRef);
        audioDoc
            .then(async (docRef) => {
                if (docRef.exists()) {
                    let audioData = docRef.data();
                    audioData.id = docRef.id;

                    // Event page audio
                    if (inApp) {
                        event_page_vocast({ vocastId: audioData.id, ownerId: audioData.ownerId, referrer: searchParams.get("referrer"), environment: "iframe" });
                    }

                    if (audioData.pictures && audioData.pictures.length > 0) setPicture(audioData.pictures[0]);
                    setAudio({ locale: "fr", ...audioData });
                    // setOriginalAudioUrl(await getDownloadURL(ref(storage, audioData.audio)));
                    setOriginalAudioUrl(audioData.url);
                    setDuration(audioData.duration);
                    setLanguage(languages.find((lng) => lng === i18n.language?.split("-").at(0)?.toLowerCase()) || "fr");
                    if (!audioData.description || audioData.description === "") setEmbedConfig({ ...embedConfig, showDescription: false });
                    // const userDoc = audioData.owner.get();
                    const userDoc = getDoc(audioData.owner);
                    userDoc
                        .then((docRef) => {
                            if (docRef.exists()) {
                                let userData = docRef.data();
                                userData.id = docRef.id;

                                setUser(userData);
                            }
                        })
                        .catch((error) => {
                            console.log("Error getting document:", error);
                        });
                } else {
                    console.log("No such document!");
                    setError(true);
                }
            })
            .catch((error) => {
                console.log("Error getting document:", error);
                setError(true);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioId]);

    useEffect(() => {
        // If audio is playing, get current time
        if (audio && audioStatus === "play" && audioPlayer?.current) {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            audioPlayerRef.current = setInterval(() => {
                setDuration((audio.duration || audioPlayer.current.duration * 1000) - audioPlayer.current.currentTime * 1000);
            }, 1000);
        } else {
            if (audioPlayerRef.current) clearInterval(audioPlayerRef.current);
            setDuration(audio ? audio.duration : 0);
        }

        if (audio && audio.listners) {
            const listnersCounter = Object.keys(audio.listners).map((key) => {
                if (Array.isArray(audio.listners[key])) {
                    return audio.listners[key].length;
                }
                return 1;
            });
            setListners(listnersCounter.reduce((a, b) => a + b, 0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioStatus, audio]);

    useEffect(() => {
        if (audioPlayer.current.isLocalyListened !== true && audio && audioPlayer?.current?.currentTime && audioPlayer.current.currentTime > 0) {
            audioPlayer.current.isLocalyListened = true;
            let listnersObject = {};
            // Clone audio variable
            let tmpAudio = JSON.parse(JSON.stringify(audio));
            if (audio && audio.listners) {
                listnersObject["listners.anonymous"] = audio.listners.anonymous ? [...audio.listners.anonymous, new Date()] : [new Date()];
                tmpAudio.listners.anonymous = audio.listners.anonymous ? [...audio.listners.anonymous, new Date()] : [new Date()];
            } else {
                listnersObject["listners.anonymous"] = [new Date()];
                tmpAudio.listners = {
                    anonymous: [new Date()],
                };
            }

            const audioRef = doc(db, "audio", audioId);
            setAudio(tmpAudio);
            updateDoc(audioRef, listnersObject);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [duration]);

    const handlePlay = async (audio, { percent = 0, forceLocale = false } = {}) => {
        const enUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${audioId}/${language}`;

        if (audioStatus === "stop") {
            // const storageRef = ref(storage, audio.audio);
            // const audioFile = await getDownloadURL(storageRef);
            const audioFile = audio.url;
            setAudioSource({ url: language === audio.locale ? audioFile : enUrl, audio, percent });
        } else if (audioStatus === "play" && audioSource && audioSource.audio && audioSource.audio.id === audio.id) {
            audioPlayer.current.pause();
            setAudioStatus("pause");
        } else if (audioStatus === "pause" && audioSource && audioSource.audio && audioSource.audio.id === audio.id) {
            audioPlayer.current.play();
            setAudioStatus("play");
        } else if (audioSource && audioSource.audio && audioSource.audio.id !== audio.id) {
            // const storageRef = ref(storage, audio.audio);
            // const audioFile = await getDownloadURL(storageRef);
            const audioFile = audio.url;
            setAudioSource({ url: language === audio.locale ? audioFile : enUrl, audio, percent });
        }
    };

    useEffect(() => {
        const enUrl = `https://us-central1-vocast-app.cloudfunctions.net/videoapp/ai/translate/${audioId}/${language}`;
        // Check if player is playing
        if (audioStatus === "play" && audioSource && audioSource.audio && audioSource?.audio.id && audioSource?.audio.id === audio?.id) {
            if (language === audio.locale) {
                setAudioSource({ url: originalAudioUrl, audio: audio });
            } else {
                setAudioSource({ url: enUrl, audio: audio });
            }
        } else if (audioStatus === "pause" && originalAudioUrl) {
            // Stop player and change audio source
            audioPlayer.current.pause();
            audioPlayer.current.currentTime = 0;
            if (language === audio.locale) {
                setAudioSource({ url: originalAudioUrl, audio: audio });
            } else {
                setAudioSource({ url: enUrl, audio: audio });
            }
            // audioPlayer.current.play();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    const canResize = () => {
        return audio && user && !error;
    };

    useEffect(() => {
        if (!canResize()) return;
        const resizeObserver = new ResizeObserver((entries) => {
            window.parent.postMessage({ type: `resize-vocast${searchParams.get("linkId") || ""}`, height: entries[0].contentRect.height + (embedConfig.showBorder ? 10 : 0) }, "*");
        });
        resizeObserver.observe(document.body);

        return () => {
            resizeObserver.disconnect();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audio, user, error]);

    const isPlaying = audio && audioStatus === "play" && audioSource && audioSource.audio && audioSource.audio.id && audioSource.audio.id === audio.id;

    useEffect(() => {
        if (!canResize()) return;
        if (!nameRef.current) return;
        const resizeObserver = new ResizeObserver((entries) => setShowNickname(entries[0].contentRect.height <= 24));

        resizeObserver.observe(nameRef.current);
        return () => resizeObserver.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameRef.current, audio, user, error]);

    if (!audio || !user)
        return (
            <div className="loader-container" style={{ backgroundColor: "var(--bg-color, #000)", height: embedConfig.height || 64 }}>
                <img src={searchParams.get("dark") === "true" ? logoWhite : logoBlack} alt="Micro Logo" style={{ height: window.location.pathname.includes("/embed/") ? "40px" : "50px", width: "auto" }} />
            </div>
        );

    if (error)
        return (
            <div className="loader-container">
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-between", gap: 5, maxHeight: 80, height: "100%", padding: 10 }}>
                    <h1 style={{ color: "#fff", margin: 0 }}>{t("AudioEmbed:notFound")}</h1>
                    <p style={{ color: "#fff", margin: 0 }}>{t("AudioEmbed:deleted")}</p>
                </div>
            </div>
        );

    let profile = {};
    if (audio?.contributor?.status === "enabled") profile = audio?.contributor;
    else if (user?.vocasters?.[audio?.vocaster]) profile = user?.vocasters?.[audio?.vocaster];
    else profile = user;

    return (
        <Container fluid style={{ width: "100%", paddingLeft: embedConfig.showBorder ? ".2rem" : 0, paddingRight: embedConfig.showBorder ? ".2rem" : 0 }} ref={ref}>
            <Row style={{ padding: 0, margin: embedConfig.showBorder ? 5 : 0, height: "fit-content" }}>
                <Col
                    xs={12}
                    style={{
                        borderRadius: "1.3em",
                        padding: embedConfig.isReduced ? 0 : "1em",
                        paddingBottom: embedConfig.isReduced ? 0 : embedConfig.showIcons || embedConfig.showLogo ? "0.5em" : "1em",
                        // border: "1px solid #000",
                        boxShadow: embedConfig.showBorder ? "0px 0px 5px 0px rgba(0,0,0,0.4)" : null,
                        color: "#fff",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        backgroundColor: embedConfig.isReduced ? embedConfig?.colors?.cardColor : embedConfig?.colors?.bgColor,
                        backgroundImage: embedConfig?.background === "vocast" ? `linear-gradient(to bottom, rgba(0, 0, 0, 0.52), rgba(0, 0, 0, 0.3)), url(${picture})` : null,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                    }}
                >
                    {embedConfig.showProfile && user && (
                        <div style={{ height: 50, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <a className="link-btn" href={vocastUrl.profile} target="_blank" rel="noreferrer">
                                <div className="align-items-center" style={{ display: "flex" }}>
                                    <div style={{ width: "auto", marginRight: "1rem" }}>
                                        {user?.profilePicture && (
                                            <img
                                                alt={user?.name}
                                                src={user?.profilePicture}
                                                style={{ width: "100%", borderRadius: "42%", aspectRatio: 1, objectFit: "cover", backgroundPosition: "center", maxWidth: 50, height: "100%" }}
                                            />
                                        )}
                                    </div>
                                    <div>
                                        <h4 ref={nameRef} className="m-0" style={{ fontSize: 19, color: embedConfig.colors?.textColor || "#fff" }}>
                                            {user?.name}
                                        </h4>
                                        {showNickname && (
                                            <div>
                                                <small style={{ color: embedConfig.colors?.textColor || "#fff" }}>@{user?.nickname}</small>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </a>
                            <IsLarger width={350}>
                                <a className="link-btn" href={vocastUrl.profile} target="_blank" rel="noreferrer">
                                    <Button
                                        variant="outline-light"
                                        style={{
                                            borderColor: embedConfig?.colors?.textColor || "#fff",
                                            color: embedConfig?.colors?.textColor || "#fff",
                                            "--bs-btn-hover-bg": embedConfig?.colors?.textColor || "#fff",
                                            "--bs-btn-hover-color": embedConfig?.colors?.bgColor || "#000000",
                                        }}
                                    >
                                        {t("common:actions.follow")}
                                    </Button>
                                </a>
                            </IsLarger>
                        </div>
                    )}

                    {!embedConfig.showProfile && embedConfig.showTitle && (
                        <a className="link-btn" href={vocastUrl.audio} target="_blank" rel="noreferrer">
                            <TextSlide text={audio?.title} style={{ height: 29, fontWeight: "bold", fontSize: "1.2rem", color: embedConfig?.colors?.textColor || "#fff" }} space={4} />
                        </a>
                    )}
                    {!embedConfig.showProfile && embedConfig.showDescription && (
                        <a className="link-btn" href={vocastUrl.audio} target="_blank" rel="noreferrer">
                            <p className="text-line2 m-0" style={{ color: embedConfig?.colors?.textColor || "#fff" }}>
                                {audio && audio.description}
                            </p>
                        </a>
                    )}

                    <Card
                        style={{
                            width: "100%",
                            borderRadius: "1.2em",
                            backgroundColor: embedConfig.isReduced ? "transparent" : embedConfig?.colors?.cardColor || "rgba(51, 51, 51, 0.8)",
                            color: embedConfig?.colors?.cardContentColor || "#fff",
                            border: embedConfig.isReduced && embedConfig.showBorder ? "1px solid rgba(0, 0, 0, 0.175)" : "none",
                        }}
                    >
                        <Card.Body
                            style={{
                                display: "flex",
                                gap: 10,
                                flexDirection: "column",
                                paddingTop: embedConfig.isReduced ? ".5rem" : "1rem",
                                paddingBottom: embedConfig.isReduced ? ".5rem" : "1rem",
                                paddingLeft: "1rem",
                                paddingRight: "1rem",
                            }}
                        >
                            {embedConfig.showProfile && embedConfig.showTitle && (
                                <a className="link-btn" href={vocastUrl.audio} target="_blank" rel="noreferrer">
                                    <TextSlide text={audio?.title} style={{ height: 29, fontWeight: "bold", fontSize: "1.2rem" }} space={4} />
                                </a>
                            )}
                            {((!embedConfig.showProfile && embedConfig.isReduced) || embedConfig.showProfile) && embedConfig.showDescription && (
                                <a className="link-btn" href={vocastUrl.audio} target="_blank" rel="noreferrer">
                                    <p className="text-line2 m-0" style={{ color: embedConfig?.colors?.cardContentColor || "#fff" }}>
                                        {audio && audio.description}
                                    </p>
                                </a>
                            )}
                            <div className="d-flex align-items-center justify-content-between gap-2">
                                <IsLarger width={330 - !embedConfig.showFlag * 35} hide={!embedConfig.showVocaster}>
                                    {profile?.profilePicture && (
                                        <a href={vocastUrl.audio} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: embedConfig?.colors?.cardContentColor || "#fff" }}>
                                            <img
                                                alt={profile?.name || user?.name}
                                                src={profile?.profilePicture || user?.profilePicture}
                                                style={{ width: 46, height: 46, borderRadius: "42%", aspectRatio: 1, objectFit: "cover", backgroundPosition: "center" }}
                                            />
                                        </a>
                                    )}
                                </IsLarger>
                                {(duration > 0 || isPlaying) && (
                                    <div style={{ display: "flex", gap: 1, alignItems: "center", flexDirection: "column" }}>
                                        <div style={{ fontSize: 13, width: 46, display: "flex", justifyContent: "center", alignItems: "center" }}>{duration && moment(duration).format("mm:ss")}</div>
                                        {isPlaying && embedConfig.showRate && (
                                            <div
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                    padding: 3,
                                                    background: "radial-gradient(rgba(255, 255, 255, 0.3), transparent)",
                                                    borderRadius: 30,
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    userSelect: "none",
                                                    width: 46,
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    const playbackRates = [1, 1.2, 1.5, 2];
                                                    setCurrentRate(playbackRates[(playbackRates.indexOf(currentRate) + 1) % playbackRates.length]);
                                                }}
                                            >
                                                <FaTimes size={10} />
                                                {currentRate}
                                            </div>
                                        )}
                                    </div>
                                )}
                                <IsLarger width={280 - !embedConfig.showFlag * 20 - !embedConfig.showVocaster * 20}>
                                    <div style={{ width: "100%", overflow: "hidden" }}>
                                        <WaveFormPlayer audio={audio} audioPlayer={audioPlayer} handlePlay={handlePlay} color={embedConfig?.colors?.cardContentColor} />
                                    </div>
                                </IsLarger>
                                {embedConfig.showFlag && (
                                    <div className="d-flex align-items-center">
                                        <Dropdown data-bs-theme="dark" variant="dark" data-drop={"coucou"} drop="down-centered">
                                            <Dropdown.Toggle
                                                className="p-0 m-0"
                                                variant="link"
                                                id="dropdown-basic"
                                                style={{
                                                    textDecoration: "none",
                                                    color: embedConfig?.colors?.cardContentColor || "#fff",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    background: "radial-gradient(rgba(255, 255, 255, 0.3), transparent); width: 46px; height: 46px",
                                                }}
                                            >
                                                {/* <div style={{ fontWeight: "bold" }}>{language.toUpperCase()}</div> */}
                                                <img src={`https://storage.googleapis.com/vocast-public/assets/flags/${language}.png`} alt={language} style={{ width: 20, height: 20 }} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{ backgroundColor: "#121212" }}>
                                                {languages.map((lng) => (
                                                    <Dropdown.Item
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            color: embedConfig?.colors?.cardContentColor || "#fff",
                                                            fontWeight: "bold",
                                                            gap: 4,
                                                        }}
                                                        onClick={() => setLanguage(lng)}
                                                        key={`embed-${lng}`}
                                                    >
                                                        <img src={`https://storage.googleapis.com/vocast-public/assets/flags/${lng}.png`} alt={lng} style={{ width: 20, height: 20 }} />
                                                        {lng.toUpperCase()} {lng !== audio?.locale && "(✨IA)"}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )}
                                <div>
                                    <Button variant="empty" size="sm" onClick={(e) => handlePlay(audio)} className="p-0 m-0" style={{ height: 48, width: 48 }}>
                                        {isPlaying ? (
                                            <PauseMini inside={embedConfig?.colors?.cardColor || "#000000"} outer={embedConfig?.colors?.cardContentColor || "#fff"} />
                                        ) : audioStatus === "loading" ? (
                                            <div style={{ width: "48px", height: "48px" }} className="d-flex justify-content-center align-items-center">
                                                <Spinner
                                                    variant="light"
                                                    className="spinner-color"
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        "--bs-spinner-color": embedConfig?.colors?.cardContentColor || "#fff",
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <PlayerMini inside={embedConfig?.colors?.cardColor || "#000000"} outer={embedConfig?.colors?.cardContentColor || "#fff"} />
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                    {(embedConfig.showIcons || embedConfig.showLogo) && (
                        <Row className="mt-0-420" style={{ height: 26 }}>
                            <Col xs={12} className={`d-flex justify-content-${embedConfig.showIcons ? "between" : "end"} align-items-end`}>
                                {embedConfig.showIcons && (
                                    <div className="d-flex align-items-center" style={{ marginLeft: 4, gap: 15 }}>
                                        <div className="mini">
                                            <a
                                                style={{ textDecoration: "none", color: embedConfig?.colors?.textColor || "#fff", display: "flex", gap: 6, fontSize: 14 }}
                                                href={vocastUrl.audio}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Headphones alt="Listens" style={{ maxWidth: 18, maxHeight: 18, width: "100%", height: "auto", minWidth: 18, color: embedConfig?.colors?.textColor || "#fff" }} />
                                                {listners}
                                            </a>
                                        </div>
                                        <div className="mini hide-250" style={{ marginRight: 1 }}>
                                            <a
                                                style={{ textDecoration: "none", color: embedConfig?.colors?.textColor || "#fff", display: "flex", gap: 6, fontSize: 14 }}
                                                href={vocastUrl.audio}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Heart alt="likes" style={{ maxWidth: 18, maxHeight: 18, width: "100%", height: "auto", minWidth: 18, marginTop: 1, color: embedConfig?.colors?.textColor || "#fff" }} />
                                                {audio && audio.likes ? Object.keys(audio.likes).length : 0}
                                            </a>
                                        </div>
                                        <div className="mini hide-300">
                                            <a
                                                style={{ textDecoration: "none", color: embedConfig?.colors?.textColor || "#fff", display: "flex", gap: 5, fontSize: 14 }}
                                                href={vocastUrl.audio}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Comment alt="comments" style={{ maxWidth: 19, maxHeight: 19, width: "100%", height: "auto", minWidth: 18, color: embedConfig?.colors?.textColor || "#fff" }} />
                                                {audio && audio.comments ? Object.keys(audio.comments).length : 0}
                                            </a>
                                        </div>
                                    </div>
                                )}
                                {embedConfig.showLogo && (
                                    <a style={{ textDecoration: "none", color: "#fff" }} href={vocastUrl.home} target="_blank" rel="noreferrer">
                                        <img alt="Vocast" src={embedConfig.logoDark ? logoWhite : logoBlack} style={{ width: "auto", maxHeight: 25 }} />
                                    </a>
                                )}
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        </Container>
    );
}
