import React, { useRef, useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from "react-i18next";

import { FaRotateLeft, FaImage, FaXmark } from "react-icons/fa6";

export const PageTab = ({ options = {}, onOptionsChange }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const storage = getStorage();
    const [preview, setPreview] = useState(options?.backgroundImageUrl || null);
    const [isDragging, setIsDragging] = useState(false);
    const [tempFile, setTempFile] = useState(null);

    const handlePageSettingChange = (setting, value) => {
        onOptionsChange({
            ...options,
            [setting]: value,
        });
    };

    const handleImageUpload = async (file) => {
        if (!file) return;

        try {
            // Generate a unique filename
            const fileExtension = file.name.split('.').pop();
            const fileName = `${uuidv4()}.${fileExtension}`;
            const storageRef = ref(storage, `recorder/${options.recorderId}/${fileName}`);

            // Upload the file
            await uploadBytes(storageRef, file);
            
            // Get the download URL
            const downloadURL = await getDownloadURL(storageRef);
            
            // Update the configuration with the new image URL
            handlePageSettingChange('backgroundImageUrl', downloadURL);
            setPreview(downloadURL);
            setTempFile(null);
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setTempFile(file);
            
            // Create local preview
            const reader = new FileReader();
            reader.onload = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.jpg', '.png', '.gif', '.webp']
        },
        maxFiles: 1,
        onDragEnter: () => setIsDragging(true),
        onDragLeave: () => setIsDragging(false),
    });

    const removeImage = () => {
        handlePageSettingChange('backgroundImageUrl', null);
        setPreview(null);
        setTempFile(null);
    };

    // Expose the tempFile to the parent component
    React.useEffect(() => {
        if (tempFile) {
            handlePageSettingChange('tempFile', tempFile);
        }
    }, [tempFile]);

    return (
        <div>
            <h5 style={{ color: "#fff", marginBottom: 16 }}>{t("Recorder:pageConfiguration.title")}</h5>
            <section style={{ display: "flex", flexDirection: "column", gap: 16, marginLeft: 16 }}>
                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="showLogo" style={{ color: "#fff" }}>
                        {t("Recorder:pageConfiguration.showLogo")}
                    </label>
                    <Form.Check 
                        type="switch" 
                        id="showLogo" 
                        checked={options?.showLogo || false} 
                        onChange={(e) => handlePageSettingChange("showLogo", e.target.checked)} 
                        className="custom-switch" 
                    />
                </div>

                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8 }}>
                    <label htmlFor="backgroundColor" style={{ color: "#fff" }}>
                        {t("Recorder:pageConfiguration.backgroundColor")}
                    </label>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 8 }}>
                        <div
                            style={{
                                padding: 4,
                                gap: 4,
                                display: "flex",
                                flexDirection: "row",
                                boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
                                alignItems: "center",
                                borderRadius: 5,
                                width: 86,
                                position: "relative",
                                backgroundColor: "gray",
                            }}
                        >
                            <input
                                type="color"
                                id="backgroundColor"
                                value={options?.backgroundColor || "#000000"}
                                onChange={(e) => handlePageSettingChange("backgroundColor", e.target.value)}
                                style={{ opacity: 0, position: "absolute", inset: 0, width: "100%", height: "100%", cursor: "pointer" }}
                            />
                            <div style={{ width: 20, height: 20, borderRadius: 5, backgroundColor: options?.backgroundColor || "#000000", boxShadow: "0 0 3px rgba(0, 0, 0, 0.3)" }}></div>
                            <div style={{ fontSize: 11, lineHeight: "10px", textTransform: "uppercase", color: "#fff" }}>{options?.backgroundColor || "#000000"}</div>
                        </div>
                        {(options?.backgroundColor || "#000000") !== "#000000" && (
                            <span style={{ cursor: "pointer", color: "#fff" }} onClick={() => handlePageSettingChange("backgroundColor", "#000000")}>
                                <FaRotateLeft />
                            </span>
                        )}
                    </div>
                </div>

                <div className="form-check" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 8, padding: 0 }}>
                    <label className="form-check-label" htmlFor="backgroundImage" style={{ color: "#fff" }}>
                        {t("Recorder:pageConfiguration.backgroundImage")}
                    </label>
                    <Form.Check
                        type="switch"
                        id="backgroundImage"
                        checked={options?.backgroundImage || false}
                        onChange={(e) => handlePageSettingChange("backgroundImage", e.target.checked)}
                        className="custom-switch"
                    />
                </div>

                {options?.backgroundImage && (
                    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                        <div
                            {...getRootProps()}
                            style={{
                                border: `2px dashed ${isDragActive ? '#80fed8' : '#ccc'}`,
                                borderRadius: 8,
                                padding: 20,
                                textAlign: 'center',
                                cursor: 'pointer',
                                backgroundColor: isDragActive ? 'rgba(128, 254, 216, 0.1)' : 'transparent',
                                transition: 'all 0.3s ease',
                                position: 'relative',
                                minHeight: 200,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <input {...getInputProps()} />
                            {preview ? (
                                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                    <img 
                                        src={preview} 
                                        alt="Preview" 
                                        style={{ 
                                            maxWidth: '100%', 
                                            maxHeight: 200, 
                                            borderRadius: 8,
                                            objectFit: 'cover'
                                        }} 
                                    />
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeImage();
                                        }}
                                        style={{
                                            position: 'absolute',
                                            top: 8,
                                            right: 8,
                                            background: 'rgba(0, 0, 0, 0.5)',
                                            border: 'none',
                                            borderRadius: '50%',
                                            width: 32,
                                            height: 32,
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <FaXmark color="#fff" />
                                    </button>
                                </div>
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 8 }}>
                                    <FaImage size={32} color="#ccc" />
                                    <p style={{ color: '#fff', margin: 0 }}>
                                        {isDragActive ? t("Recorder:pageConfiguration.dropzone.active") : t("Recorder:pageConfiguration.dropzone.inactive")}
                                    </p>
                                    <p style={{ color: '#999', fontSize: 12, margin: 0 }}>
                                        {t("Recorder:pageConfiguration.dropzone.formats")}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};
