import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { isMobile, osName } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import { GoogleAuthProvider, OAuthProvider, createUserWithEmailAndPassword, fetchSignInMethodsForEmail, signInWithPopup } from "firebase/auth";
import { collection, doc, getDoc, getDocs, query, serverTimestamp, setDoc, where } from "firebase/firestore";
import { generateUsername } from "unique-username-generator";

import { useRouteListener } from "../../context/RouteListenerProvider";

import { auth, db } from "../../services/firebase";
import { event_user_create, event_user_login } from "../../services/stats";

import { nicknameFormat } from "./CompleteProfile/PseudoUsername";

import { ReactComponent as AppleIcon } from "../../Assets/img/apple-icon.svg";
import googleIcon from "../../Assets/img/google-icon.svg";
import logo from "../../Assets/img/logo-white.png";

import { FaEye } from "react-icons/fa6";
import { FaEyeSlash } from "react-icons/fa6";

export default function Signup() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getLastUrl } = useRouteListener();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [subscribe, setSubscribe] = useState(false);

    const beforeCompleteProfile = async (user) => {
        /*
         * dans le doute ou l'utilisateur quitte la page avant de finaliser l'inscription,
         * on créer un profil par défaut avec les informations de base du provider
         */
        let _nickname = "";
        while (true) {
            _nickname = generateUsername("_", 6, 20, nicknameFormat(user.displayName || user.email.split("@")[0]));
            const userDoc = await getDocs(query(collection(db, "users"), where("nickname", "==", _nickname)));
            if (userDoc.size === 0) break;
        }

        setDoc(doc(db, "users", user.uid), {
            name: user.displayName || user.email.split("@")[0],
            nickname: _nickname,
            creationDate: serverTimestamp(),
            email: user?.email,
            profilePicture: user.photoURL,
            unCompletedProfile: true,
            platform: `webapp_${isMobile ? "mobile" : "desktop"}_${osName}`,
            subscribeFrom: getLastUrl(["/login", "/signup", "/complete-profile", "/forgot-password"]),
        });
        return navigate(`/complete-profile`);
    };

    useEffect(() => {
        document.title = "Vocast - Signup";
        const checkUser = async () => {
            if (!auth.currentUser) return;
            let user = auth.currentUser;
            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                return navigate(`/complete-profile`);
            }
        };
        checkUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?render=6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y";
        script.addEventListener("load", handleLoaded);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleLoaded = () => {
        window.grecaptcha.ready(async () => {});
    };

    const executeRecaptcha = async () => {
        if (!window.grecaptcha) {
            console.error("reCAPTCHA not loaded");
            return null;
        }

        try {
            const token = await window.grecaptcha.execute("6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y", { action: "signup" });
            return token;
        } catch (error) {
            console.error("reCAPTCHA execution failed", error);
            return null;
        }
    };

    const verifyRecaptcha = async (token) => {
        try {
            const response = await axios.post("https://us-central1-vocast-app.cloudfunctions.net/webapp/recaptcha/verify", { token });
            console.log("reCAPTCHA verification response", response.data);
            return response.data.success;
        } catch (error) {
            console.error("reCAPTCHA verification failed", error);
            return false;
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleGoogleSignup = async () => {
        setLoading(true);
        setError("");

        const provider = new GoogleAuthProvider();
        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;

            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                event_user_login({ environment: "webapp", userId: user.uid, provider: "google" });
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                event_user_create({ environment: "webapp", userId: user.uid, provider: "google" });
                return beforeCompleteProfile(user);
            }
        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") return;
            setError(t("Register:faildToCreateAccount"));
            console.error("Google signup error:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleAppleSignup = async () => {
        const provider = new OAuthProvider("apple.com");
        provider.addScope("email");
        provider.addScope("name");

        try {
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
            console.log("User logged in successfully!", user);

            const userDoc = await getDoc(doc(collection(db, "users"), user.uid));
            if (userDoc.exists()) {
                event_user_login({ environment: "webapp", userId: user.uid, provider: "apple" });
                const userData = userDoc.data();
                localStorage.setItem("user", JSON.stringify(userData));
                return navigate(`/${userData.nickname}`);
            } else {
                event_user_create({ environment: "webapp", userId: user.uid, provider: "apple" });
                beforeCompleteProfile(user);
            }
        } catch (error) {
            if (error.code === "auth/popup-closed-by-user") return;
            setError(t("Register:faildToCreateAccount"));
            console.error("Apple signup error:", error);
        }
    };

    const handleEmailSignup = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError("");

        if (!email || !password) {
            setError(t("Register:fillAllFields"));
            setLoading(false);
            return;
        }

        const meth = await fetchSignInMethodsForEmail(auth, email);
        if (meth.length > 0) {
            setError(t("Register:accountAlreadyExists"));
            setLoading(false);
            return;
        }

        const isLocal = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";

        const recaptchaToken = await executeRecaptcha();
        if (!recaptchaToken && !isLocal) {
            setError(t("Register:recaptchaFailed"));
            setLoading(false);
            return;
        }

        const isVerified = await verifyRecaptcha(recaptchaToken);
        if (!isVerified && !isLocal) {
            setError(t("Register:recaptchaFailed"));
            setLoading(false);
            return;
        }

        try {
            await createUserWithEmailAndPassword(auth, email, password);
            const user = auth.currentUser;
            event_user_create({ environment: "webapp", userId: user.uid, provider: "email" });
            console.log("User created successfully!", user);
            if (subscribe) {
                console.log("Subscribed to newsletter");
                try {
                    axios({
                        method: "post",
                        url: "https://vocast.co/api/newsletter/subscribe",
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                        data: { email },
                    })
                        .then((result) => {
                            console.log(result);
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } catch (error) {
                    console.error(error);
                }
            }
            // l'email se vérifie automatiquement quand j'envoie le lien ?? je ne sais pas pourquoi.
            // setError(t("Login:emailNotVerified"));
            // await sendEmailVerification(user, {
            //     url: `${window.location.origin}/verify-email`,
            // });
            // await signOut(auth);
            // setLoading(false);
            // return;
            return beforeCompleteProfile(user);
        } catch (error) {
            setError(t("Register:faildToCreateAccount"));
            console.error(error);
        } finally {
            setLoading(false);
        }

        setLoading(false);
        setError("");
    };

    return (
        <Container className="d-flex flex-column align-items-center" style={{ maxWidth: "600px", width: "100%" }}>
            <div className="text-center mb-4" style={{ marginTop: "24px" }}>
                <a href="https://www.vocast.co/">
                    <img src={logo} alt="Vocast Logo" style={{ maxWidth: "50%", marginBottom: "20px" }} />
                </a>
            </div>

            <div className="text-center" style={{ padding: "24px 16px" }}>
                <h2 style={{ fontSize: 32, color: "#fff" }}>
                    <b>{t("Register:firstTime")}</b>
                </h2>
                <p style={{ fontSize: 14, color: "#f3f3f3" }}>{t("Register:createAccount")}</p>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            <Form className="w-100" onSubmit={handleEmailSignup}>
                <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Control
                        type="email"
                        placeholder={`${t("Register:email")}*`}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="email"
                        required
                        style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                    />
                </Form.Group>

                <Form.Group controlId="formPassword" className="mb-2">
                    <InputGroup>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder={`${t("Register:password")}*`}
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            style={{ paddingTop: 10, paddingBottom: 10, fontSize: 16 }}
                        />
                        <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </InputGroup.Text>
                    </InputGroup>
                </Form.Group>
                <Form.Group controlId="formSubscribe" className="mb-3">
                    <Form.Check type="checkbox" style={{ fontSize: 14, color: "#f3f3f3" }} label={t("Register:newsletter")} checked={subscribe} onChange={(e) => setSubscribe(e.target.checked)} />
                </Form.Group>

                <div className="g-recaptcha" data-sitekey="6LcPzEsqAAAAAOJOlOGlTKEW9SkQiibcIslDr50y" data-size="invisible" />

                <Button
                    variant="success"
                    type="submit"
                    className="w-100 mb-3"
                    style={{ backgroundColor: "#80ffd7", padding: "12px 0", border: "none", color: "#000", fontSize: 14, fontWeight: 600 }}
                    disabled={loading || !email.length || !password.length}
                >
                    {loading ? t("Register:registerStep1Process") : t("Register:registerStep1")}
                </Button>
            </Form>

            <p style={{ fontSize: "14px", color: "#f3f3f3" }}>
                {t("Register:alreadyHaveAccount")}{" "}
                <Link to="/login" className="text-decoration-none" style={{ color: "#f3f3f3" }}>
                    <b>{t("Register:login")}</b>
                </Link>
            </p>

            <div className="w-100 text-center" style={{ marginTop: "8px", marginBottom: "16px", position: "relative" }}>
                <hr className="w-100" style={{ borderColor: "#f3f3f3" }} />
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", backgroundColor: "black", padding: "0 12px" }}>
                    <span style={{ fontSize: "12px", color: "#f3f3f3", width: 32, textTransform: "uppercase" }}>{t("Register:or")}</span>
                </div>
            </div>

            <Row className="w-100">
                <Col className="mb-2" xs={12}>
                    <Button
                        variant="outline"
                        className="h-blue w-100 text-start d-flex align-items-center"
                        style={{
                            color: "#f3f3f3",
                            borderRadius: 5,
                            border: "1px solid #6c757d",
                            fontWeight: "bold",
                            fontSize: 14,
                            padding: "12px 12px",
                        }}
                        onClick={handleGoogleSignup}
                    >
                        <img src={googleIcon} alt="Google icon" style={{ marginRight: 14, height: 20, width: "auto" }} />
                        {t("Register:continueWith")} Google
                    </Button>
                </Col>
                <Col className="mb-2" xs={12}>
                    <Button
                        variant="outline"
                        className="h-blue w-100 text-start d-flex align-items-center"
                        style={{
                            color: "#f3f3f3",
                            borderRadius: 5,
                            border: "1px solid #6c757d",
                            fontWeight: "bold",
                            fontSize: 14,
                            padding: "12px 12px",
                        }}
                        onClick={handleAppleSignup}
                    >
                        <AppleIcon style={{ marginRight: 17, color: "inherit", width: "auto", height: 20 }} />
                        {t("Register:continueWith")} Apple
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}
