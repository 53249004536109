import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { deleteField, doc, updateDoc } from "firebase/firestore";

import { useUserContext } from "../../context/UserContext";

import { auth, db } from "../../services/firebase";

import AvatarBio from "./CompleteProfile/AvatarBio";
import FirstVocast from "./CompleteProfile/FirstVocast";
import PseudoUsername from "./CompleteProfile/PseudoUsername";
import ShowProfile from "./CompleteProfile/ShowProfile";

export default function CompleteProfile() {
    const navigate = useNavigate();
    const { user, userData } = useUserContext();
    const [step, setStep] = useState(-1);
    const [searchParams] = useSearchParams();

    const handleNext = () => {
        if (step === 2 && window.innerWidth < 420) {
            return navigate(`/${userData.nickname}`);
        }
        setStep(step + 1);
    };

    useEffect(() => {
        document.title = "Vocast - Complete Profile";
        if (!userData || !user || step !== -1) return;
        const checkUser = async () => {
            if (!auth.currentUser) return navigate("/login");
            if (userData.unCompletedProfile || searchParams.get("force") === "true") {
                updateDoc(doc(db, "users", auth.currentUser.uid), { unCompletedProfile: deleteField() });
                return setStep(parseInt(searchParams.get("step") || 0));
            }
            return navigate(`/${userData.nickname}`);
        };
        checkUser();
        // eslint-disable-next-line
    }, [userData]);

    switch (step) {
        case 0:
            return <PseudoUsername next={handleNext} />;
        case 1:
            return <AvatarBio next={handleNext} user={user} userData={userData} />;
        case 2:
            return <FirstVocast next={handleNext} user={user} />;
        case 3:
            return <ShowProfile userData={userData} navigate={navigate} />;
        default:
            return null;
    }
}
