import { useEffect, useState } from "react";

import { getAuth, onAuthStateChanged } from "firebase/auth";

const IsLarger = ({ children, width, hide, force, onChange }) => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        if (onChange) onChange((windowDimensions.width >= width && !hide) || force);
        const handleResize = () => {
            setWindowDimensions((p) => {
                const oldVal = (p.width >= width && !hide) || force;
                const newVal = window.innerWidth >= width || force;
                if (onChange && oldVal !== newVal) onChange(newVal);
                return {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (windowDimensions.width >= width && !hide) || force ? children : null;
};

const IsSmaller = ({ children, width, hide, force }) => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (windowDimensions.width < width && !hide) || force ? children : null;
};

const IsAuth = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (_user) => {
            if (_user) {
                setUser(_user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return user ? children : null;
};

const IsNotAuth = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, async (_user) => {
            if (_user) {
                setUser(_user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !user ? children : null;
};

export { IsLarger, IsSmaller, IsAuth, IsNotAuth };
