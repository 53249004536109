import { useEffect, useState } from "react";

import { blobToSamples } from "../utils/audio";

export default function WaveFormView({ audioBlob, width, height = 60, barWidth = 3, gap = 3, color = "#80ffd7", currentTime = null, setWarning = null } = {}) {
    const [unique] = useState(Math.random().toString());
    const [duration, setDuration] = useState(0);

    const generateWaveform = async () => {
        const svg = document.getElementById(`waveform-view-${unique}`);
        const bars = document.getElementById(`waveform-view-${unique}-bars`);
        if (!svg || !bars) return;
        const width = svg.parentElement.clientWidth;
        svg.setAttribute("width", width);

        let samples = Math.floor(width / (barWidth + gap));
        const { waveform, duration } = await blobToSamples(audioBlob, samples, barWidth, height);
        if (duration > 1 && waveform.every((val) => val === 3) && setWarning) {
            setWarning("Record:noSound");
        } else if (setWarning) {
            setWarning(null);
        }
        setDuration(duration);

        bars.innerHTML = "";
        for (let i = 0, x = 0; i < waveform.length; i++) {
            let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", x);
            rect.setAttribute("y", (height - waveform[i]) / 2);
            rect.setAttribute("width", barWidth);
            rect.setAttribute("height", Math.abs(waveform[i]));
            rect.setAttribute("rx", barWidth / 2);
            // rect.setAttribute("fill", color);
            bars.appendChild(rect);
            x += gap + barWidth;
            if (x >= width) break;
        }
    };

    useEffect(() => {
        if (!audioBlob) return;
        generateWaveform();

        // eslint-disable-next-line
    }, [audioBlob, width]);

    // if (!audioBlob) return <div style={{ height }} />;

    return (
        <svg id={`waveform-view-${unique}`} style={{ height }}>
            <defs>
                <mask id={`waveform-view-${unique}-mask`}>
                    <rect width="100%" height="100%" fill="#7b7b7b" />
                    <rect width={`${currentTime == null ? 100 : (currentTime / duration) * 100}%`} height="100%" fill="white" />
                </mask>
            </defs>
            <g id={`waveform-view-${unique}-bars`} mask={`url(#waveform-view-${unique}-mask)`} fill={color} />
        </svg>
    );
}
