import { useCallback, useEffect, useState } from "react";

import { calculateBarData } from "../utils/audio";

export default function WaveFormLive({ mediaRecorder, height, barWidth = 3, gap = 3, color = "#80ffd7" }) {
    const [context, setContext] = useState(null);
    const [audioSource, setAudioSource] = useState(null);
    const [analyser, setAnalyser] = useState(null);
    const [unique] = useState(Math.random().toString());

    useEffect(() => {
        if (!mediaRecorder.stream) return;

        const ctx = new AudioContext();
        const analyserNode = ctx.createAnalyser();
        setAnalyser(analyserNode);
        analyserNode.fftSize = 1024;
        analyserNode.minDecibels = -90;
        analyserNode.maxDecibels = -10;
        analyserNode.smoothingTimeConstant = 0.4;
        const source = ctx.createMediaStreamSource(mediaRecorder.stream);
        source.connect(analyserNode);
        setContext(ctx);
        setAudioSource(source);

        return () => {
            source.disconnect();
            analyserNode.disconnect();
            ctx.state !== "closed" && ctx.close();
        };
        // eslint-disable-next-line
    }, [mediaRecorder.stream]);

    useEffect(() => {
        if (analyser && mediaRecorder.state === "recording") {
            report();
        }
        // eslint-disable-next-line
    }, [analyser, mediaRecorder.state]);

    const report = useCallback(() => {
        if (!analyser || !context) return;

        const data = new Uint8Array(analyser?.frequencyBinCount);

        if (mediaRecorder.state === "recording") {
            analyser?.getByteFrequencyData(data);
            processFrequencyData(data);
            requestAnimationFrame(report);
        } else if (mediaRecorder.state === "paused") {
            processFrequencyData(data);
        } else if (mediaRecorder.state === "inactive" && context.state !== "closed") {
            context.close();
        }
        // eslint-disable-next-line
    }, [analyser, context?.state]);

    useEffect(() => {
        return () => {
            if (context && context.state !== "closed") {
                context.close();
            }
            audioSource?.disconnect();
            analyser?.disconnect();
        };
        // eslint-disable-next-line
    }, []);

    const processFrequencyData = (data) => {
        const svg = document.getElementById(`waveform-live-${unique}`);
        const bars = document.getElementById(`waveform-live-${unique}-bars`);
        if (!svg || !bars) return;
        const width = svg.parentElement.clientWidth;
        svg.setAttribute("width", width);

        const waveform = calculateBarData(data, width, barWidth, gap, height);

        bars.innerHTML = "";
        for (let i = 0, x = 0; i < waveform.length; i++) {
            let rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
            rect.setAttribute("x", x);
            rect.setAttribute("y", (height - waveform[i]) / 2);
            rect.setAttribute("width", barWidth);
            rect.setAttribute("height", Math.abs(waveform[i]));
            rect.setAttribute("rx", barWidth / 2);
            // rect.setAttribute("fill", color);
            bars.appendChild(rect);
            x += barWidth * 2;
            if (x >= width) break;
        }
    };

    return (
        <svg id={`waveform-live-${unique}`} style={{ height }}>
            <g id={`waveform-live-${unique}-bars`} fill={color} />
        </svg>
    );
}