import "../Admin/admin.css";

import React, { useEffect, useRef, useState } from "react";
import { Row } from "react-bootstrap";
import { HitsPerPage, InstantSearch, Pagination, useHits, useSearchBox } from "react-instantsearch";

import { liteClient as algoliasearch } from "algoliasearch/lite";

import TopBar from "../../layout/TopBar";
import Layout from "../../layout/indexds";

import { AudioPlayer, Connection, ImageGallery, LienImgUser, convertTimestampToDate } from "./Utils";

import defaultBg from "../../Assets/img/default-bg.jpg";

import { FaExternalLinkAlt } from "react-icons/fa";

// Image par défaut

const searchClient = algoliasearch("QEZOD7FQY7", "c41c105d487e573ff5541b209eb60e27");

/*function JointAudioUsers(UsersIndex,AudiosIndex) {
		const [users, setUsers] = useState([]);
		const [audios, setAudios] = useState([]);
		const [audiosPlus, setAudiosPlus] = useState([]);
	
		useEffect(() => {
			// 1. Récupérer les produits
			UsersIndex.search('').then((userResponse) => {
				setUsers(userResponse.hits);
			});
	
			// 2. Récupérer les catégories
			AudiosIndex.search('').then((audioResponse) => {
				setAudios(audioResponse.hits);
			});
		}, []);
	
		useEffect(() => {
			// 3. "Joindre" les données sur le champ ObjetId
			if (users.length > 0 && audios.length > 0) {
				const joined = audios.map((audio) => {
					// Trouver la catégorie correspondante pour chaque produit
					const user = users.find(
						(usr) => user.objectID === audio.objetId
					);
	
					return {
						...audio,
						audioName: user ? user.name : 'Inconnu',
					};
				});
				setAudiosPlus(joined);
			}
		}, [users, audios]);
} */

function Rows(props) {
    let defaultAud = "audio/12345.mp4";
    const { items } = useHits(props);
    return (
        <div>
            {items.map((hit) => (
                <>
                    <div className="table-row" key={hit.objectID}>
                        <div className="table-cell colo-1">
                            <input type="checkbox" />
                        </div>
                        <div
                            className="table-cell colo-2"
                            style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                            }}
                        >
                            {<ImageGallery images={hit.pictures} /> || defaultBg}
                        </div>
                        <div className="table-cell colo-6 ellipsis" title={hit.title}>
                            <div>{hit.title}</div>
                            <div style={{ fontStyle: "italic" }} title={hit.description}>
                                {hit.description}
                            </div>
                        </div>
                        <div className="table-cell colo-2">{(hit.formattedDate = convertTimestampToDate(hit.date))}</div>

                        <div className="table-cell colo-1">{<AudioPlayer src={hit.audio || defaultAud} />}</div>
                        {/*<div className="table-cell colo-2">
							{LienAudio(hit.audio)}
						</div>*/}
                        <div className="table-cell colo-2 image-container">
                            <img src={LienImgUser(hit.ownerId)} className="imageGallery remImg2 image" alt="" title={hit.ownerId} />
                        </div>
                    </div>
                </>
            ))}
        </div>
    );
}
const TableHeader = () => {
    return (
        <div className="table-header">
            <div className="table-cell colo-1"></div>
            <div className="table-cell colo-2">Image</div>
            <div className="table-cell colo-6 ellipsis">Titre/Description</div>
            <div className="table-cell colo-2">Date</div>
            <div className="table-cell colo-1">Audio</div>
            <div className="table-cell colo-2">Auteur</div>
        </div>
    );
};

function SearchBox() {
    const { query, refine } = useSearchBox();
    return (
        <input
            type="text"
            value={query}
            onChange={(e) => refine(e.target.value)}
            style={{
                borderRadius: 15,
                border: "1px solid black",
                padding: "0.4rem .5rem",
            }}
            placeholder="Rechercher"
        />
    );
}

function PageAudios() {
    if (!window.location.host.includes("localhost")) {
        Connection();
    }
    return (
        <Layout>
            {/*<div style={{ padding: "1rem", marginBottom: "3rem", marginTop: "3rem", display: "flex", justifyContent: "center" }}></div>*/}
            <div
                className="flex  gap-4 "
                style={{
                    backgroundColor: "#000",
                    padding: "1rem",
                    height: "100vh",
                }}
            >
                <InstantSearch searchClient={searchClient} indexName="audio">
                    <div className="container">
                        <Row>
                            <div className="col-4">
                                <h1>Vocasts</h1>
                            </div>
                            <div className="col-4 offset-4">
                                <SearchBox />
                            </div>
                        </Row>
                    </div>

                    <div className="table-admin">
                        <div>
                            <TableHeader />
                            {/* <Hits /> */}

                            <Rows />
                        </div>
                        <HitsPerPage
                            style={{
                                marginTop: "1rem",
                                marginBottom: "1rem",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                            items={[
                                { label: "8 hits per page", value: 8, default: true },
                                {
                                    label: "12 hits per page",
                                    value: 12,
                                },
                                { label: "16 hits per page", value: 16 },
                                { label: "20 hits per page", value: 20 },
                            ]}
                        />
                        <Pagination
                            totalPages={10}
                            classNames={{
                                list: "pagination-list",
                                item: "pagination-item",
                                selectedItem: "item-selected",
                            }}
                        />
                    </div>
                </InstantSearch>
            </div>
        </Layout>
    );
}
export default PageAudios;
