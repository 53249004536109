import { algoliasearch } from "algoliasearch";

const searchClient = algoliasearch("QEZOD7FQY7", "c41c105d487e573ff5541b209eb60e27");

export const searchAlgolia = async (params) => {
    if (!params.page) params.page = 0;
    const filters = [];

    if (params.owners && Array.isArray(params.owners)) {
        filters.push(`(${params.owners.map(owner => `ownerId:${owner}`).join(" OR ")})`);
    } else if (params.owners) {
        filters.push(`ownerId:${params.owners}`);
    }

    if (params.status) {
        const statusArray = Array.isArray(params.status) ? params.status : [params.status];
        filters.push(`(${statusArray.map(status => `status:${status}`).join(" OR ")})`);
    } else {
        filters.push(`status:published`);
    }

    if (params?.contributors) {
        filters.push("hasContributor:true");
    }

    if (params?.private === "false") filters.push(`NOT visibility:private`);
    if (params?.collections && Array.isArray(params.collections)) {
        filters.push(`(${params.collections.map(collection => `collections:${collection}`).join(" OR ")})`);
    } else if (params?.collections) {
        filters.push(`collections:${params.collections}`);
    }
    
    let algoliaParams = {
        indexName: "audio",
        searchParams: {
            hitsPerPage: 10,
            filters: filters.join(" AND "),
            page: params.page,
            sortFacetValuesBy: "count",
        },
    };

    console.log("Algolia params", algoliaParams);
    if (params.search) {
        algoliaParams.searchParams.query = params.search;
    }
    const { hits, nbHits } = await searchClient.searchSingleIndex(algoliaParams);
    
    return { hits, nbHits };
};